import React, { useState } from "react";
import { addCategoryNote, addNote, clearNoteResponse } from "../../actions/noteActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import moment from "moment";
import { GET_ICD_CODES, SEARCH_ICD_CODES } from "../../actions/types";
import NoteCategory from "./Notes";

const AddChildNote = (props) => {
  const { noteProp, id, text, urgent, sortOrder, category, subcategory, fetchData } = props;
  const dispatch = useDispatch();
  const _auth = useSelector((state) => state.auth);
  const _note = useSelector((state) => state.note);

  const preloadedValues = { text: "", urgent: false };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    formState,
    control,
  } = useForm({
    defaultValues: preloadedValues,
  });

  const watchAllFields = watch();

  const [response, setResponse] = useState(null);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onSubmit = async (data) => {
    // console.log("in onSubmit: " + JSON.stringify(data));
    const cleanText = data.text.replace(/\s{2,}/g, " ").trim();

    const noteObj = {
      text: cleanText,
      user: _auth.name,
      urgent: data.urgent ? data.urgent : false,
    };

    dispatch(addCategoryNote(noteProp._id, noteObj));
    reset("", {
      keepValues: false,
    });

    setTimeout(() => {
      // Delay this action by 10 seconds
      dispatch(clearNoteResponse());
    }, 5000);
  };

  const onSubmitEdit = async (data) => {
    console.log("onSubmitEdit: " + JSON.stringify(data));
  };

  const checkCategory = (cat) => {};

  return (
    <div className="text-center rounded-8 bg-transparent">
      <Form
        // className="bg-light rounded-8 d-block"
        className="rounded-8"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/*<CardTitle className="pt-1">/!*{!!editNote ? "EDIT" : "ADD"} <strong>NOTE</strong>*!/</CardTitle>*/}
        <InputGroup className="">
          {/*<InputGroupText>*/}
          <input
            className={`form-control box-shadow-05 px-2 py-0  ${errors.text && ""}`}
            placeholder="add note"
            {...register("text", { required: true })}
            required
          />
          {/*{errors.text && <FormFeedback invalid>note can not be blank</FormFeedback>}*/}
          {/*</InputGroupText>*/}
          {/*<span className="position-absolute top-0 right-0 me-4 user-select-none text-danger fs-xxs">URGENT</span>*/}
          <InputGroupText>
            <input className="" type="checkbox" {...register("urgent")} />
          </InputGroupText>
          {/*<InputGroupText>*/}

          <Button
            type="submit"
            color="light"
            className="btn-icon "
            // disabled={!formState.dirty || formState.isSubmitting}
            disabled={formState.isSubmitting}
            name="Edit"
          >
            <i className="material-icons m-icon-custom fs-sm">add_to_photos</i>
          </Button>

          {/*</InputGroupText>*/}
        </InputGroup>
      </Form>
    </div>
  );
};

// export default AddChildNote;
export default connect(null, null)(AddChildNote);
