import React, { Component } from "react";
import { Button, Input, Row, Collapse, Card, InputGroup, InputGroupText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deletePatient, updatePatient, updatePatientActiveStatus } from "../../../actions/patientActions";
import moment from "moment";
// import _ from "lodash";
import { formatDateCal } from "../../Utilities/CalculateDate";

const exists = (value, isDate, altValue) => {
  let alt;
  altValue ? (alt = altValue) : (alt = "");
  if (isDate) {
    return value ? formatDateCal(value) : "";
  }
  return value ? value : alt;
};

const existsObj = (obj, objectKey, isDate) => {
  if (obj) {
    if (isDate) {
      return obj.date ? formatDateCal(obj.date) : "";
    }
    return obj[objectKey] ? obj[objectKey] : "";
  }
  return "";
};

class PatientActive extends Component {
  state = {
    active: this.props.editPatient.active,
    deceased: exists(this.props.editPatient.deceased),
    deceasedDate: exists(this.props.editPatient.deceasedDate, true),
    patientLeftBoolean: existsObj(this.props.editPatient.patientLeft, "boolean"),
    patientLeftDate: existsObj(this.props.editPatient.patientLeft, "date", true),
    patientLeftReason: existsObj(this.props.editPatient.patientLeft, "reason"),
    notWorthBillingBoolean: existsObj(this.props.editPatient.notWorthBilling, "boolean"),
    notWorthBillingDate: existsObj(this.props.editPatient.notWorthBilling, "date", true),
    notWorthBillingReason: existsObj(this.props.editPatient.notWorthBilling, "reason"),
    response: "",
    isOpen: false,
  };

  componentDidMount() {}

  // needed to do this to update local form state
  componentDidUpdate(prevProps, prevState, snapshot) {
    prevProps.editPatient !== this.props.editPatient &&
      this.setState({
        active: this.props.editPatient.active,
        deceased: exists(this.props.editPatient.deceased),
        deceasedDate: exists(this.props.editPatient.deceasedDate, true),
        patientLeftBoolean: existsObj(this.props.editPatient.patientLeft, "boolean"),
        patientLeftDate: existsObj(this.props.editPatient.patientLeft, "date", true),
        patientLeftReason: existsObj(this.props.editPatient.patientLeft, "reason"),
        notWorthBillingBoolean: existsObj(this.props.editPatient.notWorthBilling, "boolean"),
        notWorthBillingDate: existsObj(this.props.editPatient.notWorthBilling, "date", true),
        notWorthBillingReason: existsObj(this.props.editPatient.notWorthBilling, "reason"),
        // logic state
        response: "successfully updated",
        touched: false,
      });
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      response: "",
    });
  };

  toggleBoolean = (state) => {
    this.setState({ [state]: !this.state[state] });

    !this.state.touched && this.setState({ touched: true, response: "" });
  };

  // handleInactiveReason = (reason, key, value) => {
  //   this.setState({ [reason]: { ...this.state[reason], [key]: value } });
  // };

  // handleChangeTrimmed = (e) => {
  //   this.setState({ [e.target.name]: e.target.value.trim() });
  //
  //   !this.state.touched && this.setState({ touched: true, response: "" });
  // };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    !this.state.touched && this.setState({ touched: true, response: "" });
  };

  // clearResponse = () => {
  //   this.setState({ response: "" });
  // };

  handleUpdateActive = (id) => (e) => {
    e.preventDefault();
    const { active, deceased, deceasedDate } = this.state;
    const { patientLeftBoolean, patientLeftReason, patientLeftDate } = this.state;
    const { notWorthBillingBoolean, notWorthBillingReason, notWorthBillingDate } = this.state;

    const patientJson = {
      active,
      deceased,
      deceasedDate,
      patientLeftBoolean,
      patientLeftReason,
      patientLeftDate,
      notWorthBillingBoolean,
      notWorthBillingReason,
      notWorthBillingDate,
      userName: this.props.auth.name,
      userId: this.props.auth._id,
    };

    if (deceasedDate === "Invalid date" || !this.state.deceased || !!this.state.active) {
      delete patientJson.deceased;
      delete patientJson.deceasedDate;
    }

    this.props.updatePatientActiveStatus(id, patientJson);
  };

  // componentDidUpdate(prevProps, prevState) {}

  render() {
    const patient = this.props.editPatient;
    const { active, touched } = this.state;
    const {
      deceased,
      deceasedDate,
      patientLeftBoolean,
      patientLeftReason,
      patientLeftDate,
      notWorthBillingBoolean,
      notWorthBillingReason,
      notWorthBillingDate,
    } = this.state;
    // const oneYearAgo = moment().subtract(1, "years").format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");

    return (
      <div>
        <Card className="p-2 bg-light">
          <Row className="px-2 d-flex justify-content-center">
            <div className="p-2 text-center align-items-center">
              <Button
                color={active ? "light" : "danger"}
                onClick={() => this.toggleBoolean("active")}
                className="m-0 line-height-1 p-2 box-shadow-1 border rounded-6 font-lg"
              >
                {active ? (
                  <>
                    Active <div className="fs-xs opacity-3">Select to Inactivate Patient</div>
                  </>
                ) : (
                  <>
                    Inactive <div className="fs-xs opacity-3">Select to Activate Patient</div>
                  </>
                )}
              </Button>
            </div>

            <Collapse isOpen={!active}>
              {/*{!active && (*/}
              <>
                <div className="py-2 px-2 text-center">
                  <div className="text-center text-wrap opacity-4 fs-sm mb-2">
                    Select the reason the patient is inactive
                    <div className="opacity-3 fs-xs">selecting a reason is not required</div>
                  </div>

                  {/*---------DECEASED--------*/}
                  {/*---------DECEASED--------*/}
                  {/*---------DECEASED--------*/}
                  <Button
                    block
                    color={deceased ? "warning" : "muted"}
                    onClick={() => this.toggleBoolean("deceased")}
                    className="px-1 mt-0 mt-sm-1 mb-1 line-h-1 border rounded-16"
                  >
                    Deceased {deceased ? "✓" : ""}
                  </Button>
                  {deceased && (
                    <InputGroup size="sm" className="px-2 pb-3">
                      <InputGroupText>Died</InputGroupText>
                      <Input
                        type="date"
                        name="deceasedDate"
                        min="2019-01-01"
                        max={today}
                        value={deceasedDate}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                  )}

                  {/*---------PATIENT LEFT--------*/}
                  {/*---------PATIENT LEFT--------*/}
                  {/*---------PATIENT LEFT--------*/}
                  <Button
                    block
                    color={patientLeftBoolean ? "warning" : "muted"}
                    onClick={() => this.toggleBoolean("patientLeftBoolean")}
                    className="px-1 mt-0 mt-sm-1 mb-1 line-h-1 border rounded-16"
                  >
                    Left pharmacy {patientLeftBoolean ? "✓" : ""}
                  </Button>
                  {patientLeftBoolean && (
                    <>
                      <InputGroup size="sm" className="px-2 pb-1">
                        <InputGroupText>Reason</InputGroupText>
                        <Input
                          type="textarea"
                          name="patientLeftReason"
                          placeholder="to go to another pharmacy?"
                          value={patientLeftReason}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="px-2 pb-3">
                        <InputGroupText>When</InputGroupText>
                        <Input
                          type="date"
                          name="patientLeftDate"
                          min="2019-01-01"
                          max={today}
                          value={patientLeftDate}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                    </>
                  )}

                  {/*---------NOT WORTH BILLING--------*/}
                  {/*---------NOT WORTH BILLING--------*/}
                  {/*---------NOT WORTH BILLING--------*/}
                  <Button
                    block
                    color={notWorthBillingBoolean ? "warning" : "muted"}
                    onClick={() => this.toggleBoolean("notWorthBillingBoolean")}
                    className="px-1 mt-0 mt-sm-1 mb-1 line-h-1 border rounded-16"
                  >
                    Not worth billing {notWorthBillingBoolean ? "✓" : ""}
                  </Button>
                  {notWorthBillingBoolean && (
                    <>
                      <InputGroup size="sm" className="px-2 pb-1">
                        <InputGroupText>Reason</InputGroupText>
                        <Input
                          type="textarea"
                          name="notWorthBillingReason"
                          placeholder="insurance doesn't pay properly?"
                          value={notWorthBillingReason}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="px-2 pb-3">
                        <InputGroupText>When</InputGroupText>
                        <Input
                          type="date"
                          name="notWorthBillingDate"
                          min="2019-01-01"
                          max={today}
                          value={notWorthBillingDate}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                    </>
                  )}
                </div>
              </>
              {/*)}*/}
            </Collapse>
            <Button
              block
              color="info"
              disabled={!touched}
              onClick={this.handleUpdateActive(patient._id)}
              className="py-1 m-2"
            >
              Save Changes
            </Button>
          </Row>
        </Card>
        {/*<Collapse isOpen={!active}>*/}
        {/*  <Row noGutters={true}>*/}
        {/*    <Col md={12}>*/}
        {/*      */}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*</Collapse>*/}

        {/*{!!response && (*/}
        {/*  <Alert*/}
        {/*    color="success"*/}
        {/*    isOpen={!!response}*/}
        {/*    onClick={this.clearResponse}*/}
        {/*    tag="span"*/}
        {/*    className="p-1 px-2 mx-2 text-center font-size-sm"*/}
        {/*  >*/}
        {/*    {response}*/}
        {/*  </Alert>*/}
        {/*)}*/}
      </div>
    );
  }
}

PatientActive.propTypes = {
  patient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
  auth: state.auth,
});

export default connect(mapStateToProps, { deletePatient, updatePatient, updatePatientActiveStatus })(PatientActive);
