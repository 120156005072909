import React, { Component } from "react";
import {
  Button,
  Input,
  Col,
  Row,
  Form,
  // Alert,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updatePatientRefill, updateManyRefills } from "../../../actions/patientActions";
import moment from "moment";
import { fetchUser } from "../../../actions";
import CalculateDate from "../../Utilities/CalculateDate";
// import { CopyToClipboard } from "react-copy-to-clipboard";

class RefillChangeSelected extends Component {
  state = {
    selectedRefills: [],
    selectedDate: CalculateDate.days(1),
    popoverOpen: false,
    alertVisible: false,
  };

  componentDidMount() {}

  // handleDismissAlert = () => {
  //   this.setState({ alertVisible: false });
  // };

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUpdateManyRefills = (refills) => (e) => {
    // add action and reducer to deal with this
    this.props.updateManyRefills(refills, this.state.selectedDate);

    this.props.refreshModalYa();
  };

  render() {
    const oneMonthAgo = CalculateDate.months(-1);
    const elevenMonths = CalculateDate.months(11);
    const selectedRefills = this.props.selectedRefillsYa;
    const refillCount = selectedRefills.length;

    return (
      <div>
        <div className="rounded-8 bg-light border-lighter p-1 pt-2 mb-3 box-shadow-1">
          {/*<Alert*/}
          {/*color="info"*/}
          {/*isOpen={this.state.alertVisible}*/}
          {/*toggle={this.handleDismissAlert}*/}
          {/*>*/}
          {/*Please enter a valid refill date.*/}
          {/*</Alert>*/}
          <Form onSubmit={this.handleUpdateManyRefills(selectedRefills)}>
            <Row>
              <Col sm={{ size: 8, offset: 2 }} xs="12">
                <Input
                  type="date"
                  min={oneMonthAgo}
                  max={elevenMonths}
                  name="selectedDate"
                  value={this.state.selectedDate}
                  onChange={this.handleChange}
                  required
                  className="text-center"
                />
              </Col>

              <Col md={12}>
                <Button
                  color="primary"
                  id={"popover-love"}
                  onClick={this.togglePopover}
                  size="lg"
                  style={{ margin: "0px 0px 8px 0px" }}
                >
                  Change {refillCount} Refill{refillCount > 1 && "s"}
                </Button>

                <Popover
                  style={{ textAlign: "center" }}
                  placement="bottom"
                  isOpen={this.state.popoverOpen}
                  target={"popover-love"}
                  toggle={this.togglePopover}
                  trigger="legacy"
                  fade={false}
                >
                  <PopoverHeader>
                    <strong>Change dates to {moment(this.state.selectedDate).format("ddd MM/DD")}?</strong>
                  </PopoverHeader>
                  <PopoverBody style={{ paddingTop: "5px" }}>
                    <Button
                      style={{ marginTop: "0px" }}
                      onClick={this.togglePopover}
                      className="btn btn-primary custom-btn"
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{ marginTop: "0px" }}
                      onClick={this.handleUpdateManyRefills(selectedRefills)}
                      className="custom-btn"
                      color="danger"
                    >
                      Yes, Change Dates
                    </Button>
                  </PopoverBody>
                </Popover>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

RefillChangeSelected.propTypes = {
  patient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
});

export default connect(mapStateToProps, {
  updatePatientRefill,
  updateManyRefills,
  fetchUser,
})(RefillChangeSelected);
