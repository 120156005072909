import React, { Component } from "react";
import { Row, ListGroup, Card, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Col } from "reactstrap";
// import Pagination from "rc-pagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDrugs } from "../../actions/drugActions";
import DrugItem from "./DrugItem";
import PaginationNew from "react-js-pagination";

class Drugs extends Component {
  state = {
    current: 1,
    drugsPer: 24,
    filterDrugsBy: "All Drugs",
    dropdownOpenFilter: false,
    dropdownOpenPer: false,
  };

  componentDidMount() {
    this.props.getDrugs();
  }

  renderDrugs = (allDrugs) => {
    const { drugsPer } = this.state;
    const secondItem = drugsPer * this.state.current;
    const firstItem = secondItem - drugsPer;
    return allDrugs.slice(firstItem, secondItem).map((drug) => <DrugItem drugItem={drug} key={drug._id} />);
  };

  handleChange = (page) => {
    this.setState({
      current: page,
    });
  };

  //------Drugs Per Page------//
  toggleDrugsPer = () => {
    this.setState({
      dropdownOpenPer: !this.state.dropdownOpenPer,
    });
  };

  selectDropDrugsPer = (e) => {
    this.setState({
      dropdownOpenPer: !this.state.dropdownOpenPer,
      drugsPer: parseInt(e.target.innerText),
    });
    this.handleChange(1);
  };

  dropdownDrugsPerMenu = () => {
    return (
      <Dropdown isOpen={this.state.dropdownOpenPer} toggle={this.toggleDrugsPer}>
        <DropdownToggle caret>{this.state.drugsPer}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={this.selectDropDrugsPer}>24</DropdownItem>
          <DropdownItem onClick={this.selectDropDrugsPer}>48</DropdownItem>
          <DropdownItem onClick={this.selectDropDrugsPer}>96</DropdownItem>
          <DropdownItem onClick={this.selectDropDrugsPer}>384</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  //------Drugs Filter------//
  toggleDrugsFilter = () => {
    this.setState({
      dropdownOpenFilter: !this.state.dropdownOpenFilter,
    });
  };

  selectDropDrugsFilter = (e) => {
    this.setState({
      dropdownOpenFilter: !this.state.dropdownOpenFilter,
      current: 1,
      filterDrugsBy: e.target.innerText,
    });
  };

  dropdownDrugsFilter = () => {
    return (
      <Dropdown isOpen={this.state.dropdownOpenFilter} toggle={this.toggleDrugsFilter}>
        <DropdownToggle caret>{this.state.filterDrugsBy}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={this.selectDropDrugsFilter}>All Drugs</DropdownItem>
          <DropdownItem onClick={this.selectDropDrugsFilter}>Missing Primary ICD Code</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  drugsFilter = (drugs) => {
    switch (this.state.filterDrugsBy) {
      case "All Drugs":
        return drugs;
      case "Missing Primary ICD Code":
        return drugs.filter((patient) => !patient.primaryIcdCode);
      default:
        return drugs;
    }
  };

  render() {
    const { drugs } = this.props.drug;
    const filteredOut = this.drugsFilter(drugs);

    return (
      <div>
        <Card body>
          <Row
            style={{
              borderBottom: "1px dashed rgba(0,0,0,0.05)",
              paddingBottom: "15px",
            }}
          >
            <Col xs={6}>{this.dropdownDrugsFilter()}</Col>
            <Col xs={6}>
              <div style={{ float: "right" }}>{this.dropdownDrugsPerMenu()} </div>
            </Col>
          </Row>

          <ListGroup flush>
            <Row>{this.renderDrugs(filteredOut)}</Row>
          </ListGroup>
          <div className="text-center pt-4">
            {/*<Pagination*/}
            {/*  onChange={this.handleChange}*/}
            {/*  current={this.state.current}*/}
            {/*  total={filteredOut.length}*/}
            {/*  pageSize={this.state.drugsPer}*/}
            {/*/>*/}
            <div className="font-small text-muted mb-1">{filteredOut.length} drugs</div>
            <PaginationNew
              onChange={this.handleChange}
              activePage={this.state.current}
              totalItemsCount={filteredOut.length}
              itemsCountPerPage={this.state.drugsPer}
              pageRangeDisplayed={6}
              // the following are styling items
              itemClass="page-item m-0"
              linkClass="page-link"
              innerClass="pagination justify-content-center"
            />
          </div>
        </Card>
      </div>
    );
  }
}

Drugs.propTypes = {
  getDrugs: PropTypes.func.isRequired,
  drug: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  drug: state.drug,
});

export default connect(mapStateToProps, { getDrugs })(Drugs);
