import { combineReducers } from 'redux';
import authReducer from './authReducer';
import patientReducer from './patientReducer';
import noteReducer from './noteReducer';
import drugReducer from './drugReducer';
import icdCodeReducer from "./icdCodeReducer";
import userReducer from "./userReducer";


export default combineReducers({
  auth: authReducer,
  patient: patientReducer,
  note: noteReducer,
  drug: drugReducer,
  icdCode: icdCodeReducer,
  user: userReducer
});