import React from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

class PatientsPerPageDropdown extends React.Component {
  state = { dropdownOpenPer: false };

  togglePatientsPer = () => {
    this.setState({ dropdownOpenPer: !this.state.dropdownOpenPer });
  };

  render() {
    const { patientsPerPage, selectDropPatientsPer } = this.props;
    return (
      <Dropdown direction="start" isOpen={this.state.dropdownOpenPer} toggle={this.togglePatientsPer}>
        <DropdownToggle caret>{patientsPerPage}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => selectDropPatientsPer(16)}>16</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsPer(32)}>32</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsPer(48)}>48</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsPer(64)}>64</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsPer(128)}>128</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsPer(512)}>512</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

PatientsPerPageDropdown.propTypes = {
  patientsPerPage: PropTypes.number.isRequired,
  selectDropPatientsPer: PropTypes.func.isRequired,
};

export default PatientsPerPageDropdown;
