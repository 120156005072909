import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import RefillEditModal from "../Refills/RefillEditModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import RefillItem from "../Refills/RefillItem";
import PatientEditModal from "../PatientEdit/PatientEditModal";
import { Badge, Button } from "reactstrap";
import PatientNote from "../PatientEdit/PatientNote";
import PatientStatusSelector from "../PatientEdit/PatientStatusSelector";
import { formatDateWithYearCheck } from "../../Utilities/CalculateDate";

const findNextRefill = (refills, patientId, firstName, lastName, partner, family) => {
  const today = moment().subtract(1, "d").toDate();
  const newRefills = _.sortBy(refills, (ref) => {
    return new moment(ref.refillDate);
  });
  // const filteredRefills = newRefills.filter((refill) => {
  //   const localDate = moment(refill.refillDate, "YYYY-MM-DDTHH:mm:ss").toDate();
  //   return moment(localDate).isSameOrAfter(today);
  // });

  // if (filteredRefills.length > 0) {
  if (newRefills.length > 0) {
    const sent = newRefills[0].requestSent;
    return (
      <div className="align-items-center rounded-8" style={{ backgroundColor: sent ? "#f9f9f9" : "#e8e8e8" }}>
        {newRefills.map((refill, i) => (
          <RefillItem
            refillProp={refill}
            patient={{ firstName, lastName, patientId, partner, family }}
            index={i}
            showDate
            showBadges
            showEditModal
            whichComponent="Pat-"
            className="rounded-6"
            key={"lovely" + i}
          />
        ))}
      </div>
    );
  } else {
    return (
      <div style={{ opacity: "0.4" }}>
        <CopyToClipboard text={lastName + ", " + firstName}>
          <Button className="btn-icon bg-lime" style={{ background: "#32CD32", marginTop: "0px" }}>
            <i className="material-icons m-icon-custom">content_copy</i>
          </Button>
        </CopyToClipboard>{" "}
        No upcoming refills
      </div>
    );
  }
};

// Utility function for formatting phone numbers
const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return phoneNumber;
};

const PatientRow = ({ patient, auth }) => (
  <tr
    key={patient._id}
    className=""
    style={{ backgroundColor: !patient.active && "#f99fa6", opacity: !!patient.deceased && "0.3" }}
  >
    <td className="p-1 m-0">
      <Link to={`/patients/${patient._id}`} className="custom-link-hover">
        <div>
          <b>{patient.lastName + ", " + patient.firstName}</b>
        </div>
      </Link>
    </td>
    <td className="text-center">
      <PatientEditModal editPatient={patient} key={patient._id} />
      <div className="mt-2">
        <PatientStatusSelector
          patientId={patient._id}
          currentStatus={patient.status}
          statusUpdatedAt={patient.statusUpdatedAt}
        />
      </div>
    </td>
    <td className="fs-sm text-center text-info">
      {/*{patient.lastBilledRefill && moment(patient.lastBilledRefill.refillDate, "YYYY-MM-DDTHH:mm:ss").format("MM/DD")}*/}
      {patient.lastBilledRefill && formatDateWithYearCheck(patient.lastBilledRefill.refillDate)}
    </td>
    <td>
      {patient.refills && patient.refills.length > 0 ? (
        <div>
          {findNextRefill(
            patient.refills,
            patient._id,
            patient.firstName,
            patient.lastName,
            patient.partner,
            patient.family,
          )}
        </div>
      ) : (
        <div className="opacity-2 p-0" style={{ color: "#ad0025" }}>
          <CopyToClipboard text={patient.lastName + ", " + patient.firstName}>
            <Button className="btn-icon bg-lime" style={{ background: "#32CD32", marginTop: "0px" }}>
              <i className="material-icons m-icon-custom">content_copy</i>
            </Button>
          </CopyToClipboard>{" "}
          Patient has no refills
        </div>
      )}
    </td>
    <td className="text-center">
      {patient.rxCount && (
        <Badge color="dark" pill className="fs-xs ms-1 opacity-2">
          {patient.rxCount}
        </Badge>
      )}
    </td>
    {/*<td className="fs-xs w-25">{patient.note && patient.note}</td>*/}
    <td className="fs-xs w-50">
      <PatientNote
        patientId={patient._id}
        note={patient.note}
        noteHistory={patient.noteHistory}
        user={auth.user}
        noteType={"first"}
      />
    </td>
    <td className="fs-xs w-25">
      <PatientNote
        patientId={patient._id}
        note={patient.secondNote}
        noteHistory={patient.noteHistory}
        user={auth.user}
        noteType={"second"}
      />
    </td>
    <td className="fs-xs w-25">
      <PatientNote
        patientId={patient._id}
        note={patient.thirdNote}
        noteHistory={patient.noteHistory}
        user={auth.user}
        noteType={"third"}
      />
    </td>
    <td className="text-center">
      {patient.hasControls && (
        <span className="p-1 opacity-4">
          <i className="material-icons fs-sm text-danger">copyright</i>
        </span>
      )}
    </td>
    <td className="text-center">
      {patient.isDelivery && (
        <span className="p-1 opacity-3">
          <i className="material-icons fs-sm text-success">local_shipping</i>
        </span>
      )}
    </td>
    <td className="text-center">
      {patient.partner && (
        <span className="p-1 opacity-2">
          <i className="material-icons fs-sm">group</i>
        </span>
      )}
    </td>
    <td className="text-center px-4 px-lg-0">
      {patient.dateOfBirth && (
        <span className="rounded border-lighter fs-sm">
          {moment(patient.dateOfBirth, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY")}
        </span>
      )}
    </td>
    <td className="text-center">
      {!patient.secondaryPatient && (
        <span className="p-1 opacity-2">
          <i className="material-icons fs-sm">check_circle</i>
        </span>
      )}
    </td>
    <td className="text-center">
      {patient.primaryPhoneNumber && (
        <div className="fs-sm mb-2">
          <CopyToClipboard text={patient.primaryPhoneNumber}>
            <span className="custom-link-hover">{formatPhoneNumber(patient.primaryPhoneNumber)}</span>
          </CopyToClipboard>
          <span className="opacity-3">{patient.primaryPhoneNote}</span>
        </div>
      )}
      {patient.secondaryPhoneNumber && (
        <div className="fs-sm">
          <CopyToClipboard text={patient.secondaryPhoneNumber}>
            <span className="custom-link-hover">{formatPhoneNumber(patient.secondaryPhoneNumber)}</span>
          </CopyToClipboard>
          <span className="opacity-3">{patient.secondaryPhoneNote}</span>
        </div>
      )}
    </td>
    <td className="text-center">{patient.groupNumber && <span className="fs-xs ms-1">{patient.groupNumber}</span>}</td>

    <td className="text-center">
      <span className="rounded bg-light">
        <span className="p-1">
          {patient.sex && patient.sex !== "unknown" ? (patient.sex === "female" ? "F" : "M") : "-"}
        </span>
      </span>
    </td>
    <td className="text-center">
      {patient.mirixa && (
        <span className="p-1 opacity-2">
          <i className="material-icons fs-sm">museum</i>
        </span>
      )}
    </td>
  </tr>
);

PatientRow.propTypes = {
  patient: PropTypes.object.isRequired,
};

export default PatientRow;
