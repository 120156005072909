import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonGroup,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteDrug, updateDrug } from "../../actions/drugActions";
import _ from "lodash";

class DrugEditModal extends Component {
  state = {
    modal: false,
    brandN: this.props.editDrug.brandName,
    genericN: this.props.editDrug.genericName,
    primaryIcdCodeLocal: "",
    drugNotes: this.props.editDrug.drugNotes,
    drugClass: this.props.editDrug.drugClass,
    drugSchedule: this.props.editDrug.drugSchedule,
    extraDrugInfo: this.props.editDrug.extraDrugInfo,
    otherIcdCodesString: "",
    popoverOpen: false,
    alertVisible: false
  };

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  };

  handleDismissAlert = () => {
    this.setState({ alertVisible: false });
  };

  debounceCloseAlert = _.debounce(() => {
    this.setState({ alertVisible: false });
  }, 6000);

  checkIcdLocal = () => {
    const drug = this.props.editDrug;

    if (drug.primaryIcdCode) {
      this.setState({
        primaryIcdCodeLocal: drug.primaryIcdCode.icdCodeNum.toUpperCase()
      });
    } else {
      this.setState({ primaryIcdCodeLocal: "" });
    }
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  componentDidMount() {
    const drugProps = this.props.editDrug;
    this.checkIcdLocal();

    if (drugProps.otherIcdCodes) {
      this.updateOtherIcdCodes();
    } else {
      this.setState({ otherIcdCodesString: "" });
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangePrimaryCode = e => {
    let codeLocal = e.target.value.toUpperCase().trim();
    this.setState({ primaryIcdCodeLocal: codeLocal });
  };

  handleChangeOtherCodes = e => {
    let codes = e.target.value.toUpperCase();
    this.setState({ otherIcdCodesString: codes });
    // this.setState({ otherIcdCodesString: codes }, () => {
    //   this.saveOtherIcdCodes();
    // });
  };

  // saveOtherIcdCodes = () => {
  //   let splitArray = this.state.otherIcdCodesString.trimEnd().split(" ");
  //   const arrNoSpace = splitArray.filter(e => e !== "");
  //   this.setState({ otherIcdCodesArray: arrNoSpace });
  // };

  updateOtherIcdCodes = () => {
    const newArray = this.props.editDrug.otherIcdCodes.map(
      icdCode => icdCode.icdCodeNum
    );
    const joinedString = newArray.join(" ");
    this.setState({ otherIcdCodesString: joinedString });

    // this.setState({ otherIcdCodesString: joinedString }, () => {
    //   this.saveOtherIcdCodes();
    // });
  };

  handleDeleteClick = id => {
    this.props.deleteDrug(id);
  };

  handleEditSaveClick = id => e => {
    e.preventDefault();
    let splitArray = this.state.otherIcdCodesString.trimEnd().split(" ");
    const arrNoSpace = splitArray.filter(e => e !== "");

    const formatBrand = this.state.brandN
      .toLowerCase()
      .replace(/\w+/g, _.capitalize)
      .trim();
    const formatGeneric = this.state.genericN
      .toLowerCase()
      .replace(/\w+/g, _.capitalize)
      .trim();

    const drugJson = {
      brandName: formatBrand,
      genericName: formatGeneric,
      primaryIcdCode: this.state.primaryIcdCodeLocal.toUpperCase(),
      otherIcdCodes: arrNoSpace,
      drugNotes: this.state.drugNotes,
      drugClass: this.state.drugClass,
      drugSchedule: this.state.drugSchedule,
      extraDrugInfo: this.state.extraDrugInfo
    };

    this.props.updateDrug(id, drugJson);
    this.setState({
      brandN: formatBrand,
      genericN: formatGeneric,
      alertVisible: true
    });
    this.debounceCloseAlert();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.otherIcdCodesString !== "" &&
      this.props.editDrug.otherIcdCodes !== prevProps.editDrug.otherIcdCodes &&
      this.props.editDrug.otherIcdCodes
    ) {
      this.updateOtherIcdCodes();
      // console.log("Did update OTHER CODES ");
    }

    if (
      this.props.editDrug.primaryIcdCode !== prevProps.editDrug.primaryIcdCode
    ) {
      this.checkIcdLocal();
      // console.log("Did update ICD LOCAL ")
    }
  }

  onChangeScheduleDrug = e => {
    if (e.target.value === "Not a schedule drug") {
      this.setState({ drugSchedule: "0" });
    } else {
      this.setState({ drugSchedule: e.target.value });
    }
  };

  render() {
    const drug = this.props.editDrug;
    const {
      modal,
      brandN,
      genericN,
      primaryIcdCodeLocal,
      drugNotes,
      drugClass,
      drugSchedule,
      extraDrugInfo,
      otherIcdCodesString
    } = this.state;

    return (
      <div>
        <Button
          className="custom-btn"
          color="primary"
          onClick={this.toggle}
          style={{ width: "100%" }}
        >
          Edit
        </Button>

        <Modal isOpen={modal} toggle={this.toggle} centered={true}>
          <ModalHeader toggle={this.toggle}>Edit {drug.brandName}</ModalHeader>
          <ModalBody>
            <Alert
              isOpen={this.state.alertVisible}
              toggle={this.handleDismissAlert}
            >
              <span>Saved changes successfully.</span>
            </Alert>
            <Form onSubmit={this.handleEditSaveClick(drug._id)} autoComplete="off">
              {/*<Form onSubmit={this.handleEditSaveClick.bind(this)}>*/}
              <FormGroup>
                <Input
                  type="text"
                  name="brandN"
                  id="drug"
                  value={brandN}
                  onChange={this.handleChange}
                  required
                />
                <Input
                  type="text"
                  name="genericN"
                  id="drug"
                  value={genericN}
                  onChange={this.handleChange}
                  required
                />

                <Input
                  type="text"
                  name="primaryIcdCodeLocal"
                  id="drug"
                  placeholder="Enter Primary ICD Code"
                  value={primaryIcdCodeLocal}
                  onChange={this.handleChangePrimaryCode}
                  required
                />

                <Input
                  type="text"
                  name="otherIcdCodesString"
                  id="drug"
                  placeholder="Enter Other ICD Codes"
                  value={otherIcdCodesString}
                  onChange={this.handleChangeOtherCodes}
                />

                <Input
                  type="text"
                  name="drugClass"
                  id="drug"
                  placeholder="Drug class"
                  value={drugClass}
                  onChange={this.handleChange}
                />
                <Input
                  type="text"
                  name="drugNotes"
                  id="drug"
                  placeholder="Drug notes"
                  value={drugNotes}
                  onChange={this.handleChange}
                />
                <Input
                  type="text"
                  name="extraDrugInfo"
                  id="drug"
                  placeholder="Extra information about the drug"
                  value={extraDrugInfo}
                  onChange={this.handleChange}
                />

                <Input
                  type="select"
                  name="drugSchedule"
                  id="drug"
                  value={drugSchedule}
                  onChange={this.onChangeScheduleDrug}
                  required
                >
                  <option>Not a schedule drug</option>
                  <option>I</option>
                  <option>II</option>
                  <option>III</option>
                  <option>IV</option>
                  <option>V</option>
                </Input>

                <ButtonGroup className="d-flex">
                  <Button
                    color="dark"
                    // onClick={this.handleEditSaveClick.bind(this, drug._id)}
                    style={{ flexBasis: "75%" }}
                  >
                    Save Changes
                  </Button>

                  {/*<Button*/}
                  {/*color="danger"*/}
                  {/*onClick={this.onDeleteClick.bind(this, drug._id)}*/}
                  {/*style={{ flexBasis: "25%" }}*/}
                  {/*>*/}
                  {/*Delete*/}
                  {/*</Button>*/}

                  <Button
                    color="danger"
                    id={"popover" + drug._id}
                    style={{ flexBasis: "25%" }}
                    onClick={this.togglePopover}
                  >
                    Delete
                  </Button>

                  <Popover
                    style={{ textAlign: "center" }}
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target={"popover" + drug._id}
                    toggle={this.togglePopover}
                  >
                    <PopoverHeader>
                      <strong>
                        Are you sure you want to{" "}
                        <span style={{ color: "red" }}>PERMANENTLY</span> delete
                        this drug?
                        <div style={{ color: "#ab0002" }}>
                          You can not undo this.
                        </div>
                      </strong>
                    </PopoverHeader>
                    <PopoverBody style={{ paddingTop: "5px" }}>
                      <Button
                        style={{ marginTop: "0px" }}
                        onClick={this.togglePopover}
                        className="btn btn-primary custom-btn"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.handleDeleteClick.bind(this, drug._id)}
                        style={{ marginTop: "0px" }}
                        className="btn btn-danger custom-btn"
                      >
                        Yes Delete
                      </Button>
                    </PopoverBody>
                  </Popover>
                </ButtonGroup>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

DrugEditModal.propTypes = {
  //   getDrugs: PropTypes.func.isRequired,
  drug: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  drug: state.drug
});

export default connect(
  mapStateToProps,
  { deleteDrug, updateDrug }
)(DrugEditModal);
