import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Card,
  Badge,
  FormGroup,
  Button,
  InputGroup,
  Input,
  Alert,
  ModalBody,
  Modal,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  findFamily,
  addPartner,
  getFamily,
  updateFamily,
  removePartners,
  clearPatientError,
  clearPatientResponse,
} from "../../../actions/patientActions";
import _ from "lodash";
import classNames from "classnames";
import moment from "moment";
import { Link } from "react-router-dom";
import FamilyMembersTable from "./FamilyMembersTable";
import UnlinkFamTooltip from "./UnlinkFamTooltip";

class FamilyLink extends Component {
  state = {
    primaryPatientId: this.props.patientId,
    partner: this.props.patient.currentPatient.partner,
    firstName: "",
    lastName: "",
    response: this.props.patient.response,
    alertVisible: true,
    relationship: "",
  };

  componentDidMount() {
    this.props.getFamily(this.props.patientId);
  }

  componentWillUnmount() {
    this.handleClearResponse();
    this.props.clearPatientError({});
  }

  componentDidUpdate(prevProps, prevState) {
    // this.state.relationship !== prevState.relationship && this.findFamily(this.state.primaryPatientId);
    // this.props.patient.currentPatient.family !== prevProps.patient.currentPatient.family &&
    //   this.props.patient.response.length > 0 &&
    //   this.props.clearPatientResponse();
  }

  handleChangeTrimmed = (e) => {
    this.setState({ [e.target.name]: e.target.value.trim() });
  };

  handleSubmit = (patientId) => (e) => {
    e.preventDefault();
    // console.log(this.state.firstName + " " + this.state.lastName);
    this.findFamily(patientId);
  };

  findFamily = (patientId) => {
    const formatFirst = _.startCase(this.state.firstName.toLowerCase());
    const formatLast = _.startCase(this.state.lastName.toLowerCase());

    this.props.findFamily({
      firstName: formatFirst,
      lastName: formatLast,
      patientId: patientId,
    });
  };

  handleAddPartner = (partnerId) => {
    const patientId = this.state.primaryPatientId;
    this.props.addPartner(patientId, partnerId);
    this.setState({ relationship: "" });
    // console.log("handleAddPartner() ran: " + patientId + " | " + partnerId);
  };

  handleAddFamily = (memberId, relationship) => {
    const patientId = this.state.primaryPatientId;
    if (relationship === "Partner") {
      this.handleAddPartner(memberId);
    } else {
      this.props.updateFamily(patientId, memberId, relationship);
      // console.log("not partner =]");
    }

    this.setState({ relationship: "" });
  };

  handleRemovePartners = (patientId, partnerId) => {
    // const patientId = this.state.primaryPatientId;
    this.props.removePartners(patientId, partnerId);
  };

  handleDismissAlert = () => {
    this.setState({ alertVisible: false });
    this.props.clearPatientError({});
  };

  handleClearResponse = () => {
    this.props.clearPatientResponse("");
  };

  // selectRel = (e) => {
  //   this.setState({ relationship: e.target.innerText });
  // };

  selectRel = (e) => {
    this.setState({ relationship: e.target.innerText });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  isSamePatient = (firstId, secondId) => {
    return firstId === secondId;
  };

  isAlreadyFamily = (patientId, family) => {
    // console.log("patientID: " + patientId);
    // console.log("primary family: " + JSON.stringify(family));
    const filtered = family.filter((fam) => {
      return fam.member._id === patientId;
    });
    // console.log("filtered: " + JSON.stringify(filtered));
    return filtered.length > 0;
  };

  render() {
    const { error, response, currentPatient } = this.props.patient;
    const { modal, primaryPatientId, relationship } = this.state;
    const partnerId = currentPatient.partner ? currentPatient.partner._id : 0;

    return (
      <div>
        <Button
          color="warning"
          className="btn-sm py-1 px-2 badge fw-normal"
          onClick={this.toggle}
          style={{
            right: "12px",
            opacity: this.props.linkOrEdit === "Change/Unlink" && "0.6",
            backgroundColor: this.props.linkOrEdit !== "Change/Unlink" && "rgb(255,218,134)",
          }}
        >
          {/*{this.props.linkOrEdit}*/}
          Edit Family
        </Button>
        {!currentPatient.partner && (
          <div className="mb-2">
            <div className="mb-0 opacity-2 d-inline-flex align-items-center">Partner</div>
            <div className="opacity-1 fs-sm font-italic">no partner</div>
          </div>
        )}

        {!!currentPatient.partner && (
          <div className="mb-2">
            {/*<span className="opacity-2 mr-1 font-size-sm">Family</span>*/}
            <div className="mb-0 opacity-2 d-inline-flex align-items-center">
              Partner{" "}
              {currentPatient.partner.deceased && (
                <Badge color="danger" className="mt-0 ms-1 fs-xs">
                  DECEASED
                </Badge>
              )}
            </div>
            <div className="d-flex align-items-center">
              <Link
                to={`/patients/${currentPatient.partner._id}`}
                // target="_blank"
                // className={patient.partner.active ? "font-weight-bold" : "font-weight-bold text-danger"}
                className={classNames("fw-bold", {
                  "text-danger": !currentPatient.partner.active,
                  "opacity-2": currentPatient.partner.deceased,
                })}
              >
                {currentPatient.partner.firstName} {currentPatient.partner.lastName}
              </Link>
              {/*{currentPatient.partner.deceased && <span className="font-size-xs mt-0 ml-1 opacity-2"> deceased</span>}*/}
            </div>
          </div>
        )}

        <Modal size="lg" isOpen={modal} toggle={this.toggle} centered={true} className="text-center">
          <Button size="xs" color="light" onClick={this.toggle} className="fs-xs">
            Close
          </Button>
          <ModalBody>
            <Row>
              <Col md="6">
                <div className="opacity-2 mb-0">Current Partner</div>
                <div>
                  {currentPatient.partner ? (
                    <div>
                      <h4 className="mb-0 mt-0 text-uppercase fw-bold">
                        <Link
                          to={`/patients/${currentPatient.partner._id}`}
                          target="_blank"
                          className={classNames("custom-link-hover rounded border mx-0", {
                            "text-danger opacity-4": currentPatient.partner.deceased,
                          })}
                        >
                          {currentPatient.partner.lastName}, {currentPatient.partner.firstName}
                          {currentPatient.partner.deceased && <div className="text-danger fs-xs">deceased</div>}
                          {!currentPatient.partner.active && !currentPatient.partner.deceased && (
                            <div className="text-danger fs-xs">inactive</div>
                          )}
                        </Link>
                      </h4>
                      <UnlinkFamTooltip
                        mainId={currentPatient._id}
                        famId={currentPatient.partner._id}
                        index="index-partner"
                        partners={true}
                      />
                      {/*<Button*/}
                      {/*  color="danger"*/}
                      {/*  size="sm"*/}
                      {/*  onClick={() => this.handleRemovePartners(currentPatient._id, currentPatient.partner._id)}*/}
                      {/*>*/}
                      {/*  Unlink Partner*/}
                      {/*</Button>*/}
                    </div>
                  ) : (
                    <Badge color="light" className="p-2 text-wrap text-lighter d-block">
                      this patient has no linked partner
                    </Badge>
                  )}
                </div>
              </Col>
              <Col md="6">
                <div>
                  {/*<h6 className="opacity-3 mb-1">Other Family</h6>*/}
                  {/*{currentPatient.family && <PatientFamily patientId={primaryPatientId} />}*/}
                  {currentPatient.family && (
                    <FamilyMembersTable
                      family={currentPatient.family}
                      primaryPatientId={primaryPatientId}
                      showUnlink={true}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <hr />
            <h6 className="opacity-3 mb-2">Search</h6>
            <Form
              onSubmit={this.handleSubmit(primaryPatientId)}
              autoComplete="off"
              // className={classNames({ "opacity-2": !!currentPatient.partner })}
            >
              <FormGroup>
                <Row className="g-0">
                  <Col sm={6} className="p-2">
                    <InputGroup>
                      <Input
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        value={this.state.firstName}
                        onChange={this.handleChangeTrimmed}
                        required
                      />
                    </InputGroup>
                  </Col>
                  <Col sm={6} className="p-2">
                    <Input
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      value={this.state.lastName}
                      onChange={this.handleChangeTrimmed}
                      required
                    />
                  </Col>
                  <Col xs={12}>
                    {/*<Button color="primary" block={true} disabled={!!currentPatient.partner} className="mt-0">*/}
                    <Button color="primary" block={true} className="mt-0">
                      Search
                    </Button>
                  </Col>
                  {response.length > 0 && (
                    <Col sm="12" className="mt-3">
                      <Alert
                        color="light"
                        isOpen={true}
                        toggle={this.handleClearResponse}
                        className="container-fluid border rounded-8 bg-light p-3"
                        closeClassName="p-2"
                      >
                        {!response[0].msg && (
                          <div>
                            <UncontrolledDropdown className="text-center mx-3 mb-2">
                              <DropdownToggle color="dark" className="mt-0 line-height-1 p-2 rounded-8">
                                {relationship ? (
                                  <div className="fs-xl text-warning fw-bold">{relationship}</div>
                                ) : (
                                  <>⇢ Select Relationship ⇠</>
                                )}
                              </DropdownToggle>
                              <DropdownMenu className="text-center rounded-8 dropdown-menu-center">
                                <DropdownItem onClick={this.selectRel}>Partner</DropdownItem>
                                <DropdownItem onClick={this.selectRel}>Parent</DropdownItem>
                                <DropdownItem onClick={this.selectRel}>Child</DropdownItem>
                                <DropdownItem onClick={this.selectRel}>Sibling</DropdownItem>
                                <DropdownItem onClick={this.selectRel}>Grandparent</DropdownItem>
                                <DropdownItem onClick={this.selectRel}>Grandchild</DropdownItem>
                                <DropdownItem onClick={this.selectRel}>Aunt/Uncle</DropdownItem>
                                <DropdownItem onClick={this.selectRel}>Niece/Nephew</DropdownItem>
                                <DropdownItem onClick={this.selectRel}>Cousin</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        )}

                        {response[0].msg ? (
                          <div className="text-success">
                            <b>{response[0].msg}</b>
                          </div>
                        ) : (
                          <div className="overflow-y-auto max-height-50 mx-n1">
                            {response.map((foundPatient, index) => {
                              const { _id, firstName, lastName, partner, dateOfBirth, family, active, deceased } =
                                foundPatient;
                              const isSameAsPrimaryPatient = this.isSamePatient(_id, primaryPatientId);
                              const isSameAsPartner = this.isSamePatient(_id, partnerId);
                              // const hasOtherPartner = partner;
                              const isAlreadyFamily = this.isAlreadyFamily(primaryPatientId, family);
                              const disabledX = isSameAsPrimaryPatient || isSameAsPartner || isAlreadyFamily;
                              // isSameAsPrimaryPatient || isSameAsPartner || isAlreadyFamily || !!partner;
                              return (
                                <Card
                                  key={index}
                                  className={classNames("p-2 my-2 mx-2 border", {
                                    "opacity-4 bg-danger-transparent": disabledX,
                                  })}
                                >
                                  <div>
                                    {isSameAsPrimaryPatient && (
                                      <div className="fw-bold font-small text-danger">
                                        CAN NOT LINK PATIENT TO ITSELF
                                      </div>
                                    )}
                                    <div>
                                      <Link
                                        to={`/patients/${_id}`}
                                        target="_blank"
                                        className={classNames(
                                          "custom-link-hover text-uppercase rounded text-primary fw-bolder fs-xl",
                                          {
                                            "text-danger fw-bold disabled":
                                              isSameAsPrimaryPatient || deceased || !active,
                                          }
                                        )}
                                      >
                                        {firstName} {lastName}
                                        {deceased && <div className="text-danger fs-xs">deceased</div>}
                                        {!active && !deceased && <div className="text-danger fs-xs">inactive</div>}
                                      </Link>
                                    </div>
                                    {dateOfBirth && (
                                      <div>
                                        <span color="light" className="m-1 opacity-3 position-absolute top-0 right-0">
                                          {moment(dateOfBirth, "YYYY-MM-DDTHH:mm:ss").format("MM-DD-YYYY")}
                                        </span>
                                      </div>
                                    )}
                                    {partner && (
                                      <>
                                        {!isSameAsPrimaryPatient && relationship === "Partner" && (
                                          <div className="border rounded-8 text-danger p-2 m-2">
                                            <div>
                                              {firstName} <strong>is already the partner of</strong>{" "}
                                              <span className="text-primary ms-2">
                                                {!this.isSamePatient(partner._id, primaryPatientId) ? (
                                                  <Link
                                                    to={`/patients/${partner._id}`}
                                                    target="_blank"
                                                    className="custom-link-hover bg-light rounded border"
                                                  >
                                                    {partner.firstName} {partner.lastName}
                                                  </Link>
                                                ) : (
                                                  <b className="text-danger">this patient</b>
                                                )}
                                              </span>
                                              <UnlinkFamTooltip
                                                mainId={_id}
                                                famId={partner._id}
                                                index="index2-partner"
                                                partners={true}
                                              />
                                              {/*<Button*/}
                                              {/*  color="danger"*/}
                                              {/*  className="mb-1 mt-1"*/}
                                              {/*  onClick={() => this.handleRemovePartners(_id, partner._id)}*/}
                                              {/*>*/}
                                              {/*  Unlink {firstName} {lastName} from {partner.firstName}{" "}*/}
                                              {/*  {partner.lastName}*/}
                                              {/*</Button>*/}
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )}
                                    {family && (
                                      <>
                                        {family.length > 0 && (
                                          <FamilyMembersTable
                                            family={family}
                                            primaryPatientId={primaryPatientId}
                                            foundPatientId={_id}
                                            showUnlink={true}
                                          />
                                        )}
                                      </>
                                    )}
                                    {!disabledX ? (
                                      <div className="d-flex align-items-center justify-content-center">
                                        <Button
                                          className="border mt-1 mb-1"
                                          color="success"
                                          disabled={
                                            !relationship ||
                                            (currentPatient.partner && relationship === "Partner") ||
                                            (!!partner && relationship === "Partner")
                                          }
                                          onClick={() => this.handleAddFamily(_id, relationship)}
                                        >
                                          {relationship ? (
                                            <>
                                              {relationship === "Partner" && currentPatient.partner && !!partner ? (
                                                <span>Please unlink existing partner first</span>
                                              ) : (
                                                <span className="text-warning fw-bold">
                                                  {(partner && relationship === "Partner") ||
                                                  // with ._id causing crash --- cant find Partner 3-19-23
                                                  // (currentPatient.partner._id && relationship === "Partner") ? (
                                                  (partnerId && relationship === "Partner") ? (
                                                    <span className="text-danger">UNLINK THEIR PARTNER FIRST</span>
                                                  ) : (
                                                    <>Add as {relationship} </>
                                                  )}
                                                </span>
                                              )}
                                            </>
                                          ) : (
                                            <span className="opacity-2">SELECT RELATIONSHIP ABOVE</span>
                                          )}
                                          {/*{lastName}*/}
                                        </Button>
                                      </div>
                                    ) : (
                                      <span className="opacity-3 font-italic">already linked to this patient</span>
                                    )}
                                  </div>

                                  {/*{!this.isSamePatient(_id, primaryPatientId) && (*/}
                                  {/*  <div className="mt-2">*/}
                                  {/*    <Badge color="light">*/}
                                  {/*      <Link*/}
                                  {/*        to={_id}*/}
                                  {/*        target="_blank"*/}
                                  {/*        className="custom-link-hover btn-link"*/}
                                  {/*      >*/}
                                  {/*        Go to {firstName} {lastName}'s Page*/}
                                  {/*      </Link>*/}
                                  {/*    </Badge>*/}
                                  {/*  </div>*/}
                                  {/*)}*/}
                                </Card>
                              );
                            })}
                          </div>
                        )}
                      </Alert>
                    </Col>
                  )}

                  {error.errorMessage && (
                    <Alert color="danger" isOpen={this.state.alertVisible} toggle={this.handleDismissAlert}>
                      <div>{error.errorMessage}</div>
                    </Alert>
                  )}
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>

        {currentPatient.family && (
          <div>
            {/*<PatientFamily patientId={primaryPatientId} />*/}

            <FamilyMembersTable
              family={currentPatient.family}
              primaryPatientId={this.props.patientId ? this.props.patientId : primaryPatientId}
            />

            {/*{currentPatient.family.length ? (*/}
            {/*  currentPatient.family.map((fam, i) => {*/}
            {/*    return <div key={i + "fam"}>{fam.relationship}</div>;*/}
            {/*  })*/}
            {/*) : (*/}
            {/*  <Badge color="secondary" className="p-2 text-wrap opacity-3">*/}
            {/*    no other linked family members*/}
            {/*  </Badge>*/}
            {/*)}*/}
          </div>
        )}
      </div>
    );
  }
}

FamilyLink.propTypes = {
  patient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
  error: state.patient,
});

export default connect(mapStateToProps, {
  findFamily,
  addPartner,
  getFamily,
  updateFamily,
  removePartners,
  clearPatientError,
  clearPatientResponse,
})(FamilyLink);
