import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Alert,
  InputGroup,
  InputGroupText,
  // Fade,
  // ButtonGroup,
  // Card,
  // Popover,
  // PopoverHeader,
  // PopoverBody,
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deletePatient, updatePatient, updatePatientActiveStatus } from "../../../actions/patientActions";
import moment from "moment";
import _ from "lodash";
import { formatDateCal } from "../../Utilities/CalculateDate";
import PatientActive from "./PatientActive";
import PatientDelete from "./PatientDelete";

const exists = (value, isDate, altValue, objectKey) => {
  let alt;
  altValue ? (alt = altValue) : (alt = "");
  if (altValue === "not") {
    alt = false;
  }
  if (isDate) {
    return value ? formatDateCal(value) : "";
  }
  return value ? value : alt;
};

// const existsObj = (obj, objectKey, isDate) => {
//   if (isDate) {
//     return obj.date ? formatDateCal(obj.date) : "";
//   }
//   return obj[objectKey] ? obj[objectKey] : "";
// };

class PatientEditModal extends Component {
  state = {
    active: this.props.editPatient.active,
    firstName: this.props.editPatient.firstName,
    lastName: this.props.editPatient.lastName,
    dateOfBirth: formatDateCal(this.props.editPatient.dateOfBirth),
    mirixa: this.props.editPatient.mirixa,
    secondaryPatient: this.props.editPatient.secondaryPatient,
    sex: exists(this.props.editPatient.sex, false, "unknown"),

    hasControls: exists(this.props.editPatient.hasControls, false, "not"),
    isDelivery: exists(this.props.editPatient.isDelivery, false, "not"),
    rxCount: exists(this.props.editPatient.rxCount, false),
    groupNumber: exists(this.props.editPatient.groupNumber, false),
    primaryPhoneNumber: exists(this.props.editPatient.primaryPhoneNumber, false),
    primaryPhoneNote: exists(this.props.editPatient.primaryPhoneNote, false),
    secondaryPhoneNumber: exists(this.props.editPatient.secondaryPhoneNumber, false),
    secondaryPhoneNote: exists(this.props.editPatient.secondaryPhoneNote, false),

    modal: false,
    response: "",
  };

  // needed to do this to update local form state
  componentDidUpdate(prevProps, prevState, snapshot) {
    prevProps.editPatient !== this.props.editPatient &&
      this.setState({
        active: this.props.editPatient.active,
        firstName: this.props.editPatient.firstName,
        lastName: this.props.editPatient.lastName,
        dateOfBirth: formatDateCal(this.props.editPatient.dateOfBirth),
        mirixa: this.props.editPatient.mirixa,
        secondaryPatient: this.props.editPatient.secondaryPatient,
        sex: exists(this.props.editPatient.sex, false, "unknown"),

        hasControls: exists(this.props.editPatient.hasControls, false, "not"),
        isDelivery: exists(this.props.editPatient.isDelivery, false, "not"),
        rxCount: exists(this.props.editPatient.rxCount, false),
        groupNumber: exists(this.props.editPatient.groupNumber, false),
        primaryPhoneNumber: exists(this.props.editPatient.primaryPhoneNumber, false),
        primaryPhoneNote: exists(this.props.editPatient.primaryPhoneNote, false),
        secondaryPhoneNumber: exists(this.props.editPatient.secondaryPhoneNumber, false),
        secondaryPhoneNote: exists(this.props.editPatient.secondaryPhoneNote, false),

        response: "successfully updated",
        touched: false,
      });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      response: "",
    });
  };

  toggleBoolean = (state) => {
    this.setState({ [state]: !this.state[state] });

    !this.state.touched && this.setState({ touched: true, response: "" });
  };

  handleChangeTrimmed = (e) => {
    this.setState({ [e.target.name]: e.target.value.trim() });

    !this.state.touched && this.setState({ touched: true, response: "" });
  };

  // handleChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  //
  //   !this.state.touched && this.setState({ touched: true, response: "" });
  // };

  // adjusted for rxCount
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "rxCount") {
      const numericValue = value.replace(/^0+/, ""); // Remove leading zeros
      if (numericValue === "" || (numericValue <= 99 && numericValue >= 0)) {
        this.setState({ [name]: numericValue });
      }
    } else {
      this.setState({ [name]: value });
    }

    !this.state.touched && this.setState({ touched: true, response: "" });
  };

  clearResponse = () => {
    this.setState({ response: "" });
  };

  handleEditSaveClick = (id) => (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      dateOfBirth,
      sex,
      secondaryPatient,
      mirixa,
      primaryPhoneNumber,
      primaryPhoneNote,
      secondaryPhoneNumber,
      secondaryPhoneNote,
      hasControls,
      isDelivery,
      rxCount,
      groupNumber,
    } = this.state;

    const formatFirst = _.startCase(firstName.toLowerCase());
    const formatLast = _.startCase(lastName.toLowerCase());

    const patientJson = {
      firstName: formatFirst,
      lastName: formatLast,
      dateOfBirth,
      sex,
      mirixa,
      secondaryPatient,
      primaryPhoneNumber,
      primaryPhoneNote,
      secondaryPhoneNumber,
      secondaryPhoneNote,
      hasControls,
      isDelivery,
      rxCount,
      groupNumber,
      userName: this.props.auth.name,
      userId: this.props.auth._id,
    };

    // this.state.primaryPhoneNumber && (patientJson = { ...patientJson, primaryPhoneNumber: this.state.primaryPhoneNumber });

    dateOfBirth === "Invalid date" && delete patientJson.dateOfBirth;

    this.props.updatePatient(id, patientJson);
    // this.toggle();
  };

  render() {
    const patient = this.props.editPatient;
    const { modal, active, response } = this.state;
    const oneYearAgo = moment().subtract(1, "years").format("YYYY-MM-DD");
    // const today = moment().format("YYYY-MM-DD");

    return (
      <div>
        <Button color="primary" outline onClick={this.toggle} className="line-height-1 py-1 btn-sm fs-xs border">
          {/*<i className="material-icons m-icon-custom">create</i> */}
          Edit
        </Button>

        <Modal size="lg" isOpen={modal} toggle={this.toggle} centered={true}>
          <ModalHeader toggle={this.toggle} className="py-2">
            Edit <b>{patient.firstName}</b> {!patient.active && <div className="badge badge-secondary">INACTIVE</div>}
            {!!response && (
              <Alert
                color="success"
                isOpen={!!response}
                onClick={this.clearResponse}
                tag="span"
                className="p-1 px-2 mx-2 text-center fs-sm"
              >
                {response}
              </Alert>
            )}
          </ModalHeader>
          <ModalBody style={{ backgroundColor: !active && "rgba(255,150,150,0.13)" }}>
            {/*---------------BASIC PATIENT INFO----------------*/}
            {/*---------------BASIC PATIENT INFO----------------*/}
            {/*---------------BASIC PATIENT INFO----------------*/}
            <Row className="g-0">
              <Col md="6" className="py-2 pe-3">
                <Form onSubmit={this.handleEditSaveClick(patient._id)} autoComplete="off" id="patientBasicInfo">
                  <FormGroup className="mb-1">
                    <InputGroup size="sm" className="mb-2">
                      <InputGroupText>First Name</InputGroupText>
                      <Input
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        value={this.state.firstName}
                        onChange={this.handleChangeTrimmed}
                        className="form-control"
                        required
                      />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-2">
                      <InputGroupText>Last Name</InputGroupText>
                      <Input
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                        // id="patientLastName"
                        value={this.state.lastName}
                        onChange={this.handleChangeTrimmed}
                        className="form-control"
                        required
                      />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-2">
                      <InputGroupText>Born</InputGroupText>
                      <Input
                        type="date"
                        name="dateOfBirth"
                        min="1920-01-01"
                        max={oneYearAgo}
                        id="dobId"
                        value={this.state.dateOfBirth}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                    </InputGroup>

                    <InputGroup size="sm" className="mb-2">
                      <InputGroupText>Sex</InputGroupText>
                      <select
                        // type="select"
                        name="sex"
                        value={this.state.sex}
                        onChange={this.handleChange}
                        className="form-control"
                      >
                        <option value="unknown">Unknown</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                      </select>
                    </InputGroup>

                    {/*---------------MIRIXA----------------*/}
                    <InputGroup size="sm" className="mb-1">
                      <InputGroupText>Mirixa</InputGroupText>
                      <InputGroupText>
                        <Input
                          addon
                          type="checkbox"
                          name="mirixa"
                          checked={this.state.mirixa}
                          onChange={() => this.toggleBoolean("mirixa")}
                          aria-label="Mirixa"
                        />

                        {this.state.mirixa && <i className="material-icons fs-md ms-2">museum</i>}
                      </InputGroupText>
                    </InputGroup>

                    {/*---------------SECONDARY----------------*/}
                    <InputGroup size="sm" className="mb-3">
                      <InputGroupText>Secondary Patient</InputGroupText>
                      <InputGroupText>
                        <Input
                          addon
                          type="checkbox"
                          name="secondaryPatient"
                          checked={this.state.secondaryPatient}
                          onChange={() => this.toggleBoolean("secondaryPatient")}
                          aria-label="Secondary Patient"
                        />
                        {this.state.secondaryPatient && <i className="material-icons fs-md ms-2">people_alt</i>}
                      </InputGroupText>
                    </InputGroup>

                    <div className="border rounded-8 p-1 bg-info-light mb-3">
                      {/*---------------RX COUNT----------------*/}
                      <InputGroup size="md" className="mb-1">
                        <InputGroupText>Rx Count: </InputGroupText>
                        {/*<InputGroupText>*/}
                        <Input
                          addon
                          type="number"
                          max="99"
                          name="rxCount"
                          value={this.state.rxCount}
                          onChange={this.handleChange}
                          aria-label="Rx Count"
                        />
                        {/*{this.state.rxCount && <span className="fw-light"> {this.state.rxCount}</span>}*/}
                        {/*</InputGroupText>*/}
                      </InputGroup>
                    </div>

                    {/*---------------PHONE----------------*/}
                    <div className="border rounded-8 p-1 mb-3">
                      <InputGroup size="sm" className="">
                        <InputGroupText>Phone</InputGroupText>
                        <Input
                          autoComplete="none"
                          type="tel"
                          maxLength="10"
                          minLength="10"
                          name="primaryPhoneNumber"
                          placeholder="Phone number"
                          // id="patientLastName"
                          value={this.state.primaryPhoneNumber}
                          onChange={this.handleChangeTrimmed}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="">
                        <InputGroupText>Note</InputGroupText>
                        <Input
                          autoComplete="none"
                          type="text"
                          name="primaryPhoneNote"
                          placeholder="Note for phone #"
                          // id="patientLastName"
                          value={this.state.primaryPhoneNote}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className=" mt-3">
                        <InputGroupText>Phone-2</InputGroupText>
                        <Input
                          autoComplete="none"
                          type="tel"
                          maxLength="10"
                          minLength="10"
                          name="secondaryPhoneNumber"
                          placeholder="Phone number"
                          // id="patientLastName"
                          value={this.state.secondaryPhoneNumber}
                          onChange={this.handleChangeTrimmed}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="">
                        <InputGroupText>Note-2</InputGroupText>
                        <Input
                          autoComplete="none"
                          type="text"
                          name="secondaryPhoneNote"
                          placeholder="Note for 2nd phone #"
                          // id="patientLastName"
                          value={this.state.secondaryPhoneNote}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                    </div>

                    <div className="border rounded-8 p-1">
                      {/*---------------DELIVERY----------------*/}
                      <InputGroup size="md" className="mb-1">
                        <InputGroupText>Delivery:</InputGroupText>
                        <InputGroupText>
                          <Input
                            addon
                            type="checkbox"
                            name="isDelivery"
                            checked={this.state.isDelivery}
                            onChange={() => this.toggleBoolean("isDelivery")}
                            aria-label="Is Delivery"
                          />
                          {this.state.isDelivery && <i className="material-icons fs-md ms-3">local_shipping</i>}
                        </InputGroupText>
                      </InputGroup>

                      {/*---------------CONTROLS----------------*/}
                      <InputGroup size="md" className="">
                        <InputGroupText className="bg-danger-transparent">Has Control Drugs:</InputGroupText>
                        <InputGroupText>
                          <Input
                            addon
                            type="checkbox"
                            name="hasControls"
                            checked={this.state.hasControls}
                            onChange={() => this.toggleBoolean("hasControls")}
                            aria-label="Has Controls"
                          />
                          {this.state.hasControls && <i className="material-icons fs-md ms-3">crisis_alert</i>}
                        </InputGroupText>
                      </InputGroup>
                    </div>

                    {/*---------------GROUP NUMBER----------------*/}
                    <div className="border rounded-8 p-1 mb-3 mt-2">
                      <InputGroup size="sm" className="">
                        <InputGroupText>Insurance Group #: </InputGroupText>
                        {/*<InputGroupText>*/}
                        <Input
                          autoComplete="none"
                          type="text"
                          name="groupNumber"
                          placeholder="Insurance group #"
                          value={this.state.groupNumber}
                          onChange={this.handleChangeTrimmed}
                        />
                      </InputGroup>
                    </div>

                    <Button block color="dark" disabled={!this.state.touched} className="py-1 my-2">
                      {/*Save Changes*/}
                      Save Changes
                    </Button>
                  </FormGroup>
                </Form>
              </Col>

              {/*------------------INACTIVE & REASONS----------------*/}
              {/*------------------INACTIVE & REASONS----------------*/}
              {/*------------------INACTIVE & REASONS----------------*/}
              <Col md="6" className="py-0">
                <PatientActive editPatient={this.props.editPatient} />

                {/*-----------------DELETE PATIENT-----------------*/}
                {/*-----------------DELETE PATIENT-----------------*/}
                {/*-----------------DELETE PATIENT-----------------*/}
                <PatientDelete editPatient={this.props.editPatient} />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

PatientEditModal.propTypes = {
  patient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
  auth: state.auth,
});

export default connect(mapStateToProps, { deletePatient, updatePatient, updatePatientActiveStatus })(PatientEditModal);
