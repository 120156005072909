import React, { useState, useEffect } from "react";
import { Button, Input, Badge } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { updatePatientNote } from "../../../actions/patientActions";
import { formatDate } from "../../Utilities/CalculateDate";

const PatientNote = ({ note, patientId, noteHistory, showNoteTitle, showPastEdits, noteType }) => {
  const [noteContent, setNoteContent] = useState(note ? note : "");
  const [edit, setEdit] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [touched, setTouched] = useState(false);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setNoteContent(note ? note : "");
  }, [note]);

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const toggleShowHistory = () => {
    setShowHistory(!showHistory);
  };

  const cancelEdit = () => {
    toggleEdit();
    setNoteContent(note);
    setTouched(false);
  };

  const handleChange = (e) => {
    setNoteContent(e.target.value);
    !touched && setTouched(true);
  };

  const handleSubmit = (id) => {
    const cleanContent = noteContent.length > 0 ? noteContent.replace(/\s{2,}/g, " ").trim() : "";

    if (cleanContent === note) {
      cancelEdit();
    } else {
      const noteJson = {
        noteContent: cleanContent,
        userWhoEdited: auth.name,
        userIdWhoEdited: auth._id,
        noteType: noteType,
      };
      dispatch(updatePatientNote(id, noteJson));
      toggleEdit();
      setNoteContent(cleanContent);
    }
  };

  const handleKeyDown = (e, id) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(id);
    }
  };

  return (
    <div>
      <div className="opacity-2 d-flex align-items-center justify-content-between">
        {!showNoteTitle ? "" : "Note "}
        {noteHistory.length > 0 && showPastEdits && (
          <Button color="light" onClick={toggleShowHistory} className="border badge ms-2 mt-0">
            {showHistory ? "Close" : "View"} Past Edits
          </Button>
        )}
      </div>
      <div>
        {edit && (
          <div>
            <Input
              name="noteContent"
              type="textarea"
              value={noteContent}
              onChange={handleChange}
              onKeyDown={(e) => handleKeyDown(e, patientId)}
              className="line-height-1 p-2"
              style={{ minHeight: "70px" }}
            />
            <div className="">
              <Button
                color="primary"
                size="sm"
                className="my-1"
                disabled={!touched}
                onClick={() => handleSubmit(patientId)}
              >
                Save Changes
              </Button>
              <Button color="light" size="sm" className="my-1" onClick={cancelEdit}>
                Cancel
              </Button>
            </div>
          </div>
        )}

        {/*{!note && !edit && (*/}
        {/*  <Button size="sm" className="mt-0 fs-sm" onClick={toggleEdit}>*/}
        {/*    Add Note*/}
        {/*  </Button>*/}
        {/*)}       */}

        {!note && !edit && (
          <div
            onDoubleClick={toggleEdit}
            className="opacity-2 text-center text-light text-opacity-50 fst-italic yellow-notes custom-link-hover w-100 border rounded line-height-1 py-1 px-2 m-auto fs-sm"
          >
            {"-"}
          </div>
        )}

        {!edit && !!note && (
          <>
            {noteContent && (
              <div
                onDoubleClick={toggleEdit}
                className="custom-link-hover w-100 yellow-notes border rounded line-height-1 py-1 px-2 m-auto fs-sm"
              >
                {noteContent}
              </div>
            )}
          </>
        )}
        <div>
          {showHistory && (
            <>
              {noteHistory.length > 0 ? (
                <div className="card p-2 list-group max-height-25 overflow-auto em-drop me-2">
                  {noteHistory
                    .slice(0)
                    .reverse()
                    .map((noteObj, i) => (
                      <div key={i + "history"} className="list-group-item px-1">
                        <Badge>{formatDate(noteObj.dateEdited)}</Badge>
                        <Badge color="warning">{noteObj.userWhoEdited} </Badge>{" "}
                        <span className=" fs-sm">
                          {noteObj.note && noteObj.note.length > 0 ? (
                            noteObj.note
                          ) : (
                            <span className="opacity-2">deleted</span>
                          )}
                        </span>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="text-center text-muted opacity-3">no history exists yet</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientNote;
