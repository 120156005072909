import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Form, FormGroup, Button } from "reactstrap";

class PharmacyAdd extends Component {
  state = {
    name: "",
    address: ""
  };

  clearCourse = () => {
    document.getElementById("course").reset();
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const newPharmacy = {
      name: this.state.name,
      address: this.state.address
    };

    // Add note via addPharmacy action
    this.props.addPharmacy(newPharmacy);

    // Clear entered content
    this.clearCourse();
  };

  render() {
    return (
      <div>
        <Form onSubmit={this.onSubmit} id="course">
          <FormGroup>
            <Input
              type="text"
              name="name"
              id="course"
              placeholder="Pharmacy name"
              onChange={this.onChange}
              autoComplete="off"
              required
            />
            <Input
              type="text"
              name="address"
              id="course"
              placeholder="Pharmacy address"
              onChange={this.onChange}
              autoComplete="off"
              required
            />
            <Button>Add Pharmacy</Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps
)(PharmacyAdd);
