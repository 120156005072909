// import React from "react";
import moment from "moment";

class CalculateDate {
  static months = (amount) => {
    return moment().add(amount, "months").format("YYYY-MM-DD");
  };

  static days = (amount) => {
    return moment(moment(), "YYYY-MM-DDTHH:mm:ss").add(amount, "days").format("YYYY-MM-DD");
  };

  static today = () => {
    return moment(moment(), "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
  };
}

export default CalculateDate;

//format properly
export const formatDate = (date) => {
  return moment(date, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY");
};

export const formatDateShort = (date) => {
  return moment(date, "YYYY-MM-DDTHH:mm:ss").format("M/DD");
};

export const formatDateShortYY = (date) => {
  return moment(date, "YYYY-MM-DDTHH:mm:ss").format("M/D/YY");
};

//format properly for calendar form input entries
export const formatDateCal = (date) => {
  return moment(date, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
};

// check if its today
export const isItToday = (date) => {
  return moment(date).isSame(moment().endOf("day"));
};

// checks if its the same or before today (yesterday and prior - useful for overdue check)
export const isItSameOrBeforeToday = (date) => {
  return moment(date).isSameOrBefore(moment().subtract(2, "days").endOf("day"));
};

// checks if its the same or after today (yesterday and prior - useful for overdue check)
export const isItSameOrBefore = (date, amount, unit) => {
  if (unit && amount) return moment(date).isSameOrBefore(moment().add(amount, unit).endOf("day"));
  return null;
};

// checks if its the same or before this year
export const formatDateWithYearCheck = (date) => {
  const currentYear = moment().year();
  const dateMoment = moment(date, "YYYY-MM-DDTHH:mm:ss");
  const dateYear = dateMoment.year();

  return dateYear < currentYear ? dateMoment.format("MM/DD/YY") : dateMoment.format("MM/DD");
};

// put an ARRAY OF OBJECTS with dates in order
export const orderDates = (arr, dateName, sortOrder) => {
  const name = dateName;
  //ascending
  if (sortOrder < 0 && name) {
    return arr.sort((a, b) => {
      let aDate = Date.parse(moment(a[name], "YYYY-MM-DDTHH:mm:ss").startOf("day").format("YYYY-MM-DDTHH:mm:ss")),
        bDate = Date.parse(moment(b[name], "YYYY-MM-DDTHH:mm:ss").startOf("day").format("YYYY-MM-DDTHH:mm:ss")),
        rv = aDate - bDate;
      return rv;
    });
  } else if (sortOrder > 0 && name) {
    return arr.sort((a, b) => {
      let aDate = Date.parse(moment(a[name], "YYYY-MM-DDTHH:mm:ss").startOf("day").format("YYYY-MM-DDTHH:mm:ss")),
        bDate = Date.parse(moment(b[name], "YYYY-MM-DDTHH:mm:ss").startOf("day").format("YYYY-MM-DDTHH:mm:ss")),
        rvTwo = bDate - aDate;
      return rvTwo;
    });
  }
};
