import React, { useEffect, useState } from "react";
import { updateCategoryChildNote, deleteCategoryNote, deleteArchivedNote } from "../../actions/noteActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Button, Card, Col, Form, Input, Row, Tooltip } from "reactstrap";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DOMPurify from "dompurify";

const NoteChildItem = (props) => {
  const { specificNote, archivedNote, category } = props;
  const dispatch = useDispatch();
  const [toggleEdit, setToggleEdit] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const defaultVal = {
    text: specificNote.text ? specificNote.text : "",
    urgent: specificNote.urgent ? specificNote.urgent : false,
    isChecked: specificNote.isChecked ? specificNote.isChecked : false,
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful },
    reset,
    control,
  } = useForm({
    defaultValues: defaultVal,
  });

  const watchUrgent = watch("urgent");
  const watchIsChecked = watch("isChecked");

  const _auth = useSelector((state) => state.auth);
  const _note = useSelector((state) => state.note);

  useEffect(() => {
    reset(defaultVal);
  }, [_note, isSubmitSuccessful]);

  const onEditNote = async (data, noteId) => {
    if (
      isDirty &&
      (specificNote.text !== data.text ||
        specificNote.urgent !== data.urgent ||
        specificNote.isChecked !== data.isChecked)
    ) {
      try {
        await dispatch(
          updateCategoryChildNote(noteId, {
            text: data.text.trim(),
            urgent: data.urgent,
            isChecked: data.isChecked,
          }),
        );
        setToggleEdit(!toggleEdit);
      } catch (err) {
        console.log("Error while editing note: ", err);
      }
    } else {
      setToggleEdit(!toggleEdit);
    }
  };

  const handleKeyDown = (e, id, text, urgent, isChecked) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onEditNote({ text: text, urgent: urgent, isChecked: isChecked }, id);
    }
  };

  const onCancel = () => {
    reset();
    setToggleEdit(!toggleEdit);
  };

  const deleteNote = (noteId, note) => {
    dispatch(deleteCategoryNote(noteId, note));
  };

  const deleteArchNote = (archivedNoteId) => {
    dispatch(deleteArchivedNote(archivedNoteId));
  };

  const boldAtWords = (text) => {
    const boldedText = text.replace(/@(\w+)/g, '<span class="rounded bg-dark text-white px-1">$1</span>');
    const hashedText = boldedText.replace(/#(\w+)/g, '<strong class="">$1</strong>');
    const formattedText = hashedText.replace(
      /\*(.+?)\*/g,
      '<span class="bg-dark bg-opacity-25 text-white px-1 rounded">$1</span>',
    );

    return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formattedText) }} />;
  };

  const checkIfTask = (category) => {
    return category.toLowerCase() === "tasks" ? true : false;
  };

  const getNoteRowClasses = (specificNote) => {
    if (specificNote.isChecked) {
      return `order-2 opacity-2 ${specificNote.urgent ? "bg-warning-br" : "bg-secondary-subtle"}`;
    }
    return specificNote.urgent ? "order-0 bg-warning-br bg-opacity-25 rounded" : "order-1";
  };

  return (
    <>
      {/*<Row*/}
      {/*  className={`g-0 flex-row box-shadow-05 border-lighter align-items-center */}
      {/*  ${specificNote.urgent ? "bg-warning-br bg-opacity-25 rounded order-0" : "order-1"} */}
      {/*  ${specificNote.isChecked ? "opacity-2 bg-secondary-subtle" : ""}`}*/}
      {/*>*/}
      {/*    <Row*/}
      {/*      className={`g-0 flex-row box-shadow-05 border-lighter align-items-center */}
      {/*${specificNote.isChecked ? "order-2 opacity-2" : specificNote.urgent ? "order-0 bg-warning-br bg-opacity-25 rounded" : "order-1"} */}
      {/*${specificNote.isChecked && specificNote.urgent ? "bg-warning-br" : specificNote.isChecked ? "bg-secondary-subtle" : ""}`}*/}
      {/*    >*/}
      <Row
        className={`g-0 flex-row box-shadow-05 border-lighter align-items-center ${getNoteRowClasses(specificNote)}`}
      >
        {!toggleEdit ? (
          <>
            <Col
              xs={8}
              sm={9}
              lg={8}
              xl={9}
              onDoubleClick={() => !archivedNote && setToggleEdit(!toggleEdit)}
              className={`px-1 py-1 max-height-8 fs-sm ${checkIfTask(category) ? "d-flex align-items-start" : "overflow-y-auto"}`}
              style={
                checkIfTask(category)
                  ? { display: "flex", alignItems: "center", overflowY: "auto" }
                  : { display: "block" }
              }
            >
              {checkIfTask(category) && (
                <input
                  type="checkbox"
                  checked={specificNote.isChecked}
                  onChange={async (e) => {
                    const newData = { ...specificNote, isChecked: e.target.checked };
                    await dispatch(updateCategoryChildNote(specificNote._id, newData));
                  }}
                  className="me-2 text-bg-danger"
                />
              )}
              <div style={{ flexGrow: 1 }}>
                {specificNote.text ? (
                  <>{boldAtWords(specificNote.text)}</>
                ) : (
                  <span className="fst-italic opacity-1 fs-sm">---- blank ----</span>
                )}
              </div>
            </Col>

            <Col xs={4} sm={3} lg={4} xl={3} className="text-end">
              {!archivedNote ? (
                <>
                  <Button
                    onClick={() => deleteNote(specificNote._id, specificNote)}
                    className="mx-0 btn-icon bg-danger"
                  >
                    <i className="material-icons m-icon-custom fs-sm">delete</i>
                  </Button>
                  <Button
                    color="success"
                    className="btn-icon bg-primary me-0 "
                    onClick={() => setToggleEdit(!toggleEdit)}
                  >
                    <i className="material-icons m-icon-custom fs-sm">create</i>
                  </Button>
                  <CopyToClipboard text={specificNote.text} id={"tool" + specificNote._id}>
                    <Button color="success" className="btn-icon bg-lime me-0 ">
                      <i className="material-icons m-icon-custom fs-sm">content_copy</i>
                    </Button>
                  </CopyToClipboard>
                  <Tooltip isOpen={tooltipOpen} autohide={true} target={"tool" + specificNote._id} toggle={toggle}>
                    <div>{specificNote.user}</div>
                    <div>Updated: {moment(specificNote.updatedAt).fromNow()}</div>
                    Created: {moment(specificNote.createdAt).fromNow()}
                  </Tooltip>
                </>
              ) : (
                <>
                  <Button onClick={() => deleteArchNote(specificNote._id)} className="btn-icon bg-danger">
                    <i className="material-icons m-icon-custom fs-sm mx-0">delete</i>
                  </Button>
                  <CopyToClipboard text={specificNote.text} id={"tool" + specificNote._id}>
                    <Button color="success" className="btn-icon bg-lime me-0">
                      <i className="material-icons m-icon-custom fs-sm">content_copy</i>
                    </Button>
                  </CopyToClipboard>
                  <Tooltip isOpen={tooltipOpen} autohide={true} target={"tool" + specificNote._id} toggle={toggle}>
                    <div>{specificNote.user}</div>
                    <div>Updated: {moment(specificNote.updatedAt).fromNow()}</div>
                    Created: {moment(specificNote.createdAt).fromNow()}
                  </Tooltip>
                </>
              )}
            </Col>
          </>
        ) : (
          <>
            <Form autoComplete="off" onSubmit={handleSubmit((data) => onEditNote(data, specificNote._id))}>
              <Row className="g-0">
                <Col sm={10}>
                  <Controller
                    control={control}
                    name="text"
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Input
                        onKeyDown={(e, data) => handleKeyDown(e, specificNote._id, value, watchUrgent, watchIsChecked)}
                        onChange={onChange}
                        value={value}
                        type="textarea"
                        className="py-1 px-1 line-height-1 vh-7"
                      />
                    )}
                  />
                </Col>
                <Col className="mt-2 text-center" sm={2}>
                  <Row className="text-center">
                    <Col sm={12}>
                      <Controller
                        control={control}
                        name="urgent"
                        render={({ field: { value, onChange, onBlur } }) => (
                          <input onChange={onChange} checked={value} type="checkbox" className="p-1 my-1" />
                        )}
                      />
                    </Col>
                  </Row>
                  <Button
                    disabled={!isDirty}
                    onClick={handleSubmit(async (data) => await onEditNote(data, specificNote._id))}
                    className="btn-xs btn-dark fs-xs px-1"
                  >
                    SAVE
                  </Button>

                  <Button outline onClick={onCancel} className="btn-xs border fs-xs px-1">
                    CLOSE
                  </Button>
                  {errors.text && (
                    <div className="text-danger fw-bold fs-sm opacity-3 text-center position-absolute bottom-1 mb-2">
                      note is blank
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Row>
    </>
  );
};

export default connect(null, null)(NoteChildItem);
