import React, { useState, useEffect } from "react";
import { Badge, Col, ListGroupItem, Row, Table } from "reactstrap";
import moment from "moment";
import classNames from "classnames";
import { formatDateShort, orderDates, isItSameOrBeforeToday } from "../../Utilities/CalculateDate";
import { Link } from "react-router-dom";
import RefillEditModal from "./RefillEditModal";

const RefillItem = (props) => {
  const [refill, setRefill] = useState(props.refillProp);
  const [patient, setPatient] = useState(props.patient);

  //irrelevant for re renders
  const [className, setClassName] = useState(props.className ? props.className : null);
  const [nameClass, setNameClass] = useState(props.nameClass ? props.nameClass : null);
  const [showDate, setShowDate] = useState(props.showDate ? props.showDate : null);
  const [showEditModal, setShowEditModal] = useState(props.showEditModal ? props.showEditModal : null);
  const [showName, setShowName] = useState(props.showName ? props.showName : null);
  const [whichComp, setWhichComp] = useState(props.whichComponent ? props.whichComponent : null);
  const [colSizes, setColSizes] = useState(props.colSizes ? props.colSizes : [6, 7, 9]);
  const [notesStyle, setNotesStyle] = useState(props.notesStyle ? props.notesStyle : null);
  const [showLastBilled, setShowLastBilled] = useState(props.showLastBilled ? props.showLastBilled : null);
  // const [sent, setSent] = useState();

  useEffect(() => {
    setRefill(props.refillProp);
    setPatient(props.patient);
  }, [props]);

  const sentOrRequested = (sent, rec) => {
    if (sent && rec) {
      return "rgb(184,255,170)";
    }
    if (sent) {
      // return "#ffff5f";
      return "rgba(255, 233, 183, 0.62)";
    }
    if (rec) {
      return "#e0e0e0";
    }
    return "#f8f8f8";
  };

  return (
    <div
      // className={refill.temporary ? "py-0 px-1 opacity-2" : "py-0 px-1"}
      className={`py-0 px-1 ${refill.temporary && "opacity-2"} ${className && className} `}
      // key={props.index}
      style={{ backgroundColor: sentOrRequested(refill.requestSent, refill.responseReceived) }}
    >
      <Row className="py-1 g-0">
        <Col
          md={showEditModal ? colSizes[0] : 12}
          lg={showEditModal ? colSizes[1] : 12}
          xl={showEditModal ? colSizes[2] : 12}
          className=""
        >
          {showDate && (
            <span className={`fs-sm fw-bold mx-1 ${isItSameOrBeforeToday(refill.refillDate) ? "text-danger" : ""}`}>
              {formatDateShort(refill.refillDate)}
            </span>
          )}
          {showName && (
            <span className={nameClass && nameClass}>
              {patient.patientId ? (
                <Link to={`/patients/${patient.patientId}`} className="custom-link-hover">
                  {patient.lastName && patient.lastName}, {patient.firstName && patient.firstName}
                  {patient.rxCount && (
                    <span className="fs-sm ms-1 text-white opacity-5 border fw-bold bg-gray rounded px-1 align-middle">
                      {patient.rxCount}
                    </span>
                  )}
                </Link>
              ) : (
                <Badge color="danger">patient id missing</Badge>
              )}
            </span>
          )}
          {patient.hasControls && <i className="material-icons me-1 fs-sm text-danger">copyright</i>}
          {patient.isDelivery && <i className="material-icons me-1 fs-sm text-success">local_shipping</i>}
          {refill.typeOfRequest && (
            <>
              {refill.typeOfRequest.includes("Phone") && <i className="material-icons me-1 fs-sm text-danger">phone</i>}
            </>
          )}
          {refill.temporary && <Badge color="warning ps-1">TEMP</Badge>}
          {refill.refillNotes && (
            <div className="fs-xs max-height-5 overflow-y-auto d-flex flex-column" style={notesStyle}>
              {/*{refill.refillNotes.map(({ note, date, userName }, index) => (*/}
              {orderDates(refill.refillNotes, "date", 1).map(({ note, date, userName, important }, index) => (
                <div className={`gray-box-small my-0 ${important ? "important-note order-0" : "order-1"}`} key={index}>
                  <Badge color="light" className="opacity-3 user-select-none text-black">
                    {userName} {moment(date).format("M/DD/YY")}{" "}
                  </Badge>{" "}
                  {/*{important ? <span className="font-weight-bolder border">{note}</span> : <>{note}</>}*/}
                  {note}
                </div>
              ))}
            </div>
          )}
        </Col>

        {showEditModal && (
          <Col md={12 - colSizes[0]} lg={12 - colSizes[1]} xl={12 - colSizes[2]} className="">
            {patient.patientId && patient.firstName && patient.lastName ? (
              <RefillEditModal
                className=""
                editRefill={refill}
                patientId={patient.patientId}
                patientName={patient.lastName + ", " + patient.firstName}
                whichComponent={whichComp}
                partnerInfo={patient.partner ? patient.partner : null}
                famInfo={patient.family ? patient.family : null}
              />
            ) : (
              <Badge color="danger">patient id or name missing</Badge>
            )}
          </Col>
        )}

        {showLastBilled && refill.userWhoBilled && refill.dateAdded && (
          <Col lg={12}>
            <Badge color="dark" className="text-wrap px-2 fw-normal mt-2">
              Billed {moment(refill.dateAdded).fromNow()} on <strong>{moment(refill.dateAdded).format("M/DD")}</strong>{" "}
              by <strong>{refill.userWhoBilled}</strong>
            </Badge>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RefillItem;
