import React from "react";
import PropTypes from "prop-types";

const PatientsTableHeader = ({ sortColumn, sortOrder, handleSort }) => (
  <thead className="text-secondary text-center fs-xs bg-info-subtle position-sticky top-0">
    <tr className="opacity-3">
      <th
        className={` px-5 ${sortColumn === "lastName" ? "bg-info text-white" : ""}`}
        onClick={() => handleSort("lastName")}
        style={{ cursor: "pointer" }}
      >
        <span className="d-flex align-items-center justify-content-center px-2">
          Patient {sortColumn === "lastName" && (sortOrder === "asc" ? "▲" : "▼")}
        </span>
      </th>
      <th className=""></th>
      <th
        className={` text-info text-opacity-75 ${sortColumn === "lastBilledRefill" ? "bg-info text-white" : ""}`}
        onClick={() => handleSort("lastBilledRefill")}
        style={{ cursor: "pointer" }}
      >
        Date {sortColumn === "lastBilledRefill" && (sortOrder === "asc" ? "▲" : "▼")}
      </th>
      <th
        className={` px-5 ${sortColumn === "refills" ? "bg-info text-white" : ""}`}
        onClick={() => handleSort("refills")}
        style={{ cursor: "pointer" }}
      >
        <span className="d-flex align-items-center justify-content-center px-5">
          Refills {sortColumn === "refills" && (sortOrder === "asc" ? "▲" : "▼")}
        </span>
      </th>
      <th
        className={` ${sortColumn === "rxCount" ? "bg-info text-white" : ""}`}
        onClick={() => handleSort("rxCount")}
        style={{ cursor: "pointer" }}
      >
        <span className="d-flex align-items-center justify-content-center">
          Scripts {sortColumn === "rxCount" && (sortOrder === "asc" ? "▲" : "▼")}
        </span>
      </th>
      <th
        className={` px-5 text-primary text-opacity-50 ${sortColumn === "note" ? "bg-info text-white" : ""}`}
        onClick={() => handleSort("note")}
        style={{ cursor: "pointer" }}
      >
        <span className="d-flex align-items-center justify-content-center">
          Note {sortColumn === "note" && (sortOrder === "asc" ? "▲" : "▼")}
        </span>
      </th>
      <th
        className={` px-5 text-primary text-opacity-50 ${sortColumn === "secondNote" ? "bg-info text-white" : ""}`}
        onClick={() => handleSort("secondNote")}
        style={{ cursor: "pointer" }}
      >
        <span className="d-flex align-items-center justify-content-center">
          Note {sortColumn === "secondNote" && (sortOrder === "asc" ? "▲" : "▼")}
        </span>
      </th>
      <th
        className={` px-5 text-primary text-opacity-50 ${sortColumn === "thirdNote" ? "bg-info text-white" : ""}`}
        onClick={() => handleSort("thirdNote")}
        style={{ cursor: "pointer" }}
      >
        <span className="d-flex align-items-center justify-content-center">
          Note {sortColumn === "thirdNote" && (sortOrder === "asc" ? "▲" : "▼")}
        </span>
      </th>
      <th className="">
        <span className="d-flex align-items-center justify-content-center">
          <i className="material-icons fs-sm text-danger">copyright</i>
        </span>
      </th>
      <th className="">
        <span className="d-flex align-items-center justify-content-center">
          <i className="material-icons fs-sm text-success">local_shipping</i>
        </span>
      </th>
      <th className="">
        <span className="d-flex align-items-center justify-content-center">
          <i className="material-icons fs-sm">group</i>
        </span>
      </th>
      <th
        className={` px-4 px-lg-0 ${sortColumn === "dateOfBirth" ? "bg-info text-white" : ""}`}
        onClick={() => handleSort("dateOfBirth")}
        style={{ cursor: "pointer" }}
      >
        <span className="d-flex align-items-center justify-content-center px-4">
          DOB {sortColumn === "dateOfBirth" && (sortOrder === "asc" ? "▲" : "▼")}
          <i className="bi bi-arrow-down-up ms-2"></i>
        </span>
      </th>
      <th className="">1st</th>
      <th className=" px-5">Phone</th>
      <th className="">Group#</th>
      <th className="">Sex</th>
      <th className="">Mirixa</th>
    </tr>
  </thead>
);

PatientsTableHeader.propTypes = {
  sortColumn: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired,
};

export default PatientsTableHeader;
