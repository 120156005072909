import axios from "axios";
import {
  GET_ICD_CODES,
  ADD_ICD_CODE,
  DELETE_ICD_CODE,
  UPDATE_ICD_CODE,
  ICD_CODES_LOADING,
  SEARCH_ICD_CODES
} from "./types";

export const searchIcdCodes = query => dispatch => {
  axios.get("/api/icd-codes/search?q=" + query).then(res =>
    dispatch({
      type: SEARCH_ICD_CODES,
      payload: res.data
    })
  );
};

export const getIcdCodes = () => dispatch => {
  dispatch(setIcdCodesLoading());
  axios.get("/api/icd-codes").then(res =>
    dispatch({
      type: GET_ICD_CODES,
      payload: res.data
    })
  );
};

export const addIcdCode = icdCode => dispatch => {
  axios.post("/api/icd-codes", icdCode).then(res =>
    dispatch({
      type: ADD_ICD_CODE,
      payload: res.data
    })
  );
};
//
export const deleteIcdCode = id => dispatch => {
  axios.delete(`/api/icd-codes/${id}`).then(res =>
    dispatch({
      type: DELETE_ICD_CODE,
      payload: id
    })
  ).catch(error => console.log(error.response)) ;
};

export const updateIcdCode = (id, icdCode) => dispatch => {
  axios.put(`/api/icd-codes/${id}`, icdCode).then(res =>
    dispatch({
      type: UPDATE_ICD_CODE,
      payload: res.data
    })
  );
};

export const setIcdCodesLoading = () => {
  return {
    type: ICD_CODES_LOADING
  };
};
