import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonGroup,
  Col,
  Row,
  Badge,
  Popover,
  PopoverHeader,
  PopoverBody,
  InputGroup,
  Alert,
  UncontrolledTooltip,
  InputGroupText,
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deletePatientRefill, updatePatientRefill, addPatientRefill } from "../../../actions/patientActions";
import moment from "moment";
import RefillBilledButton from "./RefillBilledButton";
import { fetchUser } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PartnerRefillsBox from "../Family/PartnerRefillsBox";
import { orderDates } from "../../Utilities/CalculateDate";

class RefillEditModal extends Component {
  state = {
    modal: false,
    refillDate: moment(this.props.editRefill.refillDate, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
    requestTypeSelected: this.props.editRefill.typeOfRequest,
    requestSent: this.props.editRefill.requestSent,
    refillNotes: this.props.editRefill.refillNotes,
    responseReceived: this.props.editRefill.responseReceived,
    temporary: this.props.editRefill.temporary,
    popoverOpen: false,
    alertVisible: false,
    userName: "",
    copiedTooltip: false,
  };

  componentDidMount() {
    this.checkAuthProps();
  }

  // ADDED THIS TO FIX ISSUE WITH RefillChangeSelected EditRefill date not updating
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.editRefill !== prevProps.editRefill) {
      this.setState({
        refillDate: moment(this.props.editRefill.refillDate, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
        requestTypeSelected: this.props.editRefill.typeOfRequest,
        requestSent: this.props.editRefill.requestSent,
        refillNotes: this.props.editRefill.refillNotes,
        responseReceived: this.props.editRefill.responseReceived,
        temporary: this.props.editRefill.temporary,
        popoverOpen: false,
      });
    }

    if (this.props.editRefill.refillDate !== prevProps.editRefill.refillDate) {
      this.setState({
        refillDate: moment(this.props.editRefill.refillDate, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
      });
    }
    // if (this.props.editRefill.responseReceived !== prevProps.editRefill.responseReceived) {
    //   this.setState({
    //     responseReceived: this.props.editRefill.responseReceived,
    //   });
    // }
    // if (this.props.editRefill.requestSent !== prevProps.editRefill.requestSent) {
    //   this.setState({
    //     requestSent: this.props.editRefill.requestSent,
    //   });
    // }
  }

  checkAuthProps() {
    switch (this.props.auth) {
      case null:
        return;
      case false:
        this.setState({ userName: "NOPE" });
        return;
      case undefined:
        this.setState({ userName: "undefined (auth props error)" });
        return;
      default:
        this.setState({ userName: this.props.auth.name });
        return;
    }
  }

  handleDismissAlert = () => {
    this.setState({ alertVisible: false });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  toggleCopiedTooltip = () => {
    this.setState({ copiedTooltip: !this.state.copiedTooltip }, () => {
      setTimeout(() => {
        this.setState({ copiedTooltip: false });
      }, 700);
    });
  };

  handleChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;

    this.setState({ [name]: value });
  };

  handleDeleteRefill = (patientId, refillId) => {
    this.togglePopover();
    this.toggle();
    this.props.deletePatientRefill(patientId, refillId);
  };

  handleEditSaveClick = (patientId) => (e) => {
    e.preventDefault();
    const refId = this.props.editRefill._id;
    const oneMonthAgo = moment().subtract(1, "months").format("YYYY-MM-DD");
    const elevenMonths = moment().add(11, "months").format("YYYY-MM-DD");

    const before = moment(this.state.refillDate).isBefore(elevenMonths);
    const after = moment(this.state.refillDate).isAfter(oneMonthAgo);

    if (after && before) {
      const trimmedRefillNotes = this.state.refillNotes.map((note) => {
        const trimmedNote = note.note.trim();
        return { ...note, note: trimmedNote };
      });

      const removedEmptyNotes = trimmedRefillNotes.filter((note) => note.note !== "");
      const orderedNotes = orderDates(removedEmptyNotes, "date", 1);

      const refillJson = {
        refillDate: this.state.refillDate,
        // refillNote: this.state.refillNote ? this.state.refillNote.trim() : "blank my friend",
        typeOfRequest: this.state.requestTypeSelected,
        requestSent: this.state.temporary ? true : this.state.requestSent,
        responseReceived: this.state.temporary ? true : this.state.responseReceived,
        temporary: this.state.temporary,
        refillNotes: orderedNotes,
      };

      this.props.updatePatientRefill(patientId, refId, refillJson);
      this.toggle();
      this.setState({
        // refillNote: this.state.refillNote ? this.state.refillNote.trim() : "blank my friend",
        refillNotes: orderedNotes,
      });

      this.handleDismissAlert();
    } else {
      this.setState({ alertVisible: true });
    }
  };

  handlePhoneBtnClick(selected) {
    const index = this.state.requestTypeSelected.indexOf(selected);
    if (index < 0) {
      this.state.requestTypeSelected.push(selected);
    } else {
      this.state.requestTypeSelected.splice(index, 1);
    }
    this.setState({ requestTypeSelected: [...this.state.requestTypeSelected] });
  }

  handleRequestSent = () => {
    this.setState({ requestSent: !this.state.requestSent });
  };

  handleResponseReceived = () => {
    this.setState({ responseReceived: !this.state.responseReceived });
  };

  handleTemporary = () => {
    this.setState({ temporary: !this.state.temporary });
  };

  calculateTwentyFive(refDate) {
    const twentyFiveDays = moment(refDate, "YYYY-MM-DDTHH:mm:ss").add(25, "days");
    let x = 0;
    let sunday = "";

    if (twentyFiveDays.format("dddd") === "Sunday") {
      x = -2;
      sunday = "  (Sunday)";
    } else if (twentyFiveDays.format("dddd") === "Saturday") {
      x = -1;
      sunday = "  (Saturday)";
    }

    const correctRefillDate = moment(refDate, "YYYY-MM-DDTHH:mm:ss")
      .add(25 + x, "days")
      .format("MM-DD-YYYY");

    return correctRefillDate + sunday;
  }

  handleRefillNotesChange = (index) => (e) => {
    const newRefillNotes = this.state.refillNotes.map((note, sindex) => {
      // console.log("Note: " + JSON.stringify(note));
      // const name = e.target.name;
      // let sliced = name.slice(0, -1);
      // if (e.target.name.startsWith("imp")) {
      //   sliced > 8 && console.log("yess");
      //   sliced = name.slice(0, -1);
      // }

      // if (index !== sindex) return note;
      if (index === sindex) {
        const value = e.currentTarget.type === "checkbox" ? e.target.checked : e.target.value;
        // console.log("ok: " + value);
        // console.log("Note: " + JSON.stringify(note));
        const name = e.target.name;
        let sliced = name.slice(0, -1);
        return { ...note, [sliced]: value };
        // return { ...note, important: value };
      } else {
        return note;
      }
    });

    this.setState({ refillNotes: newRefillNotes });
    // console.log(this.state.refillNotes[index].important);
  };

  handleAddNote = (note) => {
    const dateNow = Date.now();

    this.setState({
      refillNotes: this.state.refillNotes.concat([{ userName: this.state.userName, note: "", date: dateNow }]),
    });
  };

  handleRemoveNote = (index) => () => {
    this.setState({
      refillNotes: this.state.refillNotes.filter((note, sIndex) => index !== sIndex),
    });
  };

  render() {
    // const patientId = this.props.patientId;
    // const patientName = this.props.patientName;

    const { patientName, patientId, whichComponent, partnerInfo, famInfo, showPhone } = this.props;
    const refill = this.props.editRefill;
    const { modal, userName, requestSent, responseReceived, temporary, requestTypeSelected, copiedTooltip } =
      this.state;
    const classes = this.props.className ? this.props.className : "";
    const oneMonthAgo = moment().subtract(1, "months").format("YYYY-MM-DD");
    const elevenMonths = moment().add(11, "months").format("YYYY-MM-DD");

    return (
      <div className={`${classes}`}>
        {refill._id ? (
          <>
            {patientName && (
              <>
                {/*<UncontrolledTooltip placement="top" target={"copy-tooltip" + refill._id} delay={{ show: 1250, hide: 50 }}>*/}
                <UncontrolledTooltip
                  isOpen={copiedTooltip}
                  toggle={this.toggleCopiedTooltip}
                  placement="top"
                  trigger="click"
                  hideArrow={true}
                  // delay={{ hide: 50 }}
                  target={"copy-tooltip" + refill._id}
                  style={{
                    backgroundColor: "rgba(255,255,255,0.8)",
                    color: "black",
                    // fontSize: "large",
                    fontWeight: "bold",
                    border: "1px solid #e8e8e8",
                  }}
                >
                  Copied
                </UncontrolledTooltip>
                <CopyToClipboard text={patientName}>
                  <Button color="success" className="btn-icon bg-lime" id={"copy-tooltip" + refill._id}>
                    <i className="material-icons m-icon-custom">content_copy</i>
                  </Button>
                </CopyToClipboard>
              </>
            )}

            <UncontrolledTooltip
              placement="top"
              trigger="hover"
              target={"edit-tooltip" + refill._id}
              delay={{ show: 2000, hide: 50 }}
            >
              Edit
            </UncontrolledTooltip>
            {/*<Button color="primary" className="custom-btn" onClick={this.toggle}>*/}
            <Button color="primary" className="btn-icon" id={"edit-tooltip" + refill._id} onClick={this.toggle}>
              <i className="material-icons m-icon-custom">create</i>
            </Button>

            {!temporary && (
              <RefillBilledButton
                billRefill={refill}
                patientId={patientId}
                user={userName}
                cameFromComponent={whichComponent}
              />
            )}

            {partnerInfo && (
              <PartnerRefillsBox partner={partnerInfo} refillId={refill._id} componentFrom={whichComponent} />
            )}

            {/*{famInfo && famInfo.length > 0 && (*/}
            {/*  <div className="btn-icon opacity-2">*/}
            {/*    <i className="material-icons m-icon-custom m-0 p-0 rounded-2">familiar_face_and_zone</i>*/}
            {/*  </div>*/}
            {/*)}*/}

            {showPhone && refill.typeOfRequest.includes("Phone") && (
              <span className="ms-2 mt-2 position-absolute opacity-2">
                {/*{refill.typeOfRequest.includes("Phone") && (*/}
                <i style={{ color: "#ff0017" }} className="material-icons">
                  phone_in_talk
                </i>
                {/*)}*/}
                {/*{refill.mirixa && <i className="material-icons">museum</i>}*/}
              </span>
            )}

            <Modal size="md" isOpen={modal} toggle={this.toggle} centered={true}>
              <ModalHeader
                toggle={this.toggle}
                className="py-1"
                style={{ backgroundColor: !requestSent && "rgba(0,0,0,0.1)" }}
              >
                <div className="d-flex align-items-center">
                  <b className="w-100 text-center">
                    {/*{patientName ? patientName.split(" ")[1] + "'s" : "Edit "}*/}
                    {/*<span className="text-muted">Refill</span>*/}
                    {patientName && patientName.split(" ")[1]}
                  </b>
                  {requestSent && <i className="material-icons text-success ms-2 opacity-3">check_circle_outline</i>}
                  {requestTypeSelected.includes("Phone") && (
                    <i className="material-icons text-danger ms-2 opacity-3">phone</i>
                  )}
                  {temporary && (
                    <Badge color="warning" className="ms-2 opacity-3">
                      TEMPORARY
                    </Badge>
                  )}
                  {/*<span className="opacity-2 ml-5 font-size-md">{this.calculateTwentyFive(this.state.refillDate)}</span>*/}
                </div>
              </ModalHeader>
              <ModalBody className="pb-1" style={{ backgroundColor: !requestSent && "rgba(0,0,0,0.06)" }}>
                <Alert color="info" isOpen={this.state.alertVisible} toggle={this.handleDismissAlert}>
                  Please enter a valid refill date.
                </Alert>
                <Form onSubmit={this.handleEditSaveClick(patientId)} autoComplete="off">
                  <FormGroup>
                    <Row>
                      <Col sm={12} className="px-3">
                        <div className="p-3 mx-n2 mt-n2 border-dashed-lighter box-shadow-1 rounded-16">
                          <Row>
                            {/*<InputGroupAddon addonType="prepend">Date:</InputGroupAddon>*/}
                            <Col sm="8">
                              <Input
                                type="date"
                                name="refillDate"
                                id="refill"
                                min={oneMonthAgo}
                                max={elevenMonths}
                                value={this.state.refillDate}
                                onChange={this.handleChange}
                                className="text-center rounded"
                                required
                              />
                            </Col>
                            <Col sm="4">
                              <span className="d-flex text-center mt-1 align-items-center opacity-2">
                                {/*{this.calculateTwentyFive(this.state.refillDate)}*/}
                                {moment(this.state.refillDate, "YYYY-MM-DDTHH:mm:ss")
                                  .add(30, "days")
                                  .format("MM-DD-YYYY")}
                              </span>
                            </Col>
                          </Row>
                          {/*<div className="py-0 mt-1 opacity-1 text-center">*/}
                          {/*  {this.calculateTwentyFive(this.state.refillDate)}*/}
                          {/*</div>*/}
                          {/*<InputGroup>*/}
                          {/*  <InputGroupAddon addonType="prepend">*/}
                          {/*    Note:*/}
                          {/*  </InputGroupAddon>*/}
                          {/*  <Input*/}
                          {/*    type="text"*/}
                          {/*    name="refillNote"*/}
                          {/*    placeholder="Add any refill note here"*/}
                          {/*    value={this.state.refillNote}*/}
                          {/*    onChange={this.handleChange}*/}
                          {/*  />*/}
                          {/*</InputGroup>*/}
                          {this.state.refillNotes && this.state.refillNotes.length > 0 && (
                            <div className="yellow-notes my-2 rounded-8 d-flex flex-column">
                              {orderDates(this.state.refillNotes, "date", 1).map((note, index) => (
                                <InputGroup
                                  size="sm"
                                  key={index}
                                  className={` ${
                                    this.state.refillNotes[index].important ? "important-note rounded-6" : ""
                                  }`}
                                >
                                  {/*<InputGroupAddon addonType="prepend">*/}
                                  {/*{console.log("owow: " + this.state.refillNotes[index].important)}*/}
                                  <InputGroupText>
                                    <Input
                                      addon
                                      type="checkbox"
                                      name={"important" + index}
                                      checked={this.state.refillNotes[index].important}
                                      onChange={this.handleRefillNotesChange(index)}
                                      aria-label="important"
                                      // className="mx-2"
                                    />
                                  </InputGroupText>
                                  {/*</InputGroupAddon>*/}
                                  {/*<InputGroupAddon addonType="prepend">*/}
                                  <span
                                    className={`px-1 pt-1 opacity-2 fs-xs user-select-none bg-transparent ${
                                      note.important && "text-danger"
                                    }`}
                                  >
                                    {note.userName && note.userName.slice(0, 2)}
                                    <br /> {moment(note.date).format("M/DD/YY")}
                                  </span>
                                  {/*{note.important && (*/}
                                  {/*  <span className="pl-0 pt-1 px-5 position-absolute top-0 opacity-3 font-size-xs user-select-none">*/}
                                  {/*    ****/}
                                  {/*  </span>*/}
                                  {/*)}*/}
                                  {/*</InputGroupAddon>*/}
                                  <Input
                                    // key={index}
                                    type="textarea"
                                    name={"note" + index}
                                    value={note.note}
                                    placeholder={`enter note ${index + 1}`}
                                    onChange={this.handleRefillNotesChange(index)}
                                    className="border-lighter box-shadow-0 note-extend"
                                    // style={{ marginTop: "2px" }}
                                    disabled={note.userName === userName ? false : true}
                                  />

                                  <Button
                                    color="danger"
                                    className="my-2 mx-1 rounded py-0 px-0"
                                    onClick={this.handleRemoveNote(index)}
                                  >
                                    <i className="material-icons m-icon-custom fs-sm">delete</i>
                                  </Button>
                                </InputGroup>
                              ))}
                            </div>
                          )}
                          <Button
                            block
                            color="warning"
                            // style={{ marginBottom: "8px" }}
                            className="btn-block btn-yellow"
                            onClick={this.handleAddNote}
                          >
                            Add Note
                          </Button>
                        </div>
                        <div
                          className="px-3 mt-2 border-dashed-lighter box-shadow-1 rounded-16 d-flex align-items-center justify-content-between"
                          style={{ backgroundColor: responseReceived && "rgba(255,255,0,0.21)" }}
                        >
                          <InputGroup size="md" className="my-2 rounded-6" style={{ width: "unset" }}>
                            {/*<InputGroupAddon addonType="prepend">*/}
                            <InputGroupText>
                              <Input
                                addon
                                type="checkbox"
                                name="requestSent"
                                checked={requestSent}
                                onChange={() => this.handleRequestSent()}
                                aria-label="requestSent"
                              />
                            </InputGroupText>
                            {/*<InputGroupAddon addonType="append">*/}
                            <InputGroupText className="py-1">Request Sent</InputGroupText>
                            {/*</InputGroupAddon>*/}
                            {/*</InputGroupAddon>*/}
                            {/*<InputGroup size="lg" className="my-3" style={{ width: "unset" }}>*/}

                            {/*<InputGroupAddon addonType="prepend">*/}
                            <InputGroupText>
                              <Input
                                addon
                                type="checkbox"
                                name="responseReceived"
                                checked={responseReceived}
                                onChange={() => this.handleResponseReceived()}
                                aria-label="responseReceived"
                              />
                            </InputGroupText>
                            {/*<InputGroupAddon addonType="append">*/}
                            <InputGroupText className="py-1 text-primary">Response Received</InputGroupText>
                            {/*</InputGroupAddon>*/}
                            {/*</InputGroupAddon>*/}
                            {/*</InputGroup>*/}
                          </InputGroup>
                          <div>
                            <Button
                              // size="lg"
                              className={"my-1 btn-block "}
                              style={{ opacity: requestTypeSelected.includes("Phone") ? "100%" : "40%" }}
                              color="info"
                              onClick={() => this.handlePhoneBtnClick("Phone")}
                              active={requestTypeSelected.includes("Phone")}
                            >
                              {/*{requestTypeSelected.includes("Phone") && <span>• </span>}*/}
                              Phone
                              {requestTypeSelected.includes("Phone") && <span> ✓</span>}
                            </Button>

                            <InputGroup
                              className="rounded-6 my-1"
                              style={{ backgroundColor: temporary ? "rgb(255,148,11)" : "#787878" }}
                            >
                              {/*<InputGroupAddon*/}
                              {/*  addonType="append"*/}
                              {/*>*/}
                              <InputGroupText className="py-1 text-white">Temporary Refill</InputGroupText>
                              <InputGroupText>
                                <Input
                                  addon
                                  type="checkbox"
                                  name="temporary"
                                  checked={temporary}
                                  onChange={() => this.handleTemporary()}
                                  aria-label="temporary"
                                />
                              </InputGroupText>
                              {/*</InputGroupAddon>*/}
                              {/*</InputGroup>*/}
                            </InputGroup>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <ButtonGroup className="d-flex mt-1">
                      <Button color="dark" onClick={this.handleEditSaveClick(patientId)} style={{ flexBasis: "75%" }}>
                        Save Changes
                      </Button>

                      <Button
                        color="danger"
                        id={"pop-del-" + whichComponent + refill._id}
                        style={{ flexBasis: "25%" }}
                        onClick={this.togglePopover}
                      >
                        Delete
                      </Button>

                      <Popover
                        style={{ textAlign: "center" }}
                        placement="bottom"
                        isOpen={this.state.popoverOpen}
                        target={"pop-del-" + whichComponent + refill._id}
                        toggle={this.togglePopover}
                      >
                        <PopoverHeader>
                          <strong>Delete Refill</strong>?
                        </PopoverHeader>
                        <PopoverBody style={{ paddingTop: "5px" }}>
                          <Button
                            style={{ marginTop: "0px" }}
                            onClick={this.togglePopover}
                            className="btn btn-primary custom-btn"
                          >
                            Cancel
                          </Button>
                          <Button
                            style={{ marginTop: "0px" }}
                            onClick={this.handleDeleteRefill.bind(this, patientId, refill._id)}
                            className="btn btn-danger custom-btn"
                          >
                            Confirm Delete
                          </Button>
                        </PopoverBody>
                      </Popover>
                    </ButtonGroup>
                  </FormGroup>
                </Form>
              </ModalBody>
            </Modal>
          </>
        ) : (
          <>no refill._id</>
        )}
      </div>
    );
  }
}

RefillEditModal.propTypes = {
  patient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  deletePatientRefill,
  updatePatientRefill,
  addPatientRefill,
  fetchUser,
})(RefillEditModal);
