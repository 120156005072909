import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonGroup,
  Popover,
  PopoverHeader,
  PopoverBody,
  InputGroup, InputGroupText
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteUser, updateUser } from "../../actions/userActions";
import _ from "lodash";

class UserEditModal extends Component {
  state = {
    modal: false,
    role: this.props.editUser.role || "",
    email: this.props.editUser.email || "",
    username: this.props.editUser.username || "",
    name: this.props.editUser.name,
    lastName: this.props.editUser.lastName || "",
    pharmacy: this.props.editUser.pharmacy ? this.props.editUser.pharmacy.name : "",
    popoverOpen: false,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const user = this.props.editUser;
    if (user !== prevProps.editUser) {
      this.setState({
        pharmacy: user.pharmacy ? user.pharmacy.name : "",
        role: user.role ? user.role : "",
      });
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  handleChangeTrimmed = (e) => {
    this.setState({ [e.target.name]: e.target.value.trim() });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDeleteClick = (id) => {
    this.props.deleteUser(id);
  };

  handleEditSaveClick = (id) => (e) => {
    e.preventDefault();

    const lowerUsername = this.state.username.toLowerCase();
    const formatFirst = _.startCase(this.state.name.toLowerCase());
    const formatLast = _.startCase(this.state.lastName.toLowerCase());
    const formatEmail = this.state.email ? this.state.email.toLowerCase() : "";

    const userJson = {
      username: lowerUsername,
      name: formatFirst,
      lastName: formatLast,
      pharmacy: _.startCase(this.state.pharmacy.toLowerCase()),
      email: formatEmail,
      role: this.state.role,
    };

    this.props.updateUser(id, userJson);
    this.toggle();
    this.setState({
      username: lowerUsername,
      name: formatFirst,
      lastName: formatLast,
      email: formatEmail,
    });
  };

  // componentDidUpdate(prevProps, prevState) {}

  render() {
    const user = this.props.editUser;
    const { modal } = this.state;

    return (
      <div>
        <Button color="primary" onClick={this.toggle}>
          Edit
        </Button>

        <Modal isOpen={modal} toggle={this.toggle} centered={true} style={{ maxWidth: "300px" }}>
          <ModalHeader toggle={this.toggle}>
            Edit <b>{user.name}</b>
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={this.handleEditSaveClick(user._id)} autoComplete="off">
              <FormGroup>
                <InputGroup>
                  <InputGroupText>Pharmacy:</InputGroupText>
                  <Input
                    type="text"
                    name="pharmacy"
                    placeholder="Pharmacy name"
                    id="user"
                    value={this.state.pharmacy}
                    onChange={this.handleChangeTrimmed}
                    style={{ marginLeft: "4px" }}
                    // required
                  />
                </InputGroup>

                <InputGroup>
                  <InputGroupText>First Name:</InputGroupText>
                  <Input
                    type="text"
                    name="name"
                    placeholder="First name"
                    id="user"
                    value={this.state.name}
                    onChange={this.handleChangeTrimmed}
                    // style={{ marginLeft: "10px" }}
                    required
                  />
                </InputGroup>

                <InputGroup>
                  <InputGroupText>Last Name:</InputGroupText>
                  <Input
                    type="text"
                    name="lastName"
                    placeholder="Last name"
                    id="user"
                    value={this.state.lastName}
                    onChange={this.handleChangeTrimmed}
                  />
                </InputGroup>

                <InputGroup>
                  <InputGroupText>Username:</InputGroupText>
                  <Input
                    type="text"
                    name="username"
                    placeholder="Username"
                    id="user"
                    value={this.state.username}
                    onChange={this.handleChangeTrimmed}
                    style={{ marginLeft: "2px" }}
                    // required
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroupText>Email:</InputGroupText>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Email address"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleChangeTrimmed}
                    style={{ marginLeft: "26px" }}
                    // required
                  />
                </InputGroup>

                {/*USER ACCESS ROLES*/}

                <InputGroup style={{ marginTop: "10px" }}>
                  <InputGroupText>Role:</InputGroupText>
                  <select
                    type="select"
                    name="role"
                    id="userAccessRoles"
                    value={this.state.role}
                    style={{ marginLeft: "32px", borderRadius: "6px" }}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="superadmin">Super Admin</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                    <option value="">none</option>
                  </select>
                </InputGroup>

                {/*//---------------------//*/}

                <ButtonGroup className="d-flex" style={{ paddingTop: "10px" }}>
                  <Button
                    color="dark" // onClick={this.handleEditSaveClick.bind(this, user._id)}
                    style={{ flexBasis: "75%" }}
                  >
                    Save Changes
                  </Button>

                  <Button
                    color="danger"
                    id={"popover" + user._id}
                    style={{ flexBasis: "25%" }}
                    onClick={this.togglePopover}
                  >
                    Delete
                  </Button>

                  <Popover
                    style={{ textAlign: "center" }}
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target={"popover" + user._id}
                    toggle={this.togglePopover}
                  >
                    <PopoverHeader>
                      <strong>
                        Are you sure you want to <span style={{ color: "red" }}>PERMANENTLY</span> delete this user?
                        <div style={{ color: "#ab0002" }}>You can not undo this.</div>
                      </strong>
                    </PopoverHeader>
                    <PopoverBody style={{ paddingTop: "5px" }}>
                      <Button
                        style={{ marginTop: "0px" }}
                        onClick={this.togglePopover}
                        className="btn btn-primary custom-btn"
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{ marginTop: "0px" }}
                        onClick={this.handleDeleteClick.bind(this, user._id)}
                        className="btn btn-danger custom-btn"
                      >
                        Yes Delete
                      </Button>
                    </PopoverBody>
                  </Popover>
                </ButtonGroup>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

UserEditModal.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { deleteUser, updateUser })(UserEditModal);
