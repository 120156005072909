import React, { useState } from "react";
import { addNote, clearNoteResponse } from "../../actions/noteActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  InputGroup,
  Row,
} from "reactstrap";
import moment from "moment";
import { GET_ICD_CODES, SEARCH_ICD_CODES } from "../../actions/types";
import NoteCategory from "./Notes";

const AddParentNote = (props) => {
  const [response, setResponse] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const _auth = useSelector((state) => state.auth);
  const _note = useSelector((state) => state.note);

  const preloadedValues = { category: "", subcategory: "" };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    formState,
    control,
  } = useForm({
    defaultValues: preloadedValues,
  });

  const watchAllFields = watch();

  const onSubmit = async (data) => {
    // console.log(data);
    // console.log("in onSubmit: " + JSON.stringify(data));
    // const cleanContent = data.content.replace(/\s{2,}/g, " ").trim();

    const newNote = {
      // content: cleanContent,
      category: data.category,
      subcategory: data.subcategory,
      user: _auth.name,
    };
    // console.log("notes: " + JSON.stringify("new note: " + JSON.stringify(newNote)));
    dispatch(addNote(newNote));
    reset("", {
      keepValues: false,
    });

    setTimeout(() => {
      // Delay this action by 10 seconds
      dispatch(clearNoteResponse());
    }, 5000);
  };

  return (
    <Card className="text-center border rounded-8">
      {showForm ? (
        <div className="border rounded-8">
          {_note.response && (
            <div className="position-absolute fw-bold top-0 left-0 text-success ms-1 opacity-4">{_note.response}</div>
          )}
          <Form className="bg-light rounded-8" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <CardTitle className="pt-1">
              <strong>ADD NOTE CATEGORY</strong>
            </CardTitle>

            <Row className="g-0">
              {/*<Col sm={10}>*/}
              <input className="form-control" placeholder="category" {...register("category", { required: true })} />
              {errors.category && (
                <span className="text-danger fw-bold fs-sm opacity-3 position-absolute mt-4 top-0 right-0">
                  category required
                </span>
              )}

              <input
                className="form-control"
                placeholder="subcategory"
                {...register("subcategory", { required: true })}
              />
              {errors.subcategory && (
                <span className="text-danger fw-bold fs-sm opacity-3 position-absolute mt-5 bottom-50 left-0">
                  subcategory required
                </span>
              )}
              {/*</Col>*/}
              {/*<Col sm={2}>*/}
              <Button
                // type="submit"
                color="dark"
                // disabled={!formState.dirty || formState.isSubmitting}
                disabled={formState.isSubmitting}
                className="line-height-1 btn-sm mb-2 py-1"
                name="Edit"
              >
                Add Category
              </Button>
              {/*</Col>*/}
            </Row>
          </Form>
          <Button
            color="light"
            className="btn-sm rounded-8 border border-dark mt-2"
            onClick={() => setShowForm(!showForm)}
          >
            ▴ Close Form ▴
          </Button>
        </div>
      ) : (
        <Button color="light" className="btn-sm rounded-8 text-black-50" onClick={() => setShowForm(!showForm)}>
          ▾ Show Add Category Form ▾
        </Button>
      )}
    </Card>
  );
};

// export default AddParentNote;
export default connect(null, null)(AddParentNote);
