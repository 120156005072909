import React, { useState } from "react";
import { Button, ButtonGroup, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { useDispatch } from "react-redux";
import { updateFamily, removePartners } from "../../../actions/patientActions";

const UnlinkFamTooltip = ({ mainId, famId, index, foundOrPrimary, partners }) => {
  const dispatch = useDispatch();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const handleUpdateFamily = (mainId, famId) => {
    // dispatch(updateFamily(foundPatientId, familyId, "", true));
    dispatch(updateFamily(mainId, famId, "", true));
    toggle();
  };

  const handleRemovePartners = (mainId, partnerId) => {
    dispatch(removePartners(mainId, partnerId));
    toggle();
  };

  return (
    <>
      <Button
        size="sm"
        className="badge ms-1 mt-0"
        id={"pop-found-" + mainId + index + foundOrPrimary}
        // onClick={() => dispatch(updateFamily(foundPatientId, fam.member._id, "", true))}
        // onClick={() => setConfirmFoundUnlink([...confirmFoundUnlink, i])}
        // onClick={() => setConfirmFoundUnlink([...confirmFoundUnlink, i])}
      >
        Unlink
      </Button>
      <Popover
        trigger="legacy"
        fade={false}
        placement="bottom"
        isOpen={popoverOpen}
        target={"pop-found-" + mainId + index + foundOrPrimary}
        toggle={toggle}
      >
        <PopoverHeader className="text-center">Are you sure you'd like to unlink them?</PopoverHeader>
        <PopoverBody className="p-1">
          <div className="p-1 fs-sm">
            <ButtonGroup className="d-flex mt-1">
              <Button color="dark" className="mt-0 custom-btn" onClick={toggle}>
                No, cancel
              </Button>
              <Button
                color="danger"
                className="mt-0 custom-btn"
                onClick={() =>
                  !partners ? handleUpdateFamily(mainId, famId, "", true) : handleRemovePartners(mainId, famId)
                }
              >
                Yes, Unlink
              </Button>
            </ButtonGroup>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default UnlinkFamTooltip;
