import React from "react";
// import { withRouter } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";

const GoBack = () => {
  const navigate = useNavigate();
  return (
    <Button className="custom-nav-btn mx-2" onClick={() => navigate(-1)} alt="Go back">
      Back
    </Button>
  );
};

export default GoBack;

// export const goBack = ComponentWithRouter => props => {
//   const location = useLocation();
//   const match = { params: useParams() };
//   const navigate = useNavigate();
//   const history = {
//     back: () => navigate(-1),
//     goBack: () => navigate(-1),
//     location,
//     push: (url, state) => navigate(url, { state }),
//     replace: (url, state) => navigate(url, { replace: true, state }),
//   };
//   return (
//     <ComponentWithRouter
//       location={location}
//       match={match}
//       navigate={navigate}
//       history={history}
//       {...props}
//     />
//   );
// };
