import React, { Component } from "react";
import { Button, Popover, PopoverHeader, PopoverBody, UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { billPatientRefill } from "../../../actions/patientActions";
import moment from "moment";
import { orderDates } from "../../Utilities/CalculateDate";
// import _ from "lodash";

class RefillBilledButton extends Component {
  state = {
    billedPopoverOpen: false,
    // randomNum: _.random(9000),
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggleBilledPopover = () => {
    this.setState({
      billedPopoverOpen: !this.state.billedPopoverOpen,
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // DELETES OLD REFILLS AND CREATES NEW ONE //
  handleBillClick = (refill) => {
    const { refillDate, typeOfRequest, refillNotes, patientId, _id, userWhoBilled } = refill;
    // const thirtyDays = moment(refillDate, "YYYY-MM-DDTHH:mm:ss").add(30, "days");
    let x = 0;
    // const checkSunday = thirtyDays.format("dddd") === "Sunday";
    // const checkSaturday = thirtyDays.format("dddd") === "Saturday";

    // If even month, take back to Friday, if odd month, take forward to Monday
    // if (moment(refillDate, "YYYY-MM-DDTHH:mm:ss").format("M") % 2 === 0) {
    //   if (checkSunday) {
    //     // x = -2;
    //     x = -2;
    //   } else if (checkSaturday) {
    //     // x = -1;
    //     x = -1;
    //   }
    // } else if (checkSunday) {
    //   // x = 1;
    //   x = -2;
    // } else if (checkSaturday) {
    //   // x = 2;
    //   x = -1;
    // }

    // const correctRefillDate = moment(refillDate, "YYYY-MM-DDTHH:mm:ss").add(28 + x, "days");
    const correctRefillDate = moment(refillDate, "YYYY-MM-DDTHH:mm:ss").add(30 + x, "days");
    const orderedNotes = orderDates(refillNotes, "date", 1);

    const checkIfSatSun = (date) => {
      if (moment(date).format("dddd") === "Saturday") {
        // console.log("is sat: " + moment(date).format("dddd"));
        return moment(refillDate, "YYYY-MM-DDTHH:mm:ss")
          .add(29 + x, "days")
          .format("YYYY-MM-DD");
      } else if (moment(date).format("dddd") === "Sunday") {
        // console.log("is sun: " + moment(date).format("dddd"));
        return moment(refillDate, "YYYY-MM-DDTHH:mm:ss")
          .add(31 + x, "days")
          .format("YYYY-MM-DD");
      } else {
        // console.log("is NOT: " + moment(date).format("dddd"));
        return moment(refillDate, "YYYY-MM-DDTHH:mm:ss")
          .add(30 + x, "days")
          .format("YYYY-MM-DD");
      }
    };

    const futureRefill = {
      // refillDate: correctRefillDate,
      refillDate: checkIfSatSun(correctRefillDate),
      typeOfRequest: typeOfRequest,
      // refillNotes: refillNotes,
      refillNotes: orderedNotes,
      // refillNote: "",
      userWhoBilled: userWhoBilled,
      billed: true,
      ogRefillDate: refillDate,
    };

    // console.log("FUTURE: " + futureRefill.refillDate);

    this.props.billPatientRefill(patientId, _id, futureRefill);
    this.toggleBilledPopover();
  };

  render() {
    const { patientId, cameFromComponent } = this.props;
    const { refillDate, refillNotes, typeOfRequest, _id } = this.props.billRefill;
    const userName = this.props.user;
    const { billedPopoverOpen } = this.state;

    return (
      <span className="">
        {/*<Button*/}
        {/*  color="warning"*/}
        {/*  // id={"pop" + patientId + _id + cameFromComponent}*/}
        {/*  id={"pop" + _id + cameFromComponent}*/}
        {/*  onClick={this.toggleBilledPopover}*/}
        {/*  className="custom-btn"*/}
        {/*>        */}

        <UncontrolledTooltip
          placement="top"
          trigger="hover"
          target={"pb" + cameFromComponent + _id}
          delay={{ show: 1250, hide: 50 }}
        >
          Billed
        </UncontrolledTooltip>

        <Button
          // outline="true"
          color="warning"
          id={"pb" + cameFromComponent + _id}
          onClick={this.toggleBilledPopover}
          className="btn-icon"
        >
          <i className="material-icons m-icon-custom">done_outline</i>
        </Button>

        <Popover
          style={{ textAlign: "center" }}
          // temporarily do this check until we figure out the z-index solution
          placement={cameFromComponent !== "PartnerTooltip" ? "bottom" : "right"}
          // placement="right"
          trigger="legacy"
          isOpen={billedPopoverOpen}
          // target={"pop" + patientId + _id + cameFromComponent}
          target={"pb" + cameFromComponent + _id}
          toggle={this.toggleBilledPopover}
        >
          <PopoverHeader>
            <strong>Did you bill this refill?</strong>
          </PopoverHeader>
          <PopoverBody style={{ paddingTop: "5px" }}>
            <Button
              style={{ marginTop: "0px" }}
              onClick={this.toggleBilledPopover}
              className="btn btn-primary custom-btn"
            >
              No, Cancel
            </Button>
            <Button
              style={{ marginTop: "0px" }}
              onClick={this.handleBillClick.bind(this, {
                refillDate: refillDate,
                refillNotes: refillNotes,
                typeOfRequest: typeOfRequest,
                _id: _id,
                patientId: patientId,
                userWhoBilled: userName,
              })}
              className="custom-btn"
              color="danger"
            >
              Yes, Billed
            </Button>
          </PopoverBody>
        </Popover>
      </span>
    );
  }
}

RefillBilledButton.propTypes = {
  patient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
});

export default connect(mapStateToProps, { billPatientRefill })(RefillBilledButton);
