import React, { useState, useEffect } from "react";
import { format, addDays, subWeeks, startOfWeek } from "date-fns";
import { Card, Container, Table } from "reactstrap";

const UserTimeClock = () => {
  const [timeData, setTimeData] = useState([]);

  useEffect(() => {
    const today = new Date();
    // Get the start of the current week, considering Monday as the first day
    let currentWeekStart = startOfWeek(today, { weekStartsOn: 1 });
    // Calculate the start date as 4 weeks before the current week start
    const startDate = subWeeks(currentWeekStart, 2);
    const endDate = addDays(today, 14); // 2 weeks ahead from today

    let date = startDate;
    let newData = [];

    while (date <= endDate) {
      newData.push({
        date: date,
        timeIn1: "",
        timeOut1: "",
        timeIn2: "",
        timeOut2: "",
        hoursTotal: 0,
      });
      date = addDays(date, 1);
    }

    setTimeData(newData);
  }, []);

  const handleTimeChange = (index, field, value) => {
    const newData = [...timeData];
    newData[index][field] = value;

    // Validate timeOut not earlier than timeIn
    if (
      (field === "timeOut1" && new Date(`1970-01-01T${value}`) < new Date(`1970-01-01T${newData[index].timeIn1}`)) ||
      (field === "timeOut2" && new Date(`1970-01-01T${value}`) < new Date(`1970-01-01T${newData[index].timeIn2}`))
    ) {
      alert("Time Out cannot be earlier than Time In.");
      return;
    }

    if (field.includes("timeIn") || field.includes("timeOut")) {
      const hoursTotal = calculateTotalHours(newData[index]);
      newData[index].hoursTotal = hoursTotal;
    }

    setTimeData(newData);
  };

  const calculateHours = (timeIn, timeOut) => {
    const timeInDate = new Date(`1970-01-01T${timeIn}`);
    const timeOutDate = new Date(`1970-01-01T${timeOut}`);
    const diff = (timeOutDate - timeInDate) / (1000 * 60 * 60);
    return isNaN(diff) ? 0 : diff;
  };

  const calculateTotalHours = (day) => {
    const hours1 = calculateHours(day.timeIn1, day.timeOut1);
    const hours2 = calculateHours(day.timeIn2, day.timeOut2);
    return hours1 + hours2;
  };

  const renderDayRow = (day, index) => {
    const today = new Date();
    const isToday = isSameDay(day.date, today);

    const rowClass = isToday ? "today-row" : "";

    return (
      <tr key={index} className={rowClass}>
        <td>{format(day.date, "EEEE, MMMM do, yyyy")}</td>
        <td>
          <input type="time" value={day.timeIn1} onChange={(e) => handleTimeChange(index, "timeIn1", e.target.value)} />
        </td>
        <td>
          <input
            type="time"
            value={day.timeOut1}
            onChange={(e) => handleTimeChange(index, "timeOut1", e.target.value)}
          />
        </td>
        <td>
          <input type="time" value={day.timeIn2} onChange={(e) => handleTimeChange(index, "timeIn2", e.target.value)} />
        </td>
        <td>
          <input
            type="time"
            value={day.timeOut2}
            onChange={(e) => handleTimeChange(index, "timeOut2", e.target.value)}
          />
        </td>
        <td>{day.hoursTotal.toFixed(2)}</td>
      </tr>
    );
  };

  const calculateWeeklyTotal = (weekStart) => {
    let total = 0;
    timeData.forEach((day) => {
      if (startOfWeek(day.date, { weekStartsOn: 1 }).getTime() === weekStart.getTime()) {
        total += day.hoursTotal;
      }
    });
    return total;
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  return (
    <Container fluid className="px-2 m-auto">
      <Card body>
        <Table bordered size="sm" className="text-center box-shadow-05 rounded-8" style={{ fontSize: "12px" }}>
          <thead className="box-shadow-05 rounded-8 pt-5">
            <tr>
              <th>Date</th>
              <th>Time In 1</th>
              <th>Time Out 1</th>
              <th>Time In 2</th>
              <th>Time Out 2</th>
              <th>Hours Total</th>
            </tr>
          </thead>
          <tbody>
            {timeData.map((day, index) => (
              <>
                {renderDayRow(day, index)}
                {(index === timeData.length - 1 ||
                  (index < timeData.length - 1 &&
                    startOfWeek(day.date, { weekStartsOn: 1 }).getTime() !==
                      startOfWeek(timeData[index + 1].date, { weekStartsOn: 1 }).getTime())) && (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "right" }} className="border-4 text-right font-lg fw-bold">
                      {calculateWeeklyTotal(startOfWeek(day.date, { weekStartsOn: 1 })).toFixed(2)} Hours
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </Table>
      </Card>
    </Container>
  );
};

export default UserTimeClock;
