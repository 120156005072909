import {
  GET_DRUGS,
  ADD_DRUG,
  DELETE_DRUG,
  UPDATE_DRUG,
  DRUGS_LOADING,
  SEARCH_DRUGS
} from "../actions/types";

const initialState = {
  drugs: [],
  searchResults: [],
  loading: false
};

export default function(state = initialState, action) {
  // console.log(
  //   "drugsReducer was called with state",
  //   state.searchResults,
  //   "and action",
  //   action.payload
  // );

  switch (action.type) {
    case SEARCH_DRUGS:
      return {
        ...state,
        searchResults: action.payload
      };
    case GET_DRUGS:
      // console.log("PAYLOAD: " + JSON.stringify(action.payload));
      return {
        ...state,
        drugs: action.payload,
        loading: false
      };
    case DELETE_DRUG:
      return {
        ...state,
        drugs: state.drugs.filter(drug => drug._id !== action.payload)
      };
    case ADD_DRUG:
      return {
        ...state,
        drugs: [action.payload, ...state.drugs]
      };
    case UPDATE_DRUG:
      const { _id } = action.payload;
      const drugs = state.drugs.map(
        drug => (drug._id === _id ? action.payload : drug)
      );
      return { ...state, drugs };
    case DRUGS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
