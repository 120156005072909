import React, { Component } from "react";
import PatientSearch from "./PatientSearch/PatientSearch";
import PatientAdd from "./PatientAdd";
import Patients from "./Patients";

import { Col, Row, Container } from "reactstrap";
import { connect } from "react-redux";
import NotAuthorized from "../NotAuthorized";
// import RefillCalendarSearch from "./Refills/RefillCalendarSearch";
// import TemporaryAdminUpdates from "../Utilities/TemporaryAdminUpdates";

class PatientDash extends Component {
  isLoggedIn() {
    return (
      <Container fluid className="px-3">
        <Row>
          <Col xs={12} className="pt-1">
            <Patients />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="pt-1">
            <PatientSearch />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <PatientAdd />
          </Col>
        </Row>
      </Container>
    );
  }

  renderCheck() {
    const auth = this.props.auth;

    switch (auth) {
      case null:
        return;
      case false:
        return (
          <div style={{ textAlign: "center" }}>
            <h5>You must login to be able to view this page.</h5>
          </div>
        );
      default:
        if (auth && auth.role) {
          return <div>{this.isLoggedIn()}</div>;
        } else {
          return <NotAuthorized />;
        }
    }
  }

  render() {
    return (
      <Container fluid className="p-0">
        <div>{this.renderCheck()}</div>
      </Container>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps)(PatientDash);
