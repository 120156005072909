// StatusSelector.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { updatePatientStatus } from "../../../actions/patientActions";
import { formatDateShortYY } from "../../Utilities/CalculateDate";

const colors = {
  green: "#01fc3c",
  red: "#ff0016",
  orange: "#ff913c",
  white: "#ececec",
  blue: "#1c8cff",
  yellow: "#fff424",
};

const StatusSelector = ({ patientId, currentStatus, statusUpdatedAt }) => {
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    const statusData = {
      status: status,
      statusUpdatedAt: new Date(),
    };
    dispatch(updatePatientStatus(patientId, statusData));
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} size="sm">
      <DropdownToggle
        className="p-0"
        style={{
          backgroundColor: colors[selectedStatus],
          width: "24px",
          height: "24px",
          border: "1px solid #ccc",
          borderRadius: "8px", // Add border radius for rounded corners
        }}
      >
        <span
          className="d-block"
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: colors[selectedStatus],
            borderRadius: "8px", // Add border radius for rounded corners
          }}
        ></span>
      </DropdownToggle>
      <DropdownMenu
        className="p-0 m-0"
        style={{
          minWidth: "unset",
          width: "auto", // Set auto width
        }}
      >
        {Object.keys(colors).map((color) => (
          <DropdownItem key={color} onClick={() => handleStatusChange(color)} className="p-0 m-0">
            <span
              className="d-inline-block"
              style={{
                width: "24px",
                height: "24px",
                backgroundColor: colors[color],
                border: selectedStatus === color ? "2px solid black" : "none",
                borderRadius: "8px", // Add border radius for rounded corners
                display: "block",
                margin: "2px", // Add some margin to prevent items from touching
              }}
            ></span>
          </DropdownItem>
        ))}
        <span disabled className="fs-xxs text-muted">
          {formatDateShortYY(statusUpdatedAt)}
        </span>
      </DropdownMenu>
    </Dropdown>
  );
};

export default StatusSelector;
