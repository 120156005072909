import axios from "axios";
import {
  GET_ALL_USERS,
  USERS_LOADING,
  DELETE_USER,
  UPDATE_USER,
  SET_USER_SORTING_PREFERENCE,
  GET_USER_SORTING_PREFERENCE,
  FETCH_USER,
} from "./types";

// Fetch the current user
export const fetchUser = () => async (dispatch) => {
  const res = await axios.get("/api/current_user");
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const getAllUsers = () => (dispatch) => {
  dispatch(setUsersLoading());
  axios.get("/api/users/all-users").then((res) =>
    dispatch({
      type: GET_ALL_USERS,
      payload: res.data,
    }),
  );
};

// Action to set user sorting preference
export const setUserSortingPreference =
  ({ userId, sortColumn, sortOrder }) =>
  (dispatch) => {
    return axios
      .post("/api/user/sorting-preference", { userId, sortColumn, sortOrder })
      .then((res) =>
        dispatch({
          type: SET_USER_SORTING_PREFERENCE,
          payload: res.data,
        }),
      )
      .catch((err) => console.log(err));
  };

// Action to get user sorting preference
export const getUserSortingPreference = (userId) => (dispatch) => {
  return axios
    .get(`/api/user/sorting-preference?userId=${userId}`)
    .then((res) =>
      dispatch({
        type: GET_USER_SORTING_PREFERENCE,
        payload: res.data,
      }),
    )
    .catch((err) => console.log(err));
};

// Update user preferences (for authenticated user)
// export const updateUserPreferences = (userId, preferences) => (dispatch) => {
//   axios.put(`/api/users/${userId}/preferences`, preferences).then((res) =>
//     dispatch({
//       type: UPDATE_USER_PREFERENCES,
//       payload: res.data,
//     }),
//   );
// };

export const deleteUser = (id) => (dispatch) => {
  axios.delete(`/api/users/${id}`).then((res) =>
    dispatch({
      type: DELETE_USER,
      payload: id,
    }),
  );
};

export const updateUser = (id, user) => (dispatch) => {
  axios.put(`/api/users/${id}`, user).then((res) =>
    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    }),
  );
};

export const setUsersLoading = () => {
  return {
    type: USERS_LOADING,
  };
};
