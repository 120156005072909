import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import classNames from "classnames";

const SevenDayTable = (props) => {
  const [dailyRefills, setDailyRefills] = useState({ m: 0, tu: 0, w: 0, th: 0, f: 0 });
  const [sent, setSent] = useState({ m: 0, tu: 0, w: 0, th: 0, f: 0 });
  const [temp, setTemp] = useState({ m: 0, tu: 0, w: 0, th: 0, f: 0 });
  const [rxCo, setRxCo] = useState({ m: 0, tu: 0, w: 0, th: 0, f: 0 });
  // const [showDays2, setShowDays2] = useState({ showDays2: true });

  // const calculateFirstDay = (week) => {
  //   return moment().add(week, "weeks").startOf("week").add(1, "days").format("MM/DD");
  // };

  const calculateEachWeekDay = (week) => {
    let weekdays = [];
    for (let i = 1; i < 6; i++) {
      weekdays.push(moment().add(week, "weeks").startOf("week").add(i, "days").format("MMM DD, YYYY"));
    }
    return weekdays;
  };

  const calcDayLetter = (i) => {
    switch (i) {
      case 0:
        return "m";
      case 1:
        return "tu";
      case 2:
        return "w";
      case 3:
        return "th";
      case 4:
        return "f";
      default:
        return;
    }
  };

  useEffect(() => {
    let dailyRefills = { m: 0, tu: 0, w: 0, th: 0, f: 0 };
    let sent = { m: 0, tu: 0, w: 0, th: 0, f: 0 };
    let temp = { m: 0, tu: 0, w: 0, th: 0, f: 0 };
    let rxCo = { m: 0, tu: 0, w: 0, th: 0, f: 0 };

    const calculate = (refill, days, weeks) => {
      return moment(refill.refillDate, "YYYY-MM-DDTHH:mm:ss").isBetween(
        moment().add(weeks, "weeks").startOf("week"),
        moment().add(weeks, "weeks").startOf("week").add(days, "d"),
        "day",
      );
    };

    if (props.refillsByDay.length > 0) {
      props.refillsByDay.forEach((refill) => {
        if (calculate(refill, 2, props.week)) {
          dailyRefills.m++;
          refill.requestSent && sent.m++;
          refill.temporary && temp.m++;
          if (refill.rxCount && !refill.temporary) rxCo.m += refill.rxCount;
        } else if (calculate(refill, 3, props.week)) {
          dailyRefills.tu++;
          refill.requestSent && sent.tu++;
          refill.temporary && temp.tu++;
          if (refill.rxCount && !refill.temporary) rxCo.tu += refill.rxCount;
        } else if (calculate(refill, 4, props.week)) {
          dailyRefills.w++;
          refill.requestSent && sent.w++;
          refill.temporary && temp.w++;
          if (refill.rxCount && !refill.temporary) rxCo.w += refill.rxCount;
        } else if (calculate(refill, 5, props.week)) {
          dailyRefills.th++;
          refill.requestSent && sent.th++;
          refill.temporary && temp.th++;
          if (refill.rxCount && !refill.temporary) rxCo.th += refill.rxCount;
        } else if (calculate(refill, 6, props.week)) {
          dailyRefills.f++;
          refill.requestSent && sent.f++;
          refill.temporary && temp.f++;
          if (refill.rxCount && !refill.temporary) rxCo.f += refill.rxCount;
        }
      });
    }

    setDailyRefills(dailyRefills);
    setSent(sent);
    setTemp(temp);
    setRxCo(rxCo);
  }, [props.refillsByDay, props.week]);

  return (
    <Table
      borderless
      size="sm"
      className="text-center box-shadow-05 rounded-8"
      style={{ fontSize: "12px", margin: "5px" }}
    >
      {props.showDays === false ? (
        <></>
      ) : (
        <thead className="box-shadow-05 rounded-8 pt-1">
          <tr>
            {/*{props.showWeek === true ? <td /> : <></>}*/}
            <th className="pb-0 pt-1">M</th>
            <th className="pb-0 pt-1">Tu</th>
            <th className="pb-0 pt-1">W</th>
            <th className="pb-0 pt-1">Th</th>
            <th className="pb-0 pt-1">F</th>
          </tr>
        </thead>
      )}
      <tbody>
        <tr>
          {calculateEachWeekDay(props.week).map((date, i) => {
            const dayLetter = calcDayLetter(i);
            const isZero = dailyRefills[dayLetter] === 0;
            const notSent = dailyRefills[dayLetter] - sent[dayLetter];
            const notTemp = dailyRefills[dayLetter] - temp[dayLetter];
            const rxCountTotal = rxCo[dayLetter];
            return (
              // <td key={i + date} style={{ width: "20%" }} className="pb-1 pt-1 px-0 border-lighter">
              <td key={i + date} style={{ width: "20%", paddingBottom: "1px" }} className="px-0">
                <div
                  className={classNames("p-1 px-2 border border-light-subtle bg-calendar-square rounded", {
                    "border-left-light": i === 0,
                  })}
                >
                  <div className="opacity-5 fw-light fs-sm">{date.split(",")[0]}</div>
                  <div
                    onClick={() => !isZero && props.sendDate(date)}
                    className={classNames(
                      "px-0 mx-0 fs-lg bg-white rounded py-1 mb-0 d-flex justify-content-between align-items-center",
                      {
                        "opacity-2 text-lighter": isZero,
                        "cursor-pointer fw-bold": !isZero,
                        "text-success": notSent === 0 && !isZero,
                      },
                    )}
                  >
                    {rxCountTotal > 0 && <span className="opacity-3 fs-sm text-info">{rxCountTotal}</span>}
                    <span className="border rounded-8 px-1 py-0 mx-auto"> {notTemp} </span>
                    {notSent !== 0 && <span className="opacity-05 rounded-8 fs-sm">{notSent}</span>}
                  </div>
                </div>
              </td>
            );
          })}
        </tr>
      </tbody>
    </Table>
  );
};

export default SevenDayTable;
