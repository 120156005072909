import React, { Component } from "react";
import { Card, CardTitle, ListGroupItem, Col, ListGroup } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DrugEditModal from "./DrugEditModal";
import { deleteDrug, getDrugs, updateDrug } from "../../actions/drugActions";

class DrugItem extends Component {
  render() {
    const drug = this.props.drugItem;

    return (
      <Col sm="4" key={drug._id} style={{ padding: "4px" }}>
        <Card body>
          <CardTitle style={{ color: "#0065ff" }}>
            <strong>{drug.brandName}</strong> |{" "}
            <strong>{drug.genericName}</strong>
          </CardTitle>
          <ListGroup className="drug-list-item">

            {drug.primaryIcdCode ? (
              <ListGroupItem>
                <b>Primary:</b> {drug.primaryIcdCode.icdCodeNum} -{" "}
                <i>{drug.primaryIcdCode.icdCodeValue}</i>
              </ListGroupItem>
            ) :               <ListGroupItem>
              <i style={{color: "rgba(255,0,0, 0.4)"}}>Please add a Primary ICD Code</i>
            </ListGroupItem>}

            {drug.otherIcdCodes.length > 0 && (
              <ListGroupItem>
                <strong>Other Codes: </strong>
                {drug.otherIcdCodes.map(({ _id, icdCodeNum }) => (
                  <span key={_id}>{icdCodeNum} </span>
                ))}
              </ListGroupItem>
            )}

            {drug.drugNotes && (
              <ListGroupItem>
                <strong> Drug Notes:</strong> {drug.drugNotes}
              </ListGroupItem>
            )}

            {drug.drugSchedule !== "0" && (
              <ListGroupItem>
                <b>Schedule:</b> <b style={{color: "red"}}>{drug.drugSchedule}</b>
              </ListGroupItem>
            )}

            {drug.drugClass && (
              <ListGroupItem>
                <b>Drug Class:</b> {drug.drugClass}
              </ListGroupItem>
            )}

            {drug.extraDrugInfo && (
              <ListGroupItem>
                <b>Extra Info:</b> {drug.extraDrugInfo}
              </ListGroupItem>
            )}

            <DrugEditModal editDrug={drug} key={drug._id} />
          </ListGroup>
        </Card>
      </Col>
    );
  }
}

DrugItem.propTypes = {
  getDrugs: PropTypes.func.isRequired,
  drug: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  drug: state.drug
});

export default connect(
  mapStateToProps,
  { getDrugs, deleteDrug, updateDrug }
)(DrugItem);
