import React, { useState } from "react";
import { Collapse, Button, Card, Tooltip, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _ from "lodash";
import moment from "moment";
import RefillEditModal from "../Refills/RefillEditModal";
import { isItSameOrBeforeToday, formatDate, isItSameOrBefore } from "../../Utilities/CalculateDate";
import classNames from "classnames";
import RefillItem from "../Refills/RefillItem";

const PartnerRefillsBox = (props) => {
  const [collapse, setCollapse] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleCollapse = () => setCollapse(!collapse);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const { firstName, lastName, refills, _id, active, deceased } = props.partner;
  const incomingRefillId = props.refillId;
  const { componentFrom } = props;
  const toolTarget = "tt2-" + componentFrom + _id + incomingRefillId;

  // const isItBefore = (refillDate, amount, unit, checkBefore) => {
  //   if (checkBefore) {
  //     return moment(refillDate).isBefore(moment().add(amount, unit));
  //   }
  //   // console.log("what time: " + moment().subtract(1, "days").endOf("day").format("MM/DD/YYYY"));
  //   // console.log("now time: " + moment(refillDate).format("MM/DD/YYYY"));
  //   return moment(refillDate).isSameOrBefore(moment().subtract(2, "days").endOf("day"));
  // };

  const findNextRefill = (refills, firstName, lastName, patientId) => {
    // const newRefills = _.orderBy(refills, ["refillDate"]);

    return (
      <>
        {refills.map((refill, i) => {
          return (
            <RefillItem
              refillProp={refill}
              patient={{ firstName, lastName, patientId }}
              index={i}
              showDate
              showBadges
              showEditModal
              // showName
              whichComponent={componentFrom + "pt-ref-box-"}
              // className="rounded-6 "
              className={classNames(
                "rounded-6 p-2 text-center",
                {
                  "bg-danger-transparent rounded-8":
                    isItSameOrBefore(refill.refillDate, 2, "weeks") && isItSameOrBeforeToday(refill.refillDate),
                },
                { "bg-gray-transparent rounded-8 opacity-4": !isItSameOrBefore(refill.refillDate, 2, "weeks") },
              )}
              key={"prb-" + i}
              colSizes={[4, 6, 6]}
            />

            // <div
            //   key={i + "ref-box1"}
            //   // className="card border rounded p-2 text-center"
            //   // className={
            //   //   "card border rounded p-2 text-center " + isItSameOrBefore(refill.refillDate, 2, "weeks")
            //   //     ? isItSameOrBeforeToday(refill.refillDate)
            //   //       ? "bg-danger-transparent rounded-8"
            //   //       : ""
            //   //     : "bg-gray-transparent rounded-8 opacity-4"
            //   // }
            //   className={classNames(
            //     "card border rounded p-2 text-center",
            //     {
            //       "bg-danger-transparent rounded-8":
            //         isItSameOrBefore(refill.refillDate, 2, "weeks") && isItSameOrBeforeToday(refill.refillDate),
            //     },
            //     { "bg-gray-transparent rounded-8 opacity-4": !isItSameOrBefore(refill.refillDate, 2, "weeks") }
            //   )}
            // >
            //   <b
            //     style={{
            //       opacity: "0.8",
            //       color: refill.requestSent ? "#049100" : "black",
            //     }}
            //   >
            //     {/*{moment(refill.refillDate, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY")}*/}
            //     {formatDate(refill.refillDate)}
            //
            //     {/*{refill.requestSent && <div className="mt-n1 font-small opacity-2">requests sent</div>}*/}
            //     {refill.requestSent && (
            //       <i className="material-icons opacity-4 text-success position-absolute ms-2">check_circle_outline</i>
            //     )}
            //   </b>
            //   {refill.refillNotes && (
            //     <>
            //       {refill.refillNotes.length > 0 && (
            //         <div className="pt-1 line-height-1">
            //           <Badge className="opacity-2 ">Refill Notes</Badge>
            //           {refill.refillNotes.map(({ note, date, userName }, index) => (
            //             <div key={index + 200} className="mb-1">
            //               <Badge color="light" className="text-wrap opacity-4">
            //                 {userName} {moment(date).format("M/DD/YY")}:{" "}
            //               </Badge>
            //               {note}
            //             </div>
            //           ))}
            //         </div>
            //       )}
            //     </>
            //   )}
            //   <RefillEditModal
            //     patientName={lastName + ", " + firstName}
            //     editRefill={{
            //       refillDate: refill.refillDate,
            //       refillNotes: refill.refillNotes,
            //       typeOfRequest: refill.typeOfRequest,
            //       requestSent: refill.requestSent,
            //       _id: refill._id,
            //     }}
            //     patientId={_id}
            //     whichComponent="PartnerRefillsBox"
            //     showPhone={true}
            //   />
            // </div>
          );
        })}
      </>
    );
  };

  const orderedRefills = _.orderBy(refills, ["refillDate"]);

  return (
    <>
      <Button
        color="light"
        className={classNames("btn-icon border ms-1", {
          "opacity-2": !active,
        })}
        id={toolTarget}
        onClick={toggleCollapse}
        // onClick={!!deceased ? null : toggleCollapse}
        // onClick={active ? toggleCollapse : (e) => goToLink(`patients/${_id}`)}
        // disabled={!active || deceased}
        // onMouseEnter={() => toggleCollapse()}
      >
        <i className={classNames("material-icons text-primary m-icon-custom", { "deceased-red": !!deceased })}>wc</i>
      </Button>

      {/*<Badge*/}
      {/*  color="light"*/}
      {/*  onClick={toggleCollapse}*/}
      {/*  onMouseEnter={() => toggleCollapse()}*/}
      {/*  className="box-shadow-2 mb-1"*/}
      {/*  id={toolTarget}*/}
      {/*>*/}
      {/*  <i className="material-icons text-primary">wc</i>*/}
      {/*</Badge>*/}

      <div>
        <Collapse isOpen={collapse}>
          <Card className="px-2 py-1 text-center">
            <div className={!!active ? "mt-2 line-height-1" : "mt-2 mb-2 line-height-1"}>
              <Link
                to={`/patients/${_id}`}
                // target="_blank"
                className={!!active ? "btn-link mx-0" : "btn-link mx-0 text-danger"}
              >
                {lastName && lastName.toUpperCase()}, {firstName && firstName.toUpperCase()}
              </Link>
            </div>
            {!!active && (
              <>
                {refills.length < 1 && (
                  <div>
                    <CopyToClipboard text={lastName + ", " + firstName}>
                      <Button className="custom-btn mt-0 bg-lime fw-normal">COPY</Button>
                    </CopyToClipboard>
                    <div className="card border rounded p-2 text-center text-muted">no refills</div>
                  </div>
                )}
                {findNextRefill(orderedRefills, firstName, lastName, _id)}
              </>
            )}
          </Card>
        </Collapse>
      </div>
      {/*</Col>*/}

      <Tooltip
        placement="top-start"
        flip={false}
        boundariesElement="viewport"
        modifiers={[
          {
            name: "preventOverflow",
            options: {
              // mainAxis: false, // true by default
              boundariesElement: "window",
            },
          },
        ]}
        // hideArrow={true}
        isOpen={tooltipOpen}
        target={toolTarget}
        toggle={toggleTooltip}
      >
        <div className="line-height-1">
          <div className={classNames("font-lg", { "text-danger": !active })}>
            {lastName && lastName.toUpperCase()}, {firstName && firstName.toUpperCase()}
          </div>
          {!active && !deceased && <div className="text-danger">inactive</div>}
          {!!deceased && <div className="text-danger fw-bold">patient is deceased</div>}
          {!!active && (
            <>
              {orderedRefills.length > 0 &&
                orderedRefills.map((refill, i) => (
                  <div
                    key={i + "ref-box-tip"}
                    className={
                      isItSameOrBefore(refill.refillDate, 2, "weeks")
                        ? isItSameOrBeforeToday(refill.refillDate)
                          ? "text-danger"
                          : "text-success fw-bold"
                        : ""
                    }
                  >
                    {/*{moment(refill.refillDate, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY")}*/}
                    {formatDate(refill.refillDate)}
                  </div>
                ))}

              <div className="text-muted font-small">click to show refills</div>
            </>
          )}
        </div>
      </Tooltip>
    </>
  );
};

export default PartnerRefillsBox;
