import { USERS_LOADING, GET_ALL_USERS, DELETE_USER, UPDATE_USER } from "../actions/types";

const initialState = {
  users: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };
    case UPDATE_USER:
      const { _id, username, name, lastName, pharmacy, role, email } = action.payload;
      const users = state.users.map((user) => {
        if (user._id === _id) {
          return { ...user, username, name, lastName, pharmacy, role, email };
        }
        return user;
      });
      return { ...state, users: users };
    case USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
