import React from "react";
import { Badge, Card, Table } from "reactstrap";
import classNames from "classnames";
// import { useDispatch } from "react-redux";
// import { updateFamily } from "../../../actions/patientActions";
import { Link } from "react-router-dom";
import UnlinkFamTooltip from "./UnlinkFamTooltip";

const FamilyMembersTable = ({ family, primaryPatientId, foundPatientId, showUnlink }) => {
  return (
    <div className="bg-light rounded-6">
      <div className="opacity-2 mb-0">Other Family</div>
      {family.length > 0 ? (
        <>
          <Card className="py-1 px-2 mt-0 mx-1">
            <Table size="sm" borderless reponsive="true" className="mb-0 text-left">
              <tbody>
                {family.map((fam, i) => {
                  return (
                    <tr
                      key={i + "famBam"}
                      className="d-flex align-items-center border rounded-8 border-light"
                      // className={classNames("d-flex align-items-center border rounded-8 border-light", {
                      //   "bg-danger-transparent rounded": fam.member._id === primaryPatientId,
                      // })}
                    >
                      <td className="col-sm-5 col-xs-5 px-0 fs-sm">{fam.relationship}</td>
                      <td className="col-sm-7 col-xs-7 px-0">
                        {fam.member._id !== primaryPatientId ? (
                          <>
                            {/*{primaryPatientId}*/}
                            {/*<div>{fam.member._id}</div>*/}
                            {/*<div>{fam.member._id === primaryPatientId && "true"}</div>*/}

                            <Link
                              to={fam.member._id ? `/patients/${fam.member._id}` : ""}
                              // target="_blank"
                              className={classNames("fw-bold d-flex align-items-center", {
                                "text-danger": !fam.member.active,
                                "opacity-2": fam.member.deceased,
                              })}
                            >
                              {fam.member.firstName} {fam.member.lastName}{" "}
                              {fam.member.deceased && <span className="fs-xs ms-1">deceased</span>}
                            </Link>
                          </>
                        ) : (
                          <b className="text-danger">
                            {/*{primaryPatientId}*/}
                            {/*<div>{fam.member._id}</div>*/}
                            {/*<div>{fam.member._id === primaryPatientId && "true"}</div>*/}
                            This Patient
                            <div className="fs-xs mt-1">
                              they are already linked together, you must unlink them first to change their relationship
                            </div>
                          </b>
                        )}
                        {showUnlink && (
                          <>
                            {/*Check if from search result section (with found patient) or part of main patient section (primary patient)*/}
                            {foundPatientId ? (
                              <UnlinkFamTooltip
                                mainId={foundPatientId}
                                famId={fam.member._id}
                                index={i}
                                foundOrPrimary="found"
                              />
                            ) : (
                              <UnlinkFamTooltip
                                mainId={primaryPatientId}
                                famId={fam.member._id}
                                index={i}
                                foundOrPrimary="primary"
                              />
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </>
      ) : (
        <>
          <Badge color="light" className="p-2 text-wrap text-lighter d-block">
            this patient has no other family members
          </Badge>
        </>
      )}
    </div>
  );
};

export default FamilyMembersTable;
