import React, { Component } from "react";
import { ListGroup, Row } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Pagination from "rc-pagination";
import { getIcdCodes } from "../../../actions/icdCodeActions";
import IcdCodeItem from "./IcdCodeItem";
import PaginationNew from "react-js-pagination";

class IcdCodes extends Component {
  componentDidMount() {
    this.props.getIcdCodes();
  }

  renderIcdItems = (allCodes) => {
    const secondItem = 16 * this.state.current;
    const firstItem = secondItem - 16;
    return allCodes
      .slice(firstItem, secondItem)
      .map((icdCode) => <IcdCodeItem icdCodeItem={icdCode} key={icdCode._id} />);
  };

  state = {
    current: 1,
  };

  onChange = (page) => {
    this.setState({
      current: page,
    });
  };

  render() {
    const { icdCodes } = this.props.icdCode;

    return (
      <div>
        <ListGroup flush>
          <Row>{this.renderIcdItems(icdCodes)}</Row>
        </ListGroup>
        <div className="text-center">
          {/*<Pagination*/}
          {/*  onChange={this.onChange}*/}
          {/*  current={this.state.current}*/}
          {/*  total={icdCodes.length}*/}
          {/*  pageSize={16}*/}
          {/*/>*/}
          <div className="font-small text-muted mb-1">{icdCodes.length} Icd Codes</div>
          <PaginationNew
            onChange={this.onChange}
            activePage={this.state.current}
            totalItemsCount={icdCodes.length}
            itemsCountPerPage={16}
            pageRangeDisplayed={6}
            // the following are styling items
            itemClass="page-item m-0"
            linkClass="page-link"
            innerClass="pagination justify-content-center"
          />
        </div>
      </div>
    );
  }
}

IcdCodes.propTypes = {
  getIcdCodes: PropTypes.func.isRequired,
  icdCode: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  icdCode: state.icdCode,
});

export default connect(mapStateToProps, { getIcdCodes })(IcdCodes);

// { getIcdCodes, deleteIcdCode, updateIcdCode }
