import React, { useState, useEffect, useCallback } from "react";
import { Card, Button, Badge, Spinner, ListGroup, ListGroupItem } from "reactstrap";
import { useSelector, useDispatch, connect } from "react-redux";
import moment from "moment";
import { getPatients, getRefillsByRange, setRefillsDateRange } from "../../../actions/patientActions";
import { formatDateShort, orderDates } from "../../Utilities/CalculateDate";
// import { orderBy, sortBy } from "lodash";
import RefillItem from "./RefillItem";
// import GoBack from "../../../GoBack";
// import { CopyToClipboard } from "react-copy-to-clipboard";

const RefillsUpcomingWeek = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  // const patient = useSelector((state) => state.patient);
  const start = useSelector((state) => state.patient.startDateRange);
  const end = useSelector((state) => state.patient.endDateRange);
  const refills = useSelector((state) => state.patient.refillsByRange);
  const { patient } = useSelector((state) => state);
  // const params = useParams();

  useEffect(() => {
    dispatch(getRefillsByRange(start, end));
    if (patient.patients && patient.loading) {
      setLoading(false);
    }
  }, [patient.patients, patient.loading, patient.refills, start, end]);
  //removed "loading" from dependencies to address issue with reloading after each render

  const calculateWeek = (amount) => {
    let end1, start1;
    if (amount > 0) {
      start1 = moment(start).add(7, "days").format("YYYY-MM-DD");
      end1 = moment(end).add(7, "days").format("YYYY-MM-DD");
    } else {
      start1 = moment(start).subtract(7, "days").format("YYYY-MM-DD");
      end1 = moment(end).subtract(7, "days").format("YYYY-MM-DD");
    }
    // setStartDate(start);
    // setEndDate(end);
    // setLoading(true);
    dispatch(setRefillsDateRange(start1, end1));
  };

  const loaded = (patient) => {
    return (
      // <div className="">
      <Card className="">
        <ListGroup className="rounded-8 overflow-y-auto max-height-scroll">
          <ListGroupItem className="py-2 px-1 text-center bg-light sticky-top z-index-1">
            <div>
              <Button onClick={() => calculateWeek(-1)} size="sm" color="light" className="my-0 me-2 py-0 border">
                ⇠
              </Button>
              <Badge color="light" className="border-bottom text-black-50 user-select-none">
                Week of <strong>{formatDateShort(moment(start).add(1, "days"))}</strong>
              </Badge>
              <Button onClick={() => calculateWeek(1)} size="sm" color="light" className="my-0 ms-2 py-0 border">
                ⇢
              </Button>
            </div>
          </ListGroupItem>
          {refills &&
            // orderDates(refills, "refillDate", -1).map((refill, index) => (
            // orderBy(refills, ["refillDate", "lastName"], ["asc", "asc"]).map((refill, index) => (
            refills.map((refill, index) => (
              <ListGroupItem className="p-0 m-0" key={refill.lastName + index}>
                <RefillItem
                  refillProp={refill}
                  patient={{
                    firstName: refill.firstName,
                    lastName: refill.lastName,
                    patientId: refill.patientId,
                    partner: refill.partner ? refill.partner : null,
                    hasControls: refill.hasControls,
                    isDelivery: refill.isDelivery,
                    rxCount: refill.rxCount,
                  }}
                  showDate
                  showBadges
                  showEditModal
                  showName
                  whichComponent="ref-upc-week-"
                />
              </ListGroupItem>
            ))}
        </ListGroup>
      </Card>
      // </div>
    );
  };

  const notLoaded = () => {
    return (
      <Card className="text-center">
        <ListGroup className="rounded-8 opacity-4">
          <ListGroupItem className="py-2 px-1 text-center bg-light">
            <div>
              <Button size="sm" color="light" className="my-0 me-2 py-0 border opacity-2">
                ⇠
              </Button>
              <Badge color="light" className="border-bottom text-black-50 opacity-4 user-select-none">
                Week of <strong>{formatDateShort(moment(start).add(1, "days"))}</strong>
              </Badge>
              <Button size="sm" color="light" className="my-0 ms-2 py-0 border opacity-2">
                ⇢
              </Button>
            </div>
          </ListGroupItem>
          <ListGroupItem className="py-3 px-2 text-center bg-light">
            {/*<div className="loading-box p- align-items-center">*/}
            <div className="d-flex mx-1 bg-gray-transparent opacity-2 p-3 rounded-8" style={{ height: "305px" }}>
              <Spinner className="m-auto opacity-2" color="dark" />
            </div>
          </ListGroupItem>
        </ListGroup>
      </Card>
    );
  };

  return <div>{!loading && patient.loading ? notLoaded() : loaded()}</div>;
};

export default connect(null, null)(RefillsUpcomingWeek);

// RefillsUpcomingWeek.propTypes = {
//   patient: PropTypes.object.isRequired,
// };

// const mapStateToProps = (state) => ({
//   patient: state.patient,
//   auth: state.auth,
// });

// export default connect(mapStateToProps, {
//   viewPatient,
//   deletePatient,
//   updatePatient,
//   deletePatientRefill,
// })(RefillsUpcomingWeek);

// ------ORIGINAL WORKING
// RefillsUpcomingWeek.propTypes = {
//   getPatients: PropTypes.func.isRequired,
//   patient: PropTypes.object.isRequired
// };
//
// const mapStateToProps = state => ({
//   patient: state.patient
// });
//
// export default connect(
//   mapStateToProps,
//   { getPatients, deletePatient, updatePatient }
// )(RefillsUpcomingWeek);
