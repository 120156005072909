import React, { Component } from "react";
import { Card, Badge, Button, Col, Row, Form, FormGroup, Input } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateRefillCheckedDate, addRefillCheckedDate } from "../../../actions/patientActions";
import moment from "moment";
import { sortBy } from "lodash";
import PopoverItem from "./PopoverItem";

class SecondaryRefillsCheckedItem extends Component {
  state = {
    dateChecked: "",
    popoverOpen: false,
  };

  handleUpdateDate = (patientId, dateId) => {
    this.props.updateRefillCheckedDate(patientId, dateId);
    // console.log("DATE ID: " + dateId);
  };

  handleAddDate = (patientId) => (e) => {
    e.preventDefault();
    this.props.addRefillCheckedDate(patientId, { dateChecked: this.state.dateChecked });
    this.clearCourseAndState();
  };

  clearCourseAndState = () => {
    document.getElementById("refill-date-course").reset();

    this.setState({
      dateChecked: "",
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const dates = this.props.datesRefillsLastChecked;
    const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
    const twoMonths = moment().subtract(2, "months").format("YYYY-MM-DD");
    const { patientId } = this.props;

    // console.log("in render " + this.state.refillDate)

    return (
      <Row className="justify-content-center overflow-y-auto max-height-40 p-2">
        <Col sm={4} className="p-2">
          <Card body className="mh-100 p-3">
            <div className="">
              <Form id="refill-date-course" autoComplete="off" onSubmit={this.handleAddDate(patientId)}>
                <FormGroup>
                  <Row>
                    <Col>
                      <Input
                        type="date"
                        name="dateChecked"
                        // placeholder="New refill date"
                        min={twoMonths}
                        max={tomorrow}
                        value={this.state.dateChecked}
                        onChange={this.handleChange}
                        required
                      />
                    </Col>
                  </Row>
                  <Button color="info" block style={{ marginBottom: "10px" }}>
                    Save Refill Date Checked
                  </Button>
                </FormGroup>
              </Form>
            </div>
          </Card>
        </Col>

        <Col sm={8} className="p-2">
          <Card body>
            <h6>Dates Refills Were Checked</h6>
            <Row className="justify-content-center overflow-y-auto max-height-40">
              {sortBy(dates, "dateChecked")
                .reverse()
                .map((object, i) => (
                  <Card body key={"secondary-key-" + i} className="mx-1 text-center">
                    {moment(object.dateChecked, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY")}

                    <PopoverItem
                      index={"index-" + object._id}
                      position={"bottom"}
                      buttonText="Delete"
                      parentFunction={() => this.handleUpdateDate(patientId, object._id)}
                    >
                      <Button style={{ marginTop: "0px" }} className="btn btn-danger btn-sm">
                        Delete
                      </Button>
                    </PopoverItem>
                  </Card>
                ))}
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

SecondaryRefillsCheckedItem.propTypes = {
  patient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
});

// export default connect(mapStateToProps)(SecondaryRefillsCheckedItem);
export default connect(mapStateToProps, { updateRefillCheckedDate, addRefillCheckedDate })(SecondaryRefillsCheckedItem);
