import React from "react";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";
import NotAuthorized from "../../NotAuthorized";
import RefillsDash from "./RefillsDash";

const Refills = (props) => {
  // const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const isLoggedIn = () => {
    return (
      <div className="pt-1 px-2">
        <RefillsDash />
      </div>
    );
  };

  const renderCheck = () => {
    switch (auth) {
      case null:
        return;
      case false:
        return (
          <div style={{ textAlign: "center" }}>
            <h5>You must login to be able to view this page.</h5>
          </div>
        );
      default:
        if (auth && auth.role) {
          return <div>{isLoggedIn()}</div>;
        } else {
          return <NotAuthorized />;
        }
    }
  };

  return (
    <Container fluid className="p-0">
      <div>{renderCheck()}</div>
    </Container>
  );
};

export default Refills;

// import React, { Component } from "react";
// import { Container } from "reactstrap";
// import { connect } from "react-redux";
// import NotAuthorized from "../../NotAuthorized";
// import RefillSearch from "./RefillSearch";
//
// class Refills extends Component {
//   isLoggedIn() {
//     return (
//       <div>
//         <RefillSearch />
//       </div>
//     );
//   }
//
//   renderCheck() {
//     const auth = this.props.auth;
//
//     switch (auth) {
//       case null:
//         return;
//       case false:
//         return (
//           <div style={{ textAlign: "center" }}>
//             <h5>You must login to be able to view this page.</h5>
//           </div>
//         );
//       default:
//         if (auth && auth.role) {
//           return <div>{this.isLoggedIn()}</div>;
//         } else {
//           return <NotAuthorized />;
//         }
//     }
//   }
//
//   render() {
//     return (
//       <Container fluid="xl" className="p-0">
//         <div>{this.renderCheck()}</div>
//       </Container>
//     );
//   }
// }
//
// function mapStateToProps({ auth }) {
//   return { auth };
// }
//
// export default connect(mapStateToProps)(Refills);
