import React, { Component } from "react";
import { Card, CardTitle, Form, Input, ListGroup, Button, Badge, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { searchDrugs } from "../../actions/drugActions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _ from "lodash";
import DrugEditModal from "./DrugEditModal";

class DrugSearch extends Component {
  state = { searchTerm: "" };

  componentDidMount() {
    this.debounceSearch = _.debounce(this.debounceSearch, 1000);
    this.debounceResetSearch = _.debounce(this.resetSearch, 1000 * 90);
  }

  componentWillUnmount() {
    this.debounceResetSearch.cancel();
    this.resetSearch();
  }

  clearCourse = () => {
    document.getElementById("course-drug").reset();
  };

  debounceSearch = () => {
    this.props.searchDrugs(this.state.searchTerm);
  };

  resetSearch = () => {
    this.setState({ searchTerm: "" });
    this.props.searchDrugs();
  };

  handleChange = (e) => {
    this.setState({ searchTerm: e.target.value });
    this.debounceSearch();
    this.debounceResetSearch();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.debounceSearch();
    this.clearCourse();
    this.debounceResetSearch();
  };

  checkIfResultExists = (searchResults) => {
    return (
      <div>
        <Row>
          {/*the filter checks if item is null*/}
          {searchResults
            .filter((item) => !!item)
            .map(
              ({
                _id,
                brandName,
                genericName,
                drugNotes,
                primaryIcdCode,
                otherIcdCodes,
                drugSchedule,
                drugClass,
                extraDrugInfo,
              }) => (
                <Col md={6} key={_id} style={{ padding: "1px" }}>
                  <Card className="p-1">
                    <h4>
                      <div style={{ marginTop: "0px" }} className="drug-search-name">
                        <div>
                          <strong>
                            {brandName}{" "}
                            <b>
                              {drugSchedule !== "0" && (
                                <span className="text-white bg-danger rounded-6 px-2 pb-1">{drugSchedule}</span>
                              )}
                            </b>
                          </strong>
                        </div>
                        <div>{genericName}</div>
                        {/*{drugSchedule !== "0" && (*/}
                        {/*  <h4 style={{ color: "red", backgroundColor: "black", borderRadius: "8px", padding: "4px" }}>*/}
                        {/*    <b>{drugSchedule}</b>*/}
                        {/*  </h4>*/}
                        {/*)}*/}
                      </div>
                    </h4>
                    {primaryIcdCode ? (
                      <div className="gray-box p-0">
                        <CopyToClipboard style={{ float: "left" }} text={primaryIcdCode.icdCodeNum}>
                          <Button className="btn-lg-icd">{primaryIcdCode.icdCodeNum}</Button>
                        </CopyToClipboard>

                        <div style={{ fontSize: "14px", verticalAlign: "center" }}>{primaryIcdCode.icdCodeValue}</div>
                      </div>
                    ) : (
                      <div style={{ color: "rgba(255,0,0, 0.4)" }}>
                        <i>Primary ICD Code Missing</i>
                      </div>
                    )}
                    {drugNotes && <h6 className="gray-box p-1 mb-0">{drugNotes}</h6>}
                    {extraDrugInfo && <h6 className="gray-box p-1">{extraDrugInfo}</h6>}

                    {otherIcdCodes && otherIcdCodes.length > 0 && (
                      <div className="other-icd-search-result">
                        <div>
                          <b>Other Possible ICD Codes</b>
                        </div>
                        {otherIcdCodes.map(({ _id, icdCodeNum, icdCodeValue }) => (
                          <div key={_id}>
                            <CopyToClipboard text={icdCodeNum}>
                              <Badge style={{ marginTop: "2px" }} className="btn-light-gray">
                                {icdCodeNum}
                              </Badge>
                            </CopyToClipboard>{" "}
                            <i>{icdCodeValue}</i>
                          </div>
                        ))}
                      </div>
                    )}
                    <DrugEditModal
                      editDrug={{
                        _id,
                        brandName,
                        genericName,
                        drugNotes,
                        primaryIcdCode,
                        otherIcdCodes,
                        drugClass,
                        drugSchedule,
                        extraDrugInfo,
                      }}
                      key={_id}
                    />
                  </Card>
                </Col>
              )
            )}
        </Row>
      </div>
    );
  };

  render() {
    const { searchResults } = this.props.drug;

    return (
      <div>
        <Card className="py-2 px-3">
          <CardTitle className="text-center mb-0">
            <span className="cursor-pointer rounded px-1" onClick={this.resetSearch}>
              DRUG <strong>SEARCH</strong>
            </span>
          </CardTitle>
          <Form onSubmit={this.handleSubmit} id="course-drug">
            <Input
              placeholder="Enter drug name"
              autoComplete="off"
              value={this.state.searchTerm}
              onChange={this.handleChange}
              className="box-shadow-0"
            />
          </Form>
          {this.checkIfResultExists(searchResults)}
        </Card>
      </div>
    );
  }
}

DrugSearch.propTypes = {
  searchDrugs: PropTypes.func.isRequired,
  drug: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  drug: state.drug,
});

export default connect(mapStateToProps, { searchDrugs })(DrugSearch);

// export default DrugSearch;
