import React, { Component } from "react";
import { Button, ButtonGroup, Input, Collapse } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deletePatient } from "../../../actions/patientActions";
// import moment from "moment";

class PatientDelete extends Component {
  state = {
    confirmDelete: "",
    isOpen: false,
    // unlockDeleteButton: "",
  };

  handleChangeTrimmed = (e) => {
    this.setState({ [e.target.name]: e.target.value.trim() });
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen, confirmDelete: "" });
  };

  handleDeleteClick = (id) => {
    const user = {
      userName: this.props.auth.name,
      userId: this.props.auth._id,
    };
    this.props.deletePatient(id, user);
  };

  render() {
    const patient = this.props.editPatient;
    const { isOpen, confirmDelete } = this.state;

    return (
      <div className="p-1 mt-2 text-center border-dashed-lighter bg-light rounded-8">
        <Button size="sm" color="danger" onClick={this.toggle} className="mb-2 px-1  opacity-4">
          {isOpen ? "Close Delete Menu" : "Show Delete Menu"}
          {/*{isOpen ? "▴ Close Delete Menu ▴" : "▾ Show Delete Menu ▾"}*/}
        </Button>

        <Collapse isOpen={isOpen}>
          <strong>
            Are you sure you want to <span style={{ color: "red" }}>PERMANENTLY</span> delete this patient?
            <div style={{ color: "#ab0002" }} className="mt-2">
              You can not undo this.
            </div>
          </strong>
          <div className="my-2">
            Type the word <span className="text-danger fw-bold">"DELETE"</span> in capital letters to unlock
            the button
          </div>
          <Input
            name="confirmDelete"
            value={confirmDelete}
            onChange={this.handleChangeTrimmed}
            autoComplete="off"
            className="text-center fw-bold bg-light text-danger"
          />
          <ButtonGroup>
            <Button onClick={this.toggle} className="btn btn-primary line-height-1 py-1">
              Cancel
            </Button>
            <Button
              onClick={this.handleDeleteClick.bind(this, patient._id)}
              className="btn btn-danger w-100 line-height-1 py-1"
              disabled={this.state.confirmDelete !== "DELETE"}
            >
              Delete Patient
            </Button>
          </ButtonGroup>
        </Collapse>
      </div>
    );
  }
}

PatientDelete.propTypes = {
  patient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
  auth: state.auth,
});

export default connect(mapStateToProps, { deletePatient })(PatientDelete);

// {/*<div className="text-center text-muted">Type "unlock" below to reveal the delete menu</div>*/}
// {/*<Input*/}
// {/*  name="unlockDeleteButton"*/}
// {/*  placeholder="type unlock here"*/}
// {/*  value={unlockDeleteButton}*/}
// {/*  onChange={this.handleChangeTrimmed}*/}
// {/*  className="text-center"*/}
// {/*/>*/}
//
// {/*<Collapse isOpen={unlockDeleteButton === "unlock"}>*/}
