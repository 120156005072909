import React, { useCallback, useEffect, useState } from "react";
import { addCategoryNote, getNotes } from "../../actions/noteActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Card, Col, Row, Spinner } from "reactstrap";
import AddChildNote from "./AddChildNote";
import NoteChildItem from "./NoteChildItem";
import NoteEditParent from "./NoteEditParent";

const Notes = (props) => {
  const dispatch = useDispatch();
  const _auth = useSelector((state) => state.auth);
  const _note = useSelector((state) => state.note);
  const {
    formState: { errors },
  } = useForm({
    defaultValues: { text: "", textEdit: "" },
  });

  useEffect(() => {}, [_note]);

  const bundledCategory = (filteredNotes) => {
    const categoryObject = filteredNotes.reduce(function (r, a) {
      r[a.category] = r[a.category] || [];
      r[a.category].push(a);
      return r;
    }, Object.create(null));

    let array = [];
    Object.keys(categoryObject).forEach((key) => {
      array.push(categoryObject[key]);
    });

    const sortArrayBySub = (array) =>
      array.sort((a, b) => a.subcategory.toLowerCase().charCodeAt(0) - b.subcategory.toLowerCase().charCodeAt(0));

    const sortedArray = (array) =>
      array.sort((a, b) => parseFloat(a.subcategoryOrder) - parseFloat(b.subcategoryOrder));

    // sorts by first letter - possibly DEPRECATED 6-2-24 after updates
    const sortByText = (notesArray) => {
      return notesArray.sort((a, b) => a.text.localeCompare(b.text));
    };

    // Custom sort function to move checked notes to the bottom and sort by text alphabetically
    const customSort = (notesArray, category) => {
      return notesArray.sort((a, b) => {
        // Sort alphabetically by text
        return a.text.localeCompare(b.text);
      });
    };

    return array.map((item, i) => (
      <Col
        sm={6}
        md={6}
        lg={4}
        xl={3}
        key={"main-cat-" + i}
        className={` ${item[0].category !== "General" ? `order-${item[0].categoryOrder}` : "order-first"}`}
      >
        {sortedArray(sortArrayBySub(item)).map((note, i) => (
          <Card
            key={i + note._id + "jo"}
            className={`box-shadow-1 border-dark border-opacity-25 mx-1 my-2 ${
              note.category === "General" ? "yellow-notes" : "bg-light border"
            }`}
          >
            <NoteEditParent note={note} />
            <AddChildNote noteProp={note} />
            <Card className={`p-0 m-0 ${note.category === "General" ? "yellow-notes" : "bg-light"}`}>
              {customSort(note.notes, note.category).map((_note, i) => (
                <NoteChildItem key={"subnote-" + _note._id} specificNote={_note} category={note.category} />
              ))}
            </Card>
          </Card>
        ))}
      </Col>
    ));
  };

  return (
    <>
      {!_note.loading ? (
        <Row className="gy-0 gx-1 justify-content-center overflow-y-auto max-height-70">
          {_note.notes && <>{bundledCategory(_note.notes.filter((note) => note.category))}</>}
        </Row>
      ) : (
        <Row className="g-2 flex-row d-flex">
          <div className="d-flex border opacity-2 p-2 rounded-8 vh-25">
            <Spinner size="sm" className="m-auto opacity-2" color="dark" />
          </div>
        </Row>
      )}
    </>
  );
};

export default connect(null, null)(Notes);
