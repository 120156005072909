export const FETCH_USER = "fetch_user";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const USERS_LOADING = "USERS_LOADING";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_USER_SORTING_PREFERENCE = "SET_USER_SORTING_PREFERENCE";
export const GET_USER_SORTING_PREFERENCE = "GET_USER_SORTING_PREFERENCE";

export const GET_PATIENTS = "GET_PATIENTS";
export const ADD_PATIENT = "ADD_PATIENT";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const UPDATE_PATIENT_NOTE = "UPDATE_PATIENT_NOTE";
export const PATIENTS_LOADING = "PATIENTS_LOADING";
export const SEARCH_PATIENTS = "SEARCH_PATIENTS";
export const VIEW_PATIENT = "VIEW_PATIENT";
export const ADD_PATIENT_REFILL = "ADD_PATIENT_REFILL";
export const DELETE_PATIENT_REFILL = "DELETE_PATIENT_REFILL";
export const UPDATE_PATIENT_REFILL = "UPDATE_PATIENT_REFILL";
export const UPDATE_MANY_PATIENT_REFILLS = "UPDATE_MANY_PATIENT_REFILLS";
export const SET_CURRENT_PAGINATION_PAGE = "SET_CURRENT_PAGINATION_PAGE";
export const FILTER_PATIENTS_BY = "FILTER_PATIENTS_BY";
export const CLEAR_PATIENT_ERROR = "CLEAR_PATIENT_ERROR";
export const CLEAR_PATIENT_RESPONSE = "CLEAR_PATIENT_RESPONSE";
export const UPDATE_PATIENTS_PER_PAGE = "PATIENTS_PER_PAGE";
export const UPDATE_REFILLS_CHECKED_SECONDARY = "UPDATE_REFILLS_CHECKED_SECONDARY";
export const ADD_REFILL_CHECKED_DATE = "ADD_REFILL_CHECKED_DATE";
export const GET_REFILLS = "GET_REFILLS";
export const GET_REFILLS_BY_RANGE = "GET_REFILLS_BY_RANGE";
export const SET_REFILLS_DATE_RANGE = "SET_REFILLS_DATE_RANGE";
export const UPDATE_PATIENT_STATUS = "  UPDATE_PATIENT_STATUS";
export const UPDATE_USER_PREFERENCES = "  UPDATE_USER_PREFERENCES";

export const FIND_FAMILY = "FIND_FAMILY";
export const ADD_PARTNER = "ADD_PARTNER";
export const REMOVE_PARTNERS = "REMOVE_PARTNERS";
export const UPDATE_FAMILY = "UPDATE_FAMILY";
export const GET_FAMILY = "GET_FAMILY";

export const GET_NOTES = "GET_NOTES";
export const ADD_NOTE = "ADD_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const NOTES_LOADING = "NOTES_LOADING";
export const CLEAR_NOTE_RESPONSE = "NOTE_RESPONSE";
export const NOTE_ERROR = "NOTE_ERROR";

export const GET_DRUGS = "GET_DRUGS";
export const ADD_DRUG = "ADD_DRUG";
export const DELETE_DRUG = "DELETE_DRUG";
export const UPDATE_DRUG = "UPDATE_DRUG";
export const DRUGS_LOADING = "DRUGS_LOADING";
export const SEARCH_DRUGS = "SEARCH_DRUGS";

export const GET_ICD_CODES = "GET_ICD_CODES";
export const ADD_ICD_CODE = "ADD_ICD_CODE";
export const DELETE_ICD_CODE = "DELETE_ICD_CODE";
export const UPDATE_ICD_CODE = "UPDATE_ICD_CODE";
export const ICD_CODES_LOADING = "ICD_CODES_LOADING";
export const SEARCH_ICD_CODES = "SEARCH_ICD_CODES";
