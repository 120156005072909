import React, { Component, useState, useEffect } from "react";
import { Card, Container, Button, Row, Col, Badge, Spinner } from "reactstrap";
import { useSelector, useDispatch, connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import { deletePatient, viewPatient, updatePatient, deletePatientRefill } from "../../actions/patientActions";
import PatientEditModal from "./PatientEdit/PatientEditModal";
import GoBack from "../../GoBack";
import RefillAdd from "./Refills/RefillAdd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Helmet } from "react-helmet";
import FamilyLink from "./Family/FamilyLink";
import { sortBy } from "lodash";
import { formatDate } from "../Utilities/CalculateDate";
import PatientNote from "./PatientEdit/PatientNote";
import NotAuthorized from "../NotAuthorized";
import SecondaryRefillsCheckedItem from "./PatientEdit/SecondaryRefillsCheckedItem";
import RefillItem from "./Refills/RefillItem";

const PatientDetails = () => {
  const [showArchived, setShowArchived] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const patient = useSelector((state) => state.patient);
  const params = useParams();
  // console.log("lovely " + JSON.stringify(params));

  useEffect(() => {
    // console.log("hi " + params);
    dispatch(viewPatient(params.id));
    setLoading(false);
    // dispatch(actions....));
  }, [loading, params.id]);

  const checkSex = (sex) => {
    switch (sex) {
      case "male":
        return "MALE";
      case "female":
        return "FEMALE";
      case "unknown":
        return "Unknown Gender";
      default:
        return null;
    }
  };

  const toggleShowArchived = () => {
    // this.setState({ showArchived: !this.state.showArchived });
    setShowArchived(!showArchived);
  };

  // Utility function for formatting phone numbers
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const loaded = (patient) => {
    if (patient.firstName === "Patient Does Not Exist") {
      return (
        <div>
          <b>Patient does not exist</b>
        </div>
      );
    }

    return (
      <div className="px-2 pt-1">
        {/*----HELMET LOGIC FOR BROWSER HEADER----*/}
        {patient.firstName && patient.lastName && (
          <>
            <Helmet defer={false}>
              <title>
                {patient.lastName}, {patient.firstName}
              </title>
            </Helmet>
          </>
        )}

        <Card className="px-4 py-3" style={{ backgroundColor: !patient.active && "rgba(255,94,94,0.13)" }}>
          {!patient.active && (
            <>
              <div className="badge badge-danger text-uppercase p-2 fs-lg fw-normal text-wrap">
                <h3>
                  This patient is {patient.deceased ? "deceased" : "marked as inactive"}{" "}
                  {patient.deceasedDate && <div>{formatDate(patient.deceasedDate)}</div>}
                </h3>

                {patient.patientLeft && (
                  <div className="opacity-4 mb-1">
                    {patient.patientLeft.boolean && "Left the pharmacy"}{" "}
                    {patient.patientLeft.reason && <div> Reason: {patient.patientLeft.reason}</div>}
                    {patient.patientLeft.date && <div> Date Left: {formatDate(patient.patientLeft.date)}</div>}
                  </div>
                )}
                {patient.notWorthBilling && (
                  <div className="opacity-4 mb-1">
                    {patient.notWorthBilling.boolean && "Not worth billing"}{" "}
                    {patient.notWorthBilling.reason && <div> Reason: {patient.notWorthBilling.reason}</div>}
                    {patient.notWorthBilling.date && <div> Since: {formatDate(patient.notWorthBilling.date)}</div>}
                  </div>
                )}
              </div>
              <br />
            </>
          )}

          <Row>
            <Col sm={12} className="pt-0 pb-2 px-2">
              {patient.firstName && (
                <CopyToClipboard text={patient.lastName + ", " + patient.firstName}>
                  <span className="fw-bold fs-xl d-flex align-items-center">
                    {patient.lastName.toUpperCase()}, {patient.firstName.toUpperCase()}
                    {patient.rxCount && (
                      <Badge color="dark" pill className="fs-xs ms-1 opacity-3">
                        {patient.rxCount}
                      </Badge>
                    )}
                  </span>
                </CopyToClipboard>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={7} className="p-0">
              {/*-------------ICONS SECTION-----------*/}
              {/*-------------ICONS SECTION-----------*/}
              {/*-------------ICONS SECTION-----------*/}
              <div className="border-dashed-lighter p-2 mx-1 bg-white rounded-8 h-100">
                <div className="d-inline-flex align-items-center opacity-3 my-0 ml-n1 fs-sm text-muted">
                  {!!patient.secondaryPatient && (
                    <Badge color="info" className="fs-sm opacity-3 fw-normal pb-1">
                      Secondary Patient
                    </Badge>
                  )}

                  {patient.isDelivery && <i className="material-icons me-1 text-success">local_shipping</i>}
                  {patient.hasControls && <i className="material-icons me-1 text-danger">copyright</i>}
                  {patient.partner && <i className="material-icons me-1">wc</i>}
                  {patient.mirixa && <i className="material-icons me-1">museum</i>}
                  {patient.sex && <span className="border rounded px-1">{checkSex(patient.sex)}</span>}
                  {patient.dateOfBirth && (
                    <>
                      <span className="border rounded px-1">
                        {moment(patient.dateOfBirth, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY")}
                      </span>
                      <span className="opacity-2 ms-1">
                        {moment().diff(moment(patient.dateOfBirth, "YYYY-MM-DDTHH:mm:ss"), "years")}
                      </span>
                    </>
                  )}
                  {patient.groupNumber && (
                    <span className="border border-info-subtle rounded px-1 ms-3">{patient.groupNumber}</span>
                  )}
                </div>

                {patient.primaryPhoneNumber && (
                  <div className="rounded px-1 my-2 box-shadow-1">
                    <span>
                      <CopyToClipboard text={patient.primaryPhoneNumber}>
                        <span className="custom-link-hover">
                          <i className="material-icons fs-sm">call</i> {formatPhoneNumber(patient.primaryPhoneNumber)}
                        </span>
                      </CopyToClipboard>
                    </span>
                    <span className="opacity-3 fs-sm">{patient.primaryPhoneNote}</span>
                  </div>
                )}
                {patient.secondaryPhoneNumber && (
                  <div className="rounded px-1 my-2 box-shadow-1">
                    <CopyToClipboard text={patient.secondaryPhoneNumber}>
                      <span className="custom-link-hover">
                        <i className="material-icons fs-sm">call</i> {formatPhoneNumber(patient.secondaryPhoneNumber)}
                      </span>
                    </CopyToClipboard>
                    <span className="opacity-3 fs-sm">{patient.secondaryPhoneNote}</span>
                  </div>
                )}

                {/*<div className="position-absolute bottom-0">*/}
                <PatientEditModal editPatient={patient} key={patient._id} />
                {/*</div>*/}
              </div>
            </Col>

            {/*-------------PARTNER LINK SECTION-----------*/}
            {/*-------------PARTNER LINK SECTION-----------*/}
            {/*-------------PARTNER LINK SECTION-----------*/}
            <Col md={4} sm={5} className="p-0">
              <div className="border-dashed-lighter h-100 p-2 mx-1 bg-white rounded-8">
                {/*<div className="mb-0 opacity-2">Family</div>*/}
                {/*<div className="d-flex align-items-center">*/}

                <FamilyLink
                  partner={patient.partner}
                  linkOrEdit={patient.partner ? "Change" : "Link Family"}
                  patientId={patient._id}
                />
                {/*</div>*/}
              </div>
            </Col>

            {/*-------------NOTE SECTION-----------*/}
            {/*-------------NOTE SECTION-----------*/}
            {/*-------------NOTE SECTION-----------*/}
            <Col md={4} sm={6} className="p-0">
              <div className="border-dashed-lighter h-100 p-2 mx-1 bg-white rounded-8">
                <PatientNote
                  patientId={patient._id}
                  note={patient.note}
                  noteHistory={patient.noteHistory}
                  user={auth.user}
                  showNoteTitle={true}
                  showPastEdits={true}
                  noteType={"first"}
                />
              </div>
            </Col>
          </Row>
          {/*<PatientEdit editPatient={patient} key={patient._id} />*/}
        </Card>

        <Card body>
          <Row>
            {patient.lastBilledRefill && (
              <Col className="center-padded-6-box">
                <div className="gray-box padding-8">
                  <span style={{ opacity: "0.5" }}>Last Billed Refill </span>{" "}
                  {moment(patient.lastBilledRefill.refillDate, "YYYY-MM-DDTHH:mm:ss").format("MMM Do YYYY")} by{" "}
                  {patient.lastBilledRefill.userWhoBilled}{" "}
                  <span style={{ opacity: "0.5" }}>
                    {patient.lastBilledRefill.dateAdded && moment(patient.lastBilledRefill.dateAdded).fromNow()}
                  </span>{" "}
                </div>
              </Col>
            )}
          </Row>

          {/*------SECONDARY PATIENT-------*/}
          {/*------SECONDARY PATIENT-------*/}
          {/*------SECONDARY PATIENT-------*/}
          {(patient.datesRefillsLastChecked.length > 0 || !!patient.secondaryPatient) && (
            <Row>
              <Col className="p-2">
                <div className="gray-box archived-refill-box text-center" style={{ backgroundColor: "#f8ffff" }}>
                  <h4>
                    <b>{!!patient.secondaryPatient ? "" : "Was Previously A "}Secondary Patient</b>
                  </h4>

                  <SecondaryRefillsCheckedItem
                    datesRefillsLastChecked={patient.datesRefillsLastChecked}
                    patientId={patient._id}
                  />
                </div>
              </Col>
            </Row>
          )}

          {/*-------------REFILLS ADD-----------*/}
          <Row>
            <Col md={5} sm={6} xs={12} className="p-2">
              <RefillAdd editPatient={patient} key={patient._id + 1} />
            </Col>

            {/*-------------REFILLS SECTION-----------*/}
            {/*-------------REFILLS SECTION-----------*/}
            {/*-------------REFILLS SECTION-----------*/}
            <Col md={7} sm={6} xs={12} className="p-2">
              <div className="gray-box text-center">
                <div className="pb-2">
                  <strong>{patient.firstName.toUpperCase()}'S REFILLS</strong>
                </div>{" "}
                {patient.refills.length > 0 ? (
                  <Row className="justify-content-center overflow-y-auto max-height-25">
                    {patient.refills.map((refill) => (
                      <RefillItem
                        key={refill._id}
                        whichComponent={"PatDet-"}
                        refillProp={refill}
                        patient={{ firstName: patient.firstName, lastName: patient.lastName, patientId: patient._id }}
                        showDate
                        showEditModal
                        className="rounded-8 box-shadow-05 m-1 bg-white"
                        colSizes={[12, 12, 12]}
                      />
                    ))}
                  </Row>
                ) : (
                  <span className="opacity-1">
                    <i>{patient.firstName} has no refills</i>
                  </span>
                )}
              </div>
            </Col>
          </Row>

          {/*-------------ARCHIVED REFILLS SECTION-----------*/}
          {/*-------------ARCHIVED REFILLS SECTION-----------*/}
          {/*-------------ARCHIVED REFILLS SECTION-----------*/}
          {patient.archivedRefills && (auth.role === "admin" || auth.role === "superadmin") && (
            <Row>
              <Col xs={12} className="p-2">
                <div className="gray-box p-3 text-center archived-refill-box opacity-3">
                  {showArchived ? (
                    <>
                      <Button color="light" onClick={toggleShowArchived} className="opacity-3 border rounded mt-0 mb-2">
                        ▴ Close Archived Refills ▴
                      </Button>
                      <Row className="justify-content-center overflow-y-auto max-height-40">
                        {sortBy(patient.archivedRefills, "dateAdded")
                          .reverse()
                          .map((archivedRefill) => (
                            <RefillItem
                              key={archivedRefill._id}
                              whichComponent={"PatDet-"}
                              refillProp={archivedRefill}
                              patient={{
                                firstName: patient.firstName,
                                lastName: patient.lastName,
                                patientId: patient._id,
                              }}
                              showDate
                              showLastBilled
                              className="rounded-8 box-shadow-05 bg-white col-4 border"
                              notesStyle={{ minHeight: "8vh", fontSize: "0.8rem" }}
                            />
                          ))}
                      </Row>
                    </>
                  ) : (
                    <>
                      {patient.archivedRefills.length > 0 ? (
                        <Button color="dark" onClick={toggleShowArchived} className="mt-0 opacity-4">
                          ▾ Show {patient.archivedRefills && patient.archivedRefills.length} Archived Refills ▾
                        </Button>
                      ) : (
                        <div className="opacity-1">
                          <i>{patient.firstName} has no archived refills</i>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          )}
        </Card>

        {patient.dateAdded && (
          <div className="p-1 text-end">
            <span className="opacity-1 border rounded-8 p-2">
              Patient Added:{" "}
              {moment(patient.dateAdded, "YYYY-MM-DDTHH:mm:ss").format(
                // "MMM Do YYYY"
                "M-DD-YYYY",
              )}{" "}
            </span>
            {moment(patient.dateAdded).isAfter(moment().subtract(1, "months")) && (
              <Badge color="success">NEW PATIENT</Badge>
            )}
          </div>
        )}
        {/*<PatientDelete editPatient={patient} />*/}
      </div>
    );
  };

  const notLoaded = () => {
    return (
      <Card body className="text-center mx-2">
        <div className="loading-box align-items-center">
          <Spinner className="m-auto" color="light" />
        </div>
      </Card>
    );
  };

  const isLoggedIn = () => {
    const { currentPatient } = patient;

    // console.log("current: " + JSON.stringify(patient));
    return (
      <div>
        <Row>
          <Col xs="12">
            <div>{currentPatient.firstName && loading === false ? loaded(currentPatient) : notLoaded()}</div>
            <div>
              <GoBack />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const renderCheck = () => {
    // const auth = this.auth;

    // console.log("in render check");
    switch (auth) {
      case null:
        return;
      case false:
        return (
          <div style={{ textAlign: "center" }}>
            <h5>Please login to access this page.</h5>
          </div>
        );
      default:
        if (auth && auth.role) {
          return <div>{isLoggedIn()}</div>;
        } else {
          return <NotAuthorized />;
        }
    }
  };

  // render() {
  return (
    <Container fluid className="p-0">
      <div>{renderCheck()}</div>
    </Container>
  );
  // }
};

// PatientDetails.propTypes = {
//   patient: PropTypes.object.isRequired,
// };

// const mapStateToProps = (state) => ({
//   patient: state.patient,
//   auth: state.auth,
// });

// export default connect(mapStateToProps, {
//   viewPatient,
//   deletePatient,
//   updatePatient,
//   deletePatientRefill,
// })(PatientDetails);

export default connect(null, null)(PatientDetails);

// ------ORIGINAL WORKING
// PatientDetails.propTypes = {
//   getPatients: PropTypes.func.isRequired,
//   patient: PropTypes.object.isRequired
// };
//
// const mapStateToProps = state => ({
//   patient: state.patient
// });
//
// export default connect(
//   mapStateToProps,
//   { getPatients, deletePatient, updatePatient }
// )(PatientDetails);
