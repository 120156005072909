import { Card, Row, Spinner } from "reactstrap";
import SevenDayTable from "./RefillSevenDayTable";
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

class RefillUpcomingCalendar extends Component {
  refillsByDay = (weeks) => {
    const activePatients = this.props.patient.patients.filter((patient) => patient.active);
    const patientsWithRefills = activePatients.filter((patient) => patient.refills.length > 0);
    const matchingRefills = [];

    patientsWithRefills.forEach((patient) => {
      patient.refills.forEach((refill, i) => {
        if (
          moment(refill.refillDate, "YYYY-MM-DDTHH:mm:ss").isBetween(
            moment().add(weeks, "weeks").startOf("week"),
            moment().add(weeks, "weeks").endOf("week"),
            "day",
          )
        ) {
          matchingRefills.push({
            ...refill,
            indexArr: i,
            rxCount: patient.rxCount || 0, // Include the rxCount total for each patient
          });
        }
      });
    });

    return matchingRefills;
  };

  render() {
    const { loading } = this.props.patient;

    return (
      <div>
        <Card
          className="text-center py-2 px-1 opacity-4 text-center mt-1 bg-white box-shadow-1"
          // style={{ backgroundColor: "#eaf1fe", }}
        >
          {loading && (
            <div className="d-flex mx-1 bg-gray-transparent opacity-2 p-3 rounded-8" style={{ height: "305px" }}>
              <Spinner className="m-auto opacity-2" color="dark" />
            </div>
          )}

          {this.refillsByDay() && !loading && (
            // <Row noGutters={true}>
            <>
              <Row className="py-0 mt-0 px-3">
                <SevenDayTable
                  refillsByDay={this.refillsByDay(1)}
                  week={1}
                  showWeek={true}
                  sendDate={this.props.sendDateToRefillSearch}
                />
              </Row>
              <Row className="py-0 mt-n2 px-3">
                <SevenDayTable
                  refillsByDay={this.refillsByDay(2)}
                  week={2}
                  showWeek={true}
                  showDays={false}
                  sendDate={this.props.sendDateToRefillSearch}
                />
              </Row>
              <Row className="py-0 mt-n2 px-3">
                <SevenDayTable
                  refillsByDay={this.refillsByDay(3)}
                  week={3}
                  showWeek={true}
                  showDays={false}
                  sendDate={this.props.sendDateToRefillSearch}
                />
              </Row>
              <Row className="py-0 mt-n2 px-3">
                <SevenDayTable
                  refillsByDay={this.refillsByDay(4)}
                  week={4}
                  showWeek={true}
                  showDays={false}
                  sendDate={this.props.sendDateToRefillSearch}
                />
              </Row>
              <Row className="py-0 mt-n2 px-3">
                <SevenDayTable
                  refillsByDay={this.refillsByDay(5)}
                  week={5}
                  showWeek={true}
                  showDays={false}
                  sendDate={this.props.sendDateToRefillSearch}
                />
              </Row>
            </>
            // </Row>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  patient: state.patient,
});

export default connect(mapStateToProps)(RefillUpcomingCalendar);
