import React from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

class PatientsFilterDropdown extends React.Component {
  state = { dropdownOpenFilter: false };

  togglePatientsFilter = () => {
    this.setState({ dropdownOpenFilter: !this.state.dropdownOpenFilter });
  };

  render() {
    const { filterPatientsBy, selectDropPatientsFilter } = this.props;
    return (
      <Dropdown isOpen={this.state.dropdownOpenFilter} toggle={this.togglePatientsFilter}>
        <DropdownToggle className="text-wrap line-height-1 py-1 px-2" caret>
          {filterPatientsBy}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => selectDropPatientsFilter("Currently Active With Refills")}>
            Currently Active With Refills
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => selectDropPatientsFilter("Primary Patients")}>Primary Patients</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsFilter("Secondary Patients")}>Secondary Patients</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => selectDropPatientsFilter("All Active Patients")}>
            All Active Patients
          </DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsFilter("Inactive Patients")}>Inactive Patients</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => selectDropPatientsFilter("Billed Past Hour")}>Billed Past Hour</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsFilter("Billed Today")}>Billed Today</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsFilter("Billed Yesterday")}>Billed Yesterday</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsFilter("Billed This Week")}>Billed This Week</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => selectDropPatientsFilter("No Refills")}>No Refills</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsFilter("Many Refills")}>Many Refills</DropdownItem>
          <DropdownItem divider />
          <DropdownItem className="text-danger" onClick={() => selectDropPatientsFilter("Has Controls")}>
            Has Controls
          </DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsFilter("Mirixa")}>Mirixa</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsFilter("Deceased")}>Deceased</DropdownItem>
          <DropdownItem onClick={() => selectDropPatientsFilter("Extra Archived Refills")}>
            Extra Archived Refills
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => selectDropPatientsFilter("All Patients")}>All Patients</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

PatientsFilterDropdown.propTypes = {
  filterPatientsBy: PropTypes.string.isRequired,
  selectDropPatientsFilter: PropTypes.func.isRequired,
};

export default PatientsFilterDropdown;
