import {
  FETCH_USER,
  SET_USER_SORTING_PREFERENCE,
  GET_USER_SORTING_PREFERENCE,
  UPDATE_USER_PREFERENCES,
} from "../actions/types";

const initialState = null;

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return action.payload || false;
    case SET_USER_SORTING_PREFERENCE:
      return {
        ...state,
        sortColumn: action.payload.sortColumn,
        sortOrder: action.payload.sortOrder,
      };
    case GET_USER_SORTING_PREFERENCE:
      return {
        ...state,
        sortColumn: action.payload.sortColumn,
        sortOrder: action.payload.sortOrder,
      };
    // case UPDATE_USER_PREFERENCES:
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    default:
      return state;
  }
}
