import axios from "axios";
import {
  GET_PATIENTS,
  ADD_PATIENT,
  DELETE_PATIENT,
  UPDATE_PATIENT,
  PATIENTS_LOADING,
  SEARCH_PATIENTS,
  VIEW_PATIENT,
  ADD_PATIENT_REFILL,
  DELETE_PATIENT_REFILL,
  UPDATE_PATIENT_REFILL,
  UPDATE_MANY_PATIENT_REFILLS,
  SET_CURRENT_PAGINATION_PAGE,
  FILTER_PATIENTS_BY,
  CLEAR_PATIENT_ERROR,
  CLEAR_PATIENT_RESPONSE,
  UPDATE_PATIENTS_PER_PAGE,
  FIND_FAMILY,
  ADD_PARTNER,
  REMOVE_PARTNERS,
  UPDATE_FAMILY,
  GET_FAMILY,
  UPDATE_PATIENT_NOTE,
  GET_REFILLS,
  UPDATE_REFILLS_CHECKED_SECONDARY,
  ADD_REFILL_CHECKED_DATE,
  GET_REFILLS_BY_RANGE,
  SET_REFILLS_DATE_RANGE,
  UPDATE_PATIENT_STATUS,
} from "./types";
import moment from "moment";

export const searchPatients = (query) => (dispatch) => {
  axios.get("/api/patients/search?q=" + query).then((res) =>
    dispatch({
      type: SEARCH_PATIENTS,
      payload: res.data,
    }),
  );
};

export const getPatients = () => (dispatch) => {
  dispatch(setPatientsLoading());
  axios.get("/api/patients").then((res) =>
    dispatch({
      type: GET_PATIENTS,
      payload: res.data,
    }),
  );
};

export const viewPatient = (id) => (dispatch, getState) => {
  let stateArr = getState().patient.patients;
  // console.log("IDDD: " + id);

  // CHECKING IF GET PATIENTS STATE EXISTS IN ORDER TO FETCH A SERVER REQUEST
  if (!Array.isArray(stateArr) || !stateArr.length) {
    axios.get(`/api/patients/${id}`).then((res) =>
      dispatch({
        type: VIEW_PATIENT,
        payload: res.data,
      }),
    );
  }
  dispatch({
    type: VIEW_PATIENT,
    payload: id,
  });
};

export const addPatient = (patient) => (dispatch) => {
  axios.post("/api/patients", patient).then((res) =>
    dispatch({
      type: ADD_PATIENT,
      payload: res.data,
    }),
  );
};

export const addPatientOverride = (patient) => (dispatch) => {
  axios.post("/api/patients/override", patient).then((res) =>
    dispatch({
      type: ADD_PATIENT,
      payload: res.data,
    }),
  );
};

export const deletePatient = (id, user) => (dispatch) => {
  axios.put(`/api/patients/${id}/delete-patient`, user).then((res) =>
    dispatch({
      type: DELETE_PATIENT,
      payload: id,
    }),
  );
};

export const updatePatient = (id, patient) => (dispatch) => {
  axios.put(`/api/patients/${id}`, patient).then((res) =>
    dispatch({
      type: UPDATE_PATIENT,
      payload: res.data,
    }),
  );
};

export const updatePatientActiveStatus = (id, patient) => (dispatch) => {
  axios.put(`/api/patients/${id}/active`, patient).then((res) =>
    dispatch({
      type: UPDATE_PATIENT,
      payload: res.data,
    }),
  );
};

// color picker for patient status
export const updatePatientStatus = (id, statusData) => (dispatch) => {
  if (!id) {
    console.error("Patient ID is undefined");
    return;
  }
  axios.put(`/api/patients/${id}/status`, statusData).then((res) =>
    dispatch({
      type: UPDATE_PATIENT_STATUS,
      payload: res.data,
    }),
  );
};

// export const updatePatientNote = (id, note) => (dispatch) => {
//   axios.put(`/api/patients/${id}/note`, note).then((res) =>
//     dispatch({
//       type: UPDATE_PATIENT_NOTE,
//       payload: res.data,
//     })
//   );
// };

export const updatePatientNote = (id, note) => (dispatch) => {
  axios.put(`/api/patients/${id}/note`, note).then((res) =>
    dispatch({
      type: UPDATE_PATIENT_NOTE,
      payload: res.data,
    }),
  );
};

export const addPatientRefill = (id, refill) => (dispatch) => {
  axios.post(`/api/patients/${id}/addrefill`, refill).then((res) =>
    dispatch({
      type: ADD_PATIENT_REFILL,
      payload: res.data,
    }),
  );
};

export const updatePatientRefill = (patientId, refillId, updatedRefill) => (dispatch) => {
  axios.put(`/api/patients/${patientId}/updaterefill/${refillId}`, updatedRefill).then((res) =>
    dispatch({
      type: UPDATE_PATIENT_REFILL,
      payload: res.data,
    }),
  );
};

export const deletePatientRefill = (patientId, refillId) => (dispatch) => {
  axios.delete(`/api/patients/${patientId}/deleterefill/${refillId}`).then((res) =>
    dispatch({
      type: DELETE_PATIENT_REFILL,
      payload: res.data,
      // payload: {refillId: refillId, patientId: patientId}
    }),
  );
};

export const billPatientRefill = (patientId, refillId, refill) => (dispatch) => {
  if (moment(refill.refillDate).isSameOrBefore(moment().add(6, "weeks"))) {
    axios
      .delete(`/api/patients/${patientId}/deleterefill/${refillId}`)
      .then((res) =>
        dispatch({
          type: DELETE_PATIENT_REFILL,
          payload: res.data,
        }),
      )
      .then(() =>
        axios.post(`/api/patients/${patientId}/addbilledrefill`, refill).then((res) =>
          dispatch({
            type: ADD_PATIENT_REFILL,
            payload: res.data,
          }),
        ),
      );
    // .then(() => {
    //   // dispatch(getRefills())
    //   dispatch(getRefillsByRange());
    // });
  } else {
    console.log("in billPatientRefill... the refill is the same or before 6 weeks from now...");
  }
};

// Update multiple refills together by date
export const updateManyRefills = (refills, date) => (dispatch) => {
  axios
    .put(`/api/refills/update-many-refills/`, { refills: refills, date: date })

    .then((res) => {
      //TEMPORARY FIX (not correct because it updates ALL patients rather than updated ones)
      // dispatch(getPatients());
      // console.log(res.data);
      dispatch({
        type: UPDATE_MANY_PATIENT_REFILLS,
        payload: res.data,
      });
    });
};

// For setting PAGINATION page number
export const setCurrentPage = (pageNumber) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_PAGINATION_PAGE,
    payload: pageNumber,
  });
};

// For filtering patients in patients component
export const filterPatientsBy = (filterBy) => (dispatch) => {
  dispatch({
    type: FILTER_PATIENTS_BY,
    payload: filterBy,
  });
};

export const updatePatientsPerPage = (amount) => (dispatch) => {
  dispatch({
    type: UPDATE_PATIENTS_PER_PAGE,
    payload: amount,
  });
};

// Clear any errors throughout patient
export const clearPatientError = (error) => (dispatch) => {
  dispatch({
    type: CLEAR_PATIENT_ERROR,
    payload: {},
  });
};

export const clearPatientResponse = (res) => (dispatch) => {
  dispatch({
    type: CLEAR_PATIENT_RESPONSE,
    payload: {},
  });
};

/// --------- SECONDARY PATIENT ---------- ///
/// --------- SECONDARY PATIENT ---------- ///
/// --------- SECONDARY PATIENT ---------- ///
export const updateRefillCheckedDate = (patientId, dateId) => (dispatch) => {
  axios.put(`/api/patients/${patientId}/refills-checked/${dateId}`).then((res) =>
    dispatch({
      type: UPDATE_REFILLS_CHECKED_SECONDARY,
      payload: res.data,
      // payload: {refillId: refillId, patientId: patientId}
    }),
  );
};

export const addRefillCheckedDate = (id, dateChecked) => (dispatch) => {
  axios.post(`/api/patients/${id}/add-refill-checked-date`, dateChecked).then((res) =>
    dispatch({
      type: ADD_REFILL_CHECKED_DATE,
      payload: res.data,
    }),
  );
};

// -----------GET REFILLS FOR FRONTEND------------ //
// -----------GET REFILLS FOR FRONTEND------------ //
// -----------GET REFILLS FOR FRONTEND------------ //
export const getRefills = (date) => (dispatch) => {
  dispatch({
    type: GET_REFILLS,
    // payload: patients,
    date: moment(date).format("YYYY-MM-DD"),
  });
};

// -----------GET REFILLS BY RANGE FOR FRONTEND------------ //
// -----------GET REFILLS BY RANGE FOR FRONTEND------------ //
// -----------GET REFILLS BY RANGE FOR FRONTEND------------ //
export const getRefillsByRange = (dateStart, dateEnd) => (dispatch) => {
  // console.log("DATE: " + dateStart);
  dispatch({
    type: GET_REFILLS_BY_RANGE,
    // payload: patients,
    // dateStart: !!dateStart ? dateStart : moment(dateStart).subtract(1, "minutes").format("YYYY-MM-DD"),
    // dateEnd: !!dateEnd ? dateEnd : moment(dateEnd).add(10, "days").format("YYYY-MM-DD"),
    dateStart: dateStart,
    dateEnd: dateEnd,
  });
};

export const setRefillsDateRange = (dateStart, dateEnd) => (dispatch) => {
  // console.log("DATE: " + dateStart);
  dispatch({
    type: SET_REFILLS_DATE_RANGE,
    // payload: patients,
    // dateStart: !!dateStart ? dateStart : moment(dateStart).subtract(1, "minutes").format("YYYY-MM-DD"),
    // dateEnd: !!dateEnd ? dateEnd : moment(dateEnd).add(10, "days").format("YYYY-MM-DD"),
    dateStart: dateStart,
    dateEnd: dateEnd,
  });
};

// -----------PARTNER LINKING------------ //
// -----------PARTNER LINKING------------ //
// -----------PARTNER LINKING------------ //

// Find partner to link patient
export const findFamily = (patient) => (dispatch) => {
  // console.log("patient action " + patient.firstName);
  axios.post("/api/family", patient).then((res) =>
    dispatch({
      type: FIND_FAMILY,
      payload: res.data,
    }),
  );
};

// Add partners to patients (link them)
export const addPartner = (patientId, partnerId) => (dispatch) => {
  axios.put(`/api/patient/${patientId}/partner/${partnerId}`).then((res) =>
    dispatch({
      type: ADD_PARTNER,
      payload: res.data,
    }),
  );
};

// Remove partners from patients (unlink them)
export const removePartners = (patientId, partnerId) => (dispatch) => {
  axios.put(`/api/patient/${patientId}/partner/${partnerId}/unlink`).then((res) =>
    dispatch({
      type: REMOVE_PARTNERS,
      payload: res.data,
    }),
  );
};

// Get family members to show on front-end
export const getFamily = (patientId) => (dispatch) => {
  dispatch(setPatientsLoading());
  axios.get(`/api/patient/${patientId}/family`).then((res) =>
    dispatch({
      type: GET_FAMILY,
      payload: res.data,
    }),
  );
};

// Add family member to patient (link them)
export const updateFamily = (patientId, familyId, relationship, unlink) => (dispatch) => {
  axios
    .put(`/api/patient/${patientId}/family/${familyId}`, { relationship: relationship, unlink: unlink })
    .then((res) =>
      dispatch({
        type: UPDATE_FAMILY,
        payload: res.data,
      }),
    );
};

// TEMPORARY TO FIX refillNotes
// export const fixRefillNotes = () => dispatch => {
//   axios.get("/api/patients/fixrefills").then(res =>
//     dispatch({
//       type: GET_PATIENTS,
//       payload: res.data
//     })
//   );
// };

// export const removeNotes = () => dispatch => {
//   axios.get("/api/patients/removenotes").then(res =>
//     dispatch({
//       type: GET_PATIENTS,
//       payload: res.data
//     })
//   );
// };

export const setPatientsLoading = () => {
  return {
    type: PATIENTS_LOADING,
  };
};
