import React from "react";

const NotAuthorized = () => (
  <div style={{ textAlign: "center" }}>
    You are not authorized to access this page. Please contact the administrator
    for more info.
  </div>
);

export default NotAuthorized;
