import React, { Component } from "react";
import { ListGroupItem, Badge, Col, Row, Spinner } from "reactstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  getPatients,
  deletePatient,
  updatePatient,
  deletePatientRefill,
  addPatientRefill,
} from "../../../actions/patientActions";
import PropTypes from "prop-types";
import moment from "moment";
import RefillEditModal from "./RefillEditModal";
// import PartnerTooltip from "../Family/PartnerTooltip";
import { sortBy, orderBy } from "lodash";
import RefillItem from "./RefillItem";
// import RefillItem from "./RefillItem";

class DashboardRefillSectionItem extends Component {
  componentDidMount() {
    this.props.getPatients();
    this.interval = setInterval(() => this.props.getPatients(), 60000 * 5);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  checkDate = (refDate, reqSent, resRec) => {
    const today = moment();
    if (moment(refDate, "YYYY-MM-DDTHH:mm:ss").isSame(today, "day")) {
      if (reqSent && resRec) {
        return "bill-today-green";
      } else if (reqSent) {
        return "only-req-yellow";
      }
      return "";
    } else if (moment(refDate, "YYYY-MM-DDTHH:mm:ss").isBefore(today, "day")) {
      return "past-due-red";
    }
    return "";
  };

  billToday = (refDate) => {
    const today = moment();
    return moment(refDate, "YYYY-MM-DDTHH:mm:ss").isSame(today, "day");
  };

  billOverdue = (refDate) => {
    const today = moment();
    const threeMonthsAgo = moment().subtract(3, "months");
    // return moment(refDate, "YYYY-MM-DDTHH:mm:ss").isBefore(today, "day");
    return moment(refDate, "YYYY-MM-DDTHH:mm:ss").isBetween(threeMonthsAgo, today);
  };

  // checkIfPhone = (typeOfRequest) => {
  //   return typeOfRequest.includes("Phone") ? (
  //     <td style={{ padding: "0px" }}>
  //       <i className="material-icons">phone_in_talk</i>
  //     </td>
  //   ) : (
  //     <></>
  //   );
  // };

  smile = () => {
    return (
      <TransitionGroup className="rounded-8">
        <CSSTransition timeout={200} classNames="fade">
          <ListGroupItem style={{ textAlign: "center", opacity: "0.5" }}>
            <i className="material-icons">sentiment_satisfied_alt</i>
          </ListGroupItem>
        </CSSTransition>
      </TransitionGroup>
    );
  };

  render() {
    const { patients, loading } = this.props.patient;
    const activePatients = patients.filter((patient) => patient.active);
    const patientsWithRefills = activePatients.filter((patient) => patient.refills.length > 0);

    const todayRefills = [];
    const overdueRefills = [];
    patientsWithRefills.forEach((patient) => {
      patient.refills.forEach((refill, i) => {
        if (this.billToday(patient.refills[i].refillDate) === true) {
          todayRefills.push({
            ...patient.refills[i],
            ...{
              firstName: patient.firstName,
              lastName: patient.lastName,
              patientId: patient._id,
              mirixa: patient.mirixa,
              hasControls: patient.hasControls,
              isDelivery: patient.isDelivery,
              partner: patient.partner,
              indexArr: i,
            },
          });
        } else if (this.billOverdue(patient.refills[i].refillDate) === true) {
          overdueRefills.push({
            ...patient.refills[i],
            ...{
              firstName: patient.firstName,
              lastName: patient.lastName,
              patientId: patient._id,
              mirixa: patient.mirixa,
              hasControls: patient.hasControls,
              isDelivery: patient.isDelivery,
              partner: patient.partner,
              indexArr: i,
            },
          });
        }
      });
    });

    const checkIt = () => {
      const check = this.props.billBuddy;

      switch (check) {
        case "BILL_TODAY":
          return todayRefills;
        case "OVERDUE":
          return sortBy(overdueRefills, "refillDate").reverse();
        default:
          return [];
      }
    };

    const todayRefillCount = (when) => {
      if (when === "BILL_TODAY" && checkIt(this.props.billBuddy === "BILL_TODAY")) {
        const length = checkIt().length;
        if (length > 0) {
          return (
            <span className="text-center opacity-1 position-absolute top-0 mt-1">
              Refill Count: <b>{length}</b>
            </span>
          );
        }
      }

      if (when === "OVERDUE" && checkIt(this.props.billBuddy === "OVERDUE")) {
        const length = checkIt().length;
        if (length > 0) {
          return (
            <span className="text-center opacity-1 position-absolute text-danger top-0 mt-1">
              Overdue Count: <b>{length}</b>
            </span>
          );
        }
      }
    };

    const veryOverdue = (refillDate) => {
      return moment(refillDate).isBefore(moment().subtract(7, "days"));
    };

    return (
      <div>
        {(!Array.isArray(checkIt()) || !checkIt().length) && !loading && this.smile()}

        {loading && (
          <div className="d-flex border opacity-2 p-2 rounded-8" style={{ height: "55px" }}>
            <Spinner size="sm" className="m-auto opacity-2" color="dark" />
          </div>
        )}

        {this.props.billBuddy === "BILL_TODAY" && todayRefillCount("BILL_TODAY")}
        {this.props.billBuddy === "OVERDUE" && todayRefillCount("OVERDUE")}

        {/*<TransitionGroup className="patient-list max-height-scroll overflow-y-auto">*/}
        <TransitionGroup className="patient-list rounded-8 max-height-50 overflow-y-auto">
          {checkIt().map(
            (
              {
                refillDate,
                refillNotes,
                typeOfRequest,
                requestSent,
                responseReceived,
                temporary,
                firstName,
                lastName,
                mirixa,
                hasControls,
                isDelivery,
                partner,
                patientId,
                indexArr,
                _id,
              },
              indexFoundRefills
            ) => (
              <CSSTransition key={patientId + indexArr} timeout={300} classNames="fade">
                <ListGroupItem
                  className={`px-1 py-1 border-lighter ${this.checkDate(refillDate, requestSent, responseReceived)}`}
                  style={{ opacity: temporary ? "40%" : "" }}
                >
                  <Row className="mb-0 ms-auto opacity-3 g-0">
                    {!this.billToday(refillDate) && (
                      <span className="text-wrap">
                        <Badge
                          className={classNames("px-1 ", {
                            "bg-danger opacity-4": veryOverdue(refillDate),
                            "bg-light text-dark": !veryOverdue(refillDate),
                          })}
                        >
                          {moment(refillDate, "YYYY-MM-DDTHH:mm:ss").format("MMMM D")}
                        </Badge>
                        <Badge color={veryOverdue(refillDate) ? "danger" : "secondary"} className="px-1 opacity-2">
                          {/*{moment(refillDate, "YYYY-MM-DDTHH:mm:ss").fromNow(true)}*/}
                          {moment(refillDate, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Badge>
                      </span>
                    )}
                  </Row>
                  {/*<Row>*/}
                  {/*<Col xs={12}>*/}
                  {/*{temporary && (*/}
                  {/*  <Badge color="warning" className="d-block">*/}
                  {/*    TEMPORARY*/}
                  {/*  </Badge>*/}
                  {/*)}*/}
                  {/*<Link to={`/patients/${patientId}`} className="custom-link-hover">*/}
                  {/*  <strong*/}
                  {/*  // style={{*/}
                  {/*  //   borderBottom: !requestSent ? "3px dotted rgba(0,0,0,0.2)" : "",*/}
                  {/*  // }}*/}
                  {/*  >*/}
                  {/*    {lastName.toUpperCase() + ", " + firstName.toUpperCase()}{" "}*/}
                  {/*  </strong>*/}
                  {/*</Link>*/}

                  {/*<div>{refillNote} </div>*/}
                  {/*<div className="max-height-8 overflow-y-auto rounded-8">*/}
                  {/*  {refillNotes.length > 0 && (*/}
                  {/*    <div className="gray-box-small my-0 d-flex flex-column">*/}
                  {/*      {refillNotes.map(({ note, date, userName, important }, index) => (*/}
                  {/*        <div key={index} className={`gray-box-small my-0 ${important ? "important-note order-0" : "order-1"}`}>*/}
                  {/*          <Badge color="light" className="badge-em-opaque">*/}
                  {/*            {userName.slice(0, 2)} {moment(date).format("M/DD/YY")}{" "}*/}
                  {/*          </Badge>{" "}*/}
                  {/*          {note}*/}
                  {/*        </div>*/}
                  {/*      ))}*/}
                  {/*    </div>*/}
                  {/*  )}*/}
                  {/*</div>*/}

                  {/*<RefillEditModal*/}
                  {/*  patientName={lastName + ", " + firstName}*/}
                  {/*  editRefill={{*/}
                  {/*    refillDate: refillDate,*/}
                  {/*    // refillNote: refillNote,*/}
                  {/*    refillNotes: refillNotes,*/}
                  {/*    typeOfRequest: typeOfRequest,*/}
                  {/*    requestSent: requestSent,*/}
                  {/*    responseReceived: responseReceived,*/}
                  {/*    temporary: temporary,*/}
                  {/*    _id: _id,*/}
                  {/*  }}*/}
                  {/*  patientId={patientId}*/}
                  {/*  whichComponent="RefillDashItem"*/}
                  {/*  partnerInfo={partner}*/}
                  {/*/>*/}

                  <RefillItem
                    refillProp={{
                      refillDate,
                      refillNotes,
                      typeOfRequest,
                      requestSent,
                      responseReceived,
                      temporary,
                      firstName,
                      lastName,
                      mirixa,
                      partner,
                      patientId,
                      indexArr,
                      _id,
                    }}
                    patient={{
                      firstName: firstName,
                      lastName: lastName,
                      patientId: patientId,
                      hasControls: hasControls,
                      isDelivery: isDelivery,
                      partner: partner ? partner : null,
                    }}
                    // showDate
                    showBadges
                    showEditModal
                    showName
                    whichComponent="dash-ref-sec-"
                    className="bg-transparent"
                    colSizes={[6, 6, 7]}
                    nameClass="fw-bold text-uppercase fs-sm"
                  />
                  {/*</Col>*/}
                  {/*<Col xs={1} className="align-items-top justify-content-center align-content-stretch flex-wrap">*/}
                  {/*  <Badge className="pt-1 float-right top-0 bg-transparent opacity-4">*/}
                  {/*    /!*{refill.requestSent && <i className="material-icons">check_circle_outline</i>}*!/*/}
                  {/*    {typeOfRequest.includes("Phone") && (*/}
                  {/*      <i style={{ color: "#ff0017" }} className="material-icons">*/}
                  {/*        phone_in_talk*/}
                  {/*      </i>*/}
                  {/*    )}*/}
                  {/*    {mirixa && <i className="material-icons text-dark opacity-3">museum</i>}*/}
                  {/*  </Badge>*/}
                  {/*</Col>*/}
                  {/*</Row>*/}
                </ListGroupItem>
              </CSSTransition>
            )
          )}
        </TransitionGroup>
      </div>
    );
  }
}

DashboardRefillSectionItem.propTypes = {
  getPatients: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
});

export default connect(mapStateToProps, {
  getPatients,
  deletePatient,
  updatePatient,
  addPatientRefill,
  deletePatientRefill,
})(DashboardRefillSectionItem);
