import {
  GET_ICD_CODES,
  ADD_ICD_CODE,
  DELETE_ICD_CODE,
  UPDATE_ICD_CODE,
  ICD_CODES_LOADING,
  SEARCH_ICD_CODES
} from "../actions/types";

const initialState = {
  icdCodes: [],
  searchResults: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SEARCH_ICD_CODES:
      return {
        ...state,
        searchResults: action.payload
      };
    case GET_ICD_CODES:
      return {
        ...state,
        icdCodes: action.payload,
        loading: false
      };
    case DELETE_ICD_CODE:
      return {
        ...state,
        icdCodes: state.icdCodes.filter(
          icdCode => icdCode._id !== action.payload
        )
      };
    case ADD_ICD_CODE:
      return {
        ...state,
        icdCodes: [action.payload, ...state.icdCodes]
      };
    case UPDATE_ICD_CODE:
      const { _id } = action.payload;
      const icdCodes = state.icdCodes.map(
        icd => (icd._id === _id ? action.payload : icd)
      );
      return { ...state, icdCodes };
    case ICD_CODES_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}

//Another option to update state
// case UPDATE_ICD_CODE: {
//   const icdCodes = state.icdCodes.map(
//     icd =>
//       icd._id === action.payload._id
//         ? {
//           ...icd,
//           icdCodeNum: action.payload.icdCodeNum,
//           icdCodeValue: action.payload.icdCodeValue
//         }
//         : icd
//   );
//   return { ...state, icdCodes };
// }
