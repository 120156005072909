import React, { Component } from "react";
import { Button, Form, FormGroup, Input, Col, Row, InputGroup, InputGroupText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addPatientRefill } from "../../../actions/patientActions";

import moment from "moment";
// import _ from "lodash";

class RefillAdd extends Component {
  state = {
    refillDate: "",
    requestTypeSelected: [],
    refillNote: "",
    refillNotes: [],
    temporary: false,
    userName: this.props.auth.name,
  };

  componentDidMount() {
    // this.initialState = _.cloneDeep(this.state);
    // this.checkAuthProps();
  }

  // checkAuthProps() {
  //   switch (this.props.auth) {
  //     case null:
  //       return;
  //     case false:
  //       this.setState({ userName: "NOPE" });
  //       return;
  //     case undefined:
  //       this.setState({ userName: "undefined (auth props error)" });
  //       return;
  //     default:
  //       this.setState({ userName: this.props.auth.name });
  //       return;
  //   }
  // }

  handleTemporary = () => {
    this.setState({ temporary: !this.state.temporary });
  };

  clearCourseAndState = () => {
    document.getElementById("refill-course").reset();
    // this.setState(this.initialState);
    // this.initialState = _.cloneDeep(this.initialState);

    this.setState({
      refillDate: "",
      requestTypeSelected: [],
      refillNote: "",
      refillNotes: [],
      temporary: false,
      userName: this.props.auth.name,
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAddRefillClick = (id) => (e) => {
    e.preventDefault();

    const trimmedNote = this.state.refillNote.trim();

    const refillJson = {
      refillDate: this.state.refillDate,
      typeOfRequest: this.state.requestTypeSelected,
      temporary: this.state.temporary,
      requestSent: this.state.temporary && true,
      responseReceived: this.state.temporary && true,
      refillNotes:
        trimmedNote === ""
          ? []
          : [
              {
                date: Date.now(),
                userName: this.state.userName,
                note: trimmedNote,
              },
            ],
    };

    // console.log("REFILL JASON: " + JSON.stringify(refillJson));

    this.props.addPatientRefill(id, refillJson);
    this.clearCourseAndState();
  };

  handleCheckboxBtnClick(selected) {
    const index = this.state.requestTypeSelected.indexOf(selected);
    if (index < 0) {
      this.state.requestTypeSelected.push(selected);
    } else {
      this.state.requestTypeSelected.splice(index, 1);
    }
    this.setState({ requestTypeSelected: [...this.state.requestTypeSelected] });
  }

  render() {
    const patient = this.props.editPatient;
    const oneMonthAgo = moment().subtract(1, "months").format("YYYY-MM-DD");
    const elevenMonths = moment().add(11, "months").format("YYYY-MM-DD");

    return (
      <div className="gray-box">
        {" "}
        <Form id="refill-course" autoComplete="off" onSubmit={this.handleAddRefillClick(patient._id)}>
          <FormGroup>
            <Row>
              <Col sm="7" xs="8" className="pe-0">
                <Input
                  type="date"
                  name="refillDate"
                  placeholder="New refill date"
                  min={oneMonthAgo}
                  max={elevenMonths}
                  value={this.state.refillDate}
                  onChange={this.handleChange}
                  required
                />
              </Col>
              <Col sm="5" xs="4" className="pl-0">
                <Button
                  color="primary"
                  className="bg-info-light float-right line-height-1 py-1"
                  onClick={() => this.handleCheckboxBtnClick("Phone")}
                  active={this.state.requestTypeSelected.includes("Phone")}
                >
                  Phone
                  {/*{this.state.requestTypeSelected.includes("Phone") && <span style={{ marginRight: "-4px" }}> ✓</span>}*/}
                  {this.state.requestTypeSelected.includes("Phone") && <span> ✓</span>}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Input
                  type="text"
                  name="refillNote"
                  placeholder="Refill note"
                  value={this.state.refillNote}
                  onChange={this.handleChange}
                  className="mb-n1"
                />
              </Col>
              <Col>
                <InputGroup className="my-2">
                  {/*<InputGroupAddon*/}
                  {/*  addonType="append"*/}
                  {/*>*/}
                  <InputGroupText style={{ backgroundColor: this.state.temporary ? "rgb(255,148,11)" : "" }}>
                    Temporary Refill
                  </InputGroupText>
                  <InputGroupText>
                    <Input
                      addon
                      type="checkbox"
                      name="temporary"
                      checked={this.state.temporary}
                      onChange={() => this.handleTemporary()}
                      aria-label="temporary"
                    />
                  </InputGroupText>
                  {/*</InputGroupAddon>*/}
                </InputGroup>
              </Col>
              <Col lg={12}>
                <Button color="dark" block style={{ marginBottom: "10px" }}>
                  Add New Refill
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

RefillAdd.propTypes = {
  //   getPatients: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
  auth: state.auth,
});

export default connect(mapStateToProps, { addPatientRefill })(RefillAdd);
