import React, { Component } from "react";
import { Card, Col, Row, Spinner, Table } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { filterPatientsBy, getPatients, setCurrentPage, updatePatientsPerPage } from "../../actions/patientActions";
import { setUserSortingPreference, getUserSortingPreference } from "../../actions/userActions";
import { Helmet } from "react-helmet";
import PatientsTableHeader from "./PatientsTable/PatientsTableHeader";
import PatientRow from "./PatientsTable/PatientRow";
import PatientsPagination from "./PatientsTable/PatientsPagination";
import PatientsFilterDropdown from "./PatientsTable/PatientsFilterDropdown";
import PatientsPerPageDropdown from "./PatientsTable/PatientsPerPageDropdown";
import moment from "moment";
import _ from "lodash";

const noteSortValue = (note) => {
  if (!note) return { type: "none", value: "" };
  const firstChar = note.trim().charAt(0);
  if (!isNaN(firstChar)) {
    const num = parseInt(note.trim(), 10);
    return { type: "number", value: num };
  }
  return { type: "string", value: note.trim().toLowerCase() };
};

const customSort = (a, b) => {
  if (a.type === "none") return 1;
  if (b.type === "none") return -1;
  if (a.type === b.type) {
    return a.value > b.value ? 1 : -1;
  }
  return a.type === "number" ? -1 : 1;
};

class Patients extends Component {
  componentDidMount() {
    const { getUserSortingPreference, auth } = this.props;
    this.props.getPatients();
    if (auth && auth._id) {
      getUserSortingPreference(auth._id)
        .then((res) => {
          // console.log("User sorting preference fetched:", res.payload);
        })
        .catch((err) => {
          console.error("Failed to get user sorting preference:", err);
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.auth.sortColumn !== this.props.auth.sortColumn ||
      prevProps.auth.sortOrder !== this.props.auth.sortOrder
    ) {
      this.props.getPatients(); // Refetch patients when sorting preference changes
    }
  }

  handleSort = (column) => {
    const { auth } = this.props;
    const { sortColumn, sortOrder } = auth || { sortColumn: "lastName", sortOrder: "asc" };
    const newSortOrder = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    // console.log(`Sorting column: ${column}, order: ${newSortOrder}`);
    if (auth && auth._id) {
      this.props
        .setUserSortingPreference({
          userId: auth._id,
          sortColumn: column,
          sortOrder: newSortOrder,
        })
        .then(() => {
          this.props.getPatients(); // Refetch patients after sorting preference is updated
        });
    }
  };

  renderPatients = (allPatients) => {
    const { sortColumn, sortOrder } = this.props.auth || { sortColumn: "lastName", sortOrder: "asc" };

    const sortedPatients = _.orderBy(
      allPatients,
      (patient) => {
        if (sortColumn === "refills") {
          if (patient.refills && patient.refills.length > 0) {
            const validRefills = patient.refills.filter((refill) => !refill.temporary);
            if (validRefills.length > 0) {
              return moment.max(validRefills.map((refill) => moment(refill.refillDate)));
            } else {
              return moment("1970-01-01"); // Default date if no valid refills
            }
          } else {
            return moment("1970-01-01"); // Default date if no refills
          }
        } else if (sortColumn === "lastBilledRefill") {
          if (patient.lastBilledRefill && patient.lastBilledRefill.refillDate) {
            return moment(patient.lastBilledRefill.refillDate);
          } else {
            return new Date("1970-01-01");
          }
        } else if (sortColumn === "note" || sortColumn === "secondNote" || sortColumn === "thirdNote") {
          return noteSortValue(patient[sortColumn]);
        } else {
          return _.get(patient, sortColumn);
        }
      },
      [sortOrder],
    );

    if (sortColumn === "note" || sortColumn === "secondNote" || sortColumn === "thirdNote") {
      sortedPatients.sort((a, b) => {
        const noteA = noteSortValue(a[sortColumn]);
        const noteB = noteSortValue(b[sortColumn]);
        return customSort(noteA, noteB) * (sortOrder === "asc" ? 1 : -1);
      });
    }

    const patientsPer = this.props.patient.patientsPerPage;
    const secondItem = patientsPer * this.props.patient.currentPage;
    const firstItem = secondItem - patientsPer;
    return sortedPatients
      .slice(firstItem, secondItem)
      .map((patient) => <PatientRow patient={patient} key={patient._id} auth={this.props.auth} />);
  };

  handleChange = (page) => {
    this.props.setCurrentPage(page);
  };

  filterPatientsChange = (filter) => {
    this.props.filterPatientsBy(filter);
  };

  updatePatientsPerPageChange = (amount) => {
    this.props.updatePatientsPerPage(amount);
  };

  patientsFilter = (patients) => {
    const billedPatients = patients.filter((patient) => patient.lastBilledRefill);
    const withDateAdded = billedPatients.filter((patient) => "dateAdded" in patient.lastBilledRefill);
    const withRecentRefill = patients.filter((patient) => patient.refills.length > 0);

    switch (this.props.patient.filterPatientsBy) {
      case "All Patients":
        return patients;
      case "Currently Active With Refills":
        return withRecentRefill.filter(
          (patient) =>
            patient.active === true &&
            !patient.secondaryPatient &&
            patient.refills.some((refill) => moment(refill.refillDate).isAfter(moment().subtract(60, "days"), "days")),
        );
      case "Primary Patients":
        return patients.filter((patient) => patient.active === true && !patient.secondaryPatient);
      case "Secondary Patients":
        return patients.filter((patient) => patient.secondaryPatient === true && patient.active === true);
      case "All Active Patients":
        return patients.filter((patient) => patient.active === true);
      case "Inactive Patients":
        return patients.filter((patient) => patient.active === false && !patient.deceased);
      case "No Refills":
        return patients.filter((patient) => patient.refills.length < 1 && !!patient.active);
      case "Deceased":
        return patients.filter((patient) => patient.deceased);
      case "Many Refills":
        return patients.filter((patient) => patient.refills.length >= 2 && !!patient.active);
      case "Billed Past Hour":
        return withDateAdded.filter((patient) => moment(patient.lastBilledRefill.dateAdded).isSame(moment(), "hour"));
      case "Billed Today":
        return withDateAdded.filter((patient) => moment(patient.lastBilledRefill.dateAdded).isSame(moment(), "day"));
      case "Billed Yesterday":
        return withDateAdded.filter((patient) =>
          moment(patient.lastBilledRefill.dateAdded).isSame(moment().subtract(1, "days"), "day"),
        );
      case "Billed This Week":
        return withDateAdded.filter((patient) =>
          moment(patient.lastBilledRefill.dateAdded).isSameOrAfter(moment().subtract(7, "day"), "day"),
        );
      case "Has Controls":
        return patients.filter(
          (patient) => patient.hasControls === true && !!patient.hasControls && patient.active === true,
        );
      case "Mirixa":
        return patients.filter((patient) => patient.mirixa === true && !!patient.active);
      case "Extra Archived Refills":
        return patients.filter((patient) => patient.archivedRefills.length > 18);
      default:
        return patients;
    }
  };

  calcTotal = (total, current, perPage) => {
    const first = total === 0 ? 0 : (current - 1) * perPage + 1;
    const second = current * perPage > total ? total : current * perPage;
    return first + " to " + second;
  };

  render() {
    const { patients, currentPage, patientsPerPage, loading } = this.props.patient;
    const { sortColumn, sortOrder } = this.props.auth || { sortColumn: "lastName", sortOrder: "asc" };
    const filteredOut = this.patientsFilter(patients);

    return (
      <div>
        <Helmet defer={false}>
          <title>Patients</title>
        </Helmet>
        <Card body className="pt-2 pb-0">
          {loading ? (
            <div className="loading-pad-100 opacity-3">
              <Spinner className="m-auto" color="dark" />
            </div>
          ) : (
            <>
              <Row
                style={{
                  borderBottom: "1px dashed rgba(0,0,0,0.05)",
                  paddingBottom: "15px",
                }}
              >
                <Col xs={6}>
                  <PatientsFilterDropdown
                    filterPatientsBy={this.props.patient.filterPatientsBy}
                    selectDropPatientsFilter={this.filterPatientsChange}
                  />
                </Col>
                <Col xs={6}>
                  <div style={{ float: "right" }}>
                    <PatientsPerPageDropdown
                      patientsPerPage={this.props.patient.patientsPerPage}
                      selectDropPatientsPer={this.updatePatientsPerPageChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="max-height-70 overflow-auto rounded-8 box-shadow-bottom-1">
                <Table striped bordered hover size="sm" className="table-responsive-lg">
                  <PatientsTableHeader sortColumn={sortColumn} sortOrder={sortOrder} handleSort={this.handleSort} />
                  <tbody>{this.renderPatients(filteredOut)}</tbody>
                </Table>
              </Row>
              <PatientsPagination
                currentPage={currentPage}
                patientsPerPage={patientsPerPage}
                totalItems={filteredOut.length}
                onPageChange={this.handleChange}
              />
            </>
          )}
        </Card>
      </div>
    );
  }
}

Patients.propTypes = {
  getPatients: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  updatePatientsPerPage: PropTypes.func.isRequired,
  setUserSortingPreference: PropTypes.func.isRequired,
  getUserSortingPreference: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPatients,
  setCurrentPage,
  filterPatientsBy,
  updatePatientsPerPage,
  setUserSortingPreference,
  getUserSortingPreference,
})(Patients);
