import React, { Component } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../actions";

import Header from "./Header";
import Landing from "./Landing";
import Dashboard from "./Dashboard";
import IcdDash from "./Drug/DrugDash";
import IcdCodeDash from "./Drug/ICDCodes/IcdCodeDash";
import PatientDash from "./Patients/PatientDash";
import PatientDetails from "./Patients/PatientDetails";
import Pharmacy from "./Pharmacy/Pharmacy";
import Users from "./Users/Users";
import Refills from "./Patients/Refills/Refills";

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import Join from "./Chat/Join";
import Chat from "./Chat/Chat";
import UserTimeClock from "./Users/UserTimeClock";

class App extends Component {
  componentDidMount() {
    this.props.fetchUser();
  }

  render() {
    const auth = this.props.auth;
    // console.log("AUTH: ", this.props.auth);
    // const PatientDetailsWrapper = () => {
    //   const props = useParams();
    //   console.log("loveeely: " + props.match.params);
    //   return <PatientDetails key={props.match.params.id} {...props} />;
    // };

    return (
      <BrowserRouter>
        {/*<div>*/}
        <Header />
        <Routes>
          <Route exact path="/" element={!!auth ? <Dashboard /> : <Landing />} />

          {/*<Route path="/join" exact element={Join} />*/}
          {/*<Route path="/chat" element={Chat} />*/}

          <Route exact path="/dash" element={!!auth ? <Dashboard /> : <Landing />} />
          <Route exact path="/drugs" element={<IcdDash />} />
          <Route exact path="/icd-codes" element={<IcdCodeDash />} />
          <Route exact path="/refills" element={!!auth ? <Refills /> : <Landing />} />
          <Route exact path="/patients" element={!!auth ? <PatientDash /> : <Landing />} />
          <Route exact path="/timeclock" element={!!auth ? <UserTimeClock /> : <Landing />} />
          {/*<Route exact path="/patients/:id" element={PatientDetails} />*/}
          <Route
            exact
            path="/patients/:id"
            element={!!auth ? <PatientDetails /> : <Landing />}
            // render={(props) => <PatientDetails key={props.match.params.id} {...props} />}
          />

          <Route exact path="/pharmacy" element={<Pharmacy />} />
          <Route exact path="/users" element={!!auth ? <Users /> : <Landing />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {/*</div>*/}
      </BrowserRouter>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, actions)(App);
// export default connect(null, actions)(App);
