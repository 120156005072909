import React, { useState } from "react";
import { Button, Card, CardTitle } from "reactstrap";

const WholesalerMultiSearch = () => {
  const [query, setQuery] = useState("");
  const [searchRVP, setSearchRVP] = useState(true);
  const [searchSRX, setSearchSRX] = useState(true);
  const [searchIPC, setSearchIPC] = useState(true);
  const [ipcOrderNumber, setIpcOrderNumber] = useState("");
  const [searchANDA, setSearchANDA] = useState(true);
  const [searchTrxade, setSearchTrxade] = useState(false);

  const handleSearch = () => {
    // const sanitizedQuery = query.replace(/%/g, "");
    const sanitizedQuery = query.trim().replace(/[%\-]/g, "");
    console.log("SANITIZED TOO: ", sanitizedQuery);

    if (searchRVP) {
      const rvpUrl = `https://www.realvalueproducts.com/card-view?filter=${encodeURIComponent(sanitizedQuery)}`;
      const rvpWindow = window.open(rvpUrl, "_blank");
      if (!rvpWindow) {
        alert("Popup blocked! Please allow popups for this site.");
      }
    }
    if (searchSRX) {
      const srxUrl = `https://www.2srx.shop/search?keywords=${encodeURIComponent(sanitizedQuery)}`;
      const srxWindow = window.open(srxUrl, "_blank");
      if (!srxWindow) {
        alert("Popup blocked! Please allow popups for this site.");
      }
    }
    if (searchANDA) {
      const andaUrl = `https://www.andanet.com/search?q=${encodeURIComponent(sanitizedQuery)}`;
      const andaWindow = window.open(andaUrl, "_blank");
      if (!andaWindow) {
        alert("Popup blocked! Please allow popups for this site.");
      }
    }
    if (searchTrxade) {
      const trxadeUrl = `https://www.trxade.com/market/search?searchLabel=&searchTerm=${encodeURIComponent(sanitizedQuery)}&catCode=All&NDCSpecific=true`;
      const trxadeWindow = window.open(trxadeUrl, "_blank");
      if (!trxadeWindow) {
        alert("Popup blocked! Please allow popups for this site.");
      }
    }
    if (searchIPC) {
      const ipcUrl = `https://order.ipcrx.com/order/${encodeURIComponent(ipcOrderNumber)}?search=${encodeURIComponent(
        sanitizedQuery,
      )}`;
      const ipcWindow = window.open(ipcUrl, "_blank");
      if (!ipcWindow) {
        alert("Popup blocked! Please allow popups for this site.");
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const resetSearch = () => {
    setQuery("");
    setSearchRVP(true);
    setSearchSRX(true);
    setSearchIPC(true);
    setSearchANDA(true);
    setSearchTrxade(false);
  };

  return (
    <div>
      <Card className="py-2 px-3">
        <CardTitle className="text-center text-success mb-2">
          <span className="cursor-pointer rounded px-1" onClick={resetSearch}>
            DRUG WHOLESALER <strong>MULTISEARCH</strong>
          </span>
        </CardTitle>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter search query"
          className="box-shadow-0 form-control"
        />
        {/*<Button onClick={handleSearch}>Search</Button>*/}
        <div className="my-1">
          <input type="checkbox" id="search-rvp" checked={searchRVP} onChange={(e) => setSearchRVP(e.target.checked)} />
          <label htmlFor="search-rvp" className="px-2">
            RealValueRX
          </label>
        </div>
        <div className="my-1">
          <input type="checkbox" id="search-srx" checked={searchSRX} onChange={(e) => setSearchSRX(e.target.checked)} />
          <label htmlFor="search-srx" className="px-2">
            2nd Source RX
          </label>
        </div>
        <div className="my-1">
          <input
            type="checkbox"
            id="search-anda"
            checked={searchANDA}
            onChange={(e) => setSearchANDA(e.target.checked)}
          />
          <label htmlFor="search-anda" className="px-2">
            ANDA
          </label>
        </div>
        <div className="my-1">
          <input type="checkbox" id="search-ipc" checked={searchIPC} onChange={(e) => setSearchIPC(e.target.checked)} />
          <label htmlFor="search-ipc" className="px-2">
            IPC
          </label>
          {searchIPC && (
            <input
              type="text"
              value={ipcOrderNumber}
              onChange={(e) => setIpcOrderNumber(e.target.value)}
              placeholder="Enter IPC Order Number"
            />
          )}
        </div>
        <div className="my-1">
          <input
            type="checkbox"
            id="search-trxade"
            checked={searchTrxade}
            onChange={(e) => setSearchTrxade(e.target.checked)}
          />
          <label htmlFor="search-trxade" className="px-2">
            Trxade
          </label>
        </div>
      </Card>
    </div>
  );
};

export default WholesalerMultiSearch;
