import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate  } from "react-router-dom";
import { Container, Col, Row, Card } from "reactstrap";
import IcdCodeAdd from "./IcdCodeAdd";
import IcdCodeItems from "./IcdCodes";
import NotAuthorized from "../../NotAuthorized";
import { Helmet } from "react-helmet";

class IcdCodeDash extends Component {
  renderContent() {
    const auth = this.props.auth;
    switch (auth) {
      case null:
        return;
      case false:
        return <Navigate  to="/" />;
      default:
        if (auth.role === "superadmin" || auth.role === "admin") {
          return (
            <div className="">
              <>
                <Helmet defer={false}>
                  <title>ICD Codes</title>
                </Helmet>
              </>
              <Row>
                <Col xs="12" className="pt-1">
                  <Card className="text-center py-2">
                    ICD-10 CODE <strong>DASHBOARD</strong>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <IcdCodeAdd />
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Card body>
                    <IcdCodeItems />
                  </Card>
                </Col>
              </Row>
            </div>
          );
        } else {
          return <NotAuthorized />;
        }
    }
  }

  render() {
    return (
      <Container fluid className="px-3">
        {this.renderContent()}
      </Container>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps)(IcdCodeDash);
