import {
  GET_NOTES,
  ADD_NOTE,
  DELETE_NOTE,
  UPDATE_NOTE,
  NOTES_LOADING,
  CLEAR_NOTE_RESPONSE,
  NOTE_ERROR,
} from "../actions/types";

const initialState = {
  notes: [],
  response: "",
  error: {},
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NOTES:
      return {
        ...state,
        notes: action.payload,
        loading: false,
      };
    case DELETE_NOTE:
      return {
        ...state,
        notes: state.notes.filter((note) => note._id !== action.payload),
      };
    case ADD_NOTE:
      if (action.payload.errorMessage) {
        return { ...state, error: action.payload };
      }
      return {
        ...state,
        notes: [action.payload, ...state.notes],
        response: "note successfully added",
        error: { jim: "damn" },
      };
    case UPDATE_NOTE:
      const {
        _id,
        createdAt,
        updatedAt,
        category,
        subcategory,
        categoryOrder,
        subcategoryOrder,
        sortOrder,
        notes,
        archivedNotes,
        user,
      } = action.payload;
      const notesOne = state.notes.map((note) => {
        if (note._id === _id) {
          return {
            ...note,
            createdAt,
            updatedAt,
            category,
            subcategory,
            categoryOrder,
            subcategoryOrder,
            sortOrder,
            notes,
            archivedNotes,
            user,
          };
        }
        return note;
      });
      return { ...state, notes: notesOne };

    case CLEAR_NOTE_RESPONSE:
      // console.log("whats cracking: ");
      return { ...state, response: "" };

    case NOTES_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
