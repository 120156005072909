import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  Form,
  Input,
  Button,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  Alert,
  FormGroup,
  Badge,
  Spinner,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import _ from "lodash";
import RefillEditModal from "./RefillEditModal";
import RefillChangeSelected from "./RefillChangeSelected";
import CalculateDate, { orderDates } from "../../Utilities/CalculateDate";
import SevenDayTable from "./RefillSevenDayTable";
import { getPatients, getRefills } from "../../../actions/patientActions";
import RefillUpcomingCalendar from "./RefillUpcomingCalendar";
import PatientSearch from "../PatientSearch/PatientSearch";
import { Helmet } from "react-helmet";
import RefillsUpcomingWeek from "./RefillsUpcomingWeek";
import RefillItem from "./RefillItem";
// import { formatDate } from "../../Utilities/CalculateDate";

class RefillsDash extends Component {
  state = {
    modal: false,
    // selectedDate: moment(moment(), "YYYY-MM-DDTHH:mm:ss").add(1, "days").format("YYYY-MM-DD"),
    // foundRefills: [],
    selectedDate: this.props.patient.selectedDate
      ? this.props.patient.selectedDate
      : moment(moment(), "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
    // : moment(moment(), "YYYY-MM-DDTHH:mm:ss").add(1, "days").format("YYYY-MM-DD"),
    foundRefills: this.props.patient.refills ? this.props.patient.refills : [],
    alertVisible: false,
    selectedRefills: [],
    addPartner: false,
    count: this.props.patient.requestsSent ? this.props.patient.requestsSent : 0,
    // count: 0,
    dropdownOpen: false,
    dropdownSelection: "",
    loading: true,
  };

  componentDidMount() {
    this.props.getPatients();
    // this.props.getRefills();
    this.setTodaysDate();
    this.interval = setInterval(() => this.setTodaysDate(), 40 * 1000 * 360);
  }

  componentWillUnmount() {
    // clearInterval(this.interval);
    this.debounceCloseAlert.cancel();
  }

  componentDidUpdate(prevProps) {
    if (this.props.patient.patients !== prevProps.patient.patients) {
      this.refreshRefills();
      this.setState({ loading: false });
      // this.props.getRefills(this.props.patient.selectedDate);
    }
    if (this.props.patient.refills !== prevProps.patient.refills) {
      this.setState({ foundRefills: this.props.patient.refills, count: this.props.patient.requestsSent });
    }
  }

  setTodaysDate = () => {
    // this.setState({
    //   selectedDate: moment(moment(), "YYYY-MM-DDTHH:mm:ss").add(1, "days").format("YYYY-MM-DD"),
    // });

    this.oneMonthAgo = moment().subtract(1, "months").format("YYYY-MM-DD");
    this.elevenMonths = moment().add(11, "months").format("YYYY-MM-DD");
  };

  goBackToToday = () => {
    this.setState(
      {
        // selectedDate: moment(moment(), "YYYY-MM-DDTHH:mm:ss").add(1, "days").format("YYYY-MM-DD"),
        selectedDate: moment(moment(), "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
      },
      () => this.refreshRefills(),
    );
  };

  handleDismissAlert = () => {
    this.setState({ alertVisible: false });
  };

  debounceCloseAlert = _.debounce(() => {
    this.setState({ alertVisible: false });
  }, 5000);

  toggle = () => {
    this.setState({
      // modal: !this.state.modal,
      selectedRefills: [],
      // ADDED LINE ABOVE TO CLEAR UP ISSUE WHEN CLOSING MODAL LEAVES LINGERING selectedRefill
      dropdownSelection: "",
    });
    this.findRefillsByDate();
  };

  checkDate = () => {
    const before = moment(this.state.selectedDate).isBefore(this.elevenMonths);
    const after = moment(this.state.selectedDate).isAfter(this.oneMonthAgo);
    if (before && after) {
      this.toggle();
    } else {
      this.setState({ alertVisible: true });
      this.debounceCloseAlert();
    }
  };

  refreshRefills = () => {
    this.findRefillsByDate();
    this.setState({ selectedRefills: [], dropdownSelection: "" });

    //resets all checkboxes to empty since I'm not managing it with state
    document.querySelectorAll("input[type=checkbox]").forEach((el) => (el.checked = false));
  };

  refreshAllPatients = () => {
    this.props.getPatients();
  };

  handleChangeDate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => this.checkDate());
  };

  checkNextDate = (add) => (e) => {
    let count;
    add ? (count = 1) : (count = -1);
    this.setState(
      {
        selectedDate: moment(this.state.selectedDate, "YYYY-MM-DDTHH:mm:ss").add(count, "days").format("YYYY-MM-DD"),
      },
      () => this.refreshRefills(),
    );
  };

  refillsByDay = () => {
    const activePatients = this.props.patient.patients.filter((patient) => patient.active);
    const patientsWithRefills = activePatients.filter((patient) => patient.refills.length > 0);
    const matchingRefills = [];

    patientsWithRefills.forEach((patient) => {
      patient.refills.forEach((refill, i) => {
        if (
          moment(refill.refillDate, "YYYY-MM-DDTHH:mm:ss").isBetween(
            moment().startOf("week"),
            moment().endOf("week"),
            "day",
          )
        ) {
          const rxCount = patient.rxCount || 0;

          matchingRefills.push({
            ...refill,
            indexArr: i,
            rxCount: rxCount, // Ensure rxCount is always a number
          });
        }
      });
    });

    // console.log("Matching refills:", matchingRefills);
    return matchingRefills;
  };

  findRefillsByDate = () => {
    const selected = moment(this.state.selectedDate);
    this.props.getRefills(selected);
    this.setState({ count: this.props.patient.requestsSent });
    // this.setState({ foundRefills: this.props.patient.refills, count: this.props.patient.requestsSent });
  };

  handleSelectRefill = (refillId, isChecked, partner) => {
    partner && this.setState({ addPartner: "true" });

    let newArray = isChecked
      ? [...this.state.selectedRefills, refillId]
      : [...this.state.selectedRefills].filter((e) => e !== refillId);

    this.setState({ selectedRefills: newArray, dropdownSelection: "" });
  };

  selectAllRefills = (type) => {
    const { foundRefills } = this.state;
    let newArray = [];
    switch (type) {
      case "all":
        // newArray = [...selectedRefills, ...foundRefills.map((refill) => refill._id)];
        newArray = foundRefills.map((refill) => refill._id);
        this.setState({ selectedRefills: newArray, dropdownSelection: "all" });
        return;
      case "partners":
        // newArray = [...selectedRefills, ...foundRefills.filter((r) => r.partner).map((refill) => refill._id)];
        newArray = foundRefills.filter((r) => r.partner).map((refill) => refill._id);
        this.setState({ selectedRefills: newArray, dropdownSelection: "partners" });
        return;
      case "no-partners":
        // newArray = [...selectedRefills, ...foundRefills.filter((r) => !r.partner).map((refill) => refill._id)];
        newArray = foundRefills.filter((r) => !r.partner).map((refill) => refill._id);
        this.setState({ selectedRefills: newArray, dropdownSelection: "no-partners" });
        return;
      case "deselect":
        this.setState({ selectedRefills: [], dropdownSelection: "" });
        return;
      default:
        return;
    }
  };

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  selectOrDeselectRefills = () => {
    const { selectedRefills, dropdownSelection, dropdownOpen } = this.state;

    return (
      <Dropdown
        size="sm"
        color="light"
        isOpen={dropdownOpen}
        toggle={this.toggleDropdown}
        className="float-left position-absolute mt-0 text-center"
      >
        <DropdownToggle className="btn-light border rounded-8 opacity-4" caret style={{ marginTop: "-1px" }}>
          {selectedRefills.length > 0 ? <b>Selected {selectedRefills.length}</b> : "Select"}
        </DropdownToggle>
        <DropdownMenu className="rounded-8">
          <DropdownItem disabled={dropdownSelection === "all"} onClick={() => this.selectAllRefills("all")}>
            All Refills
          </DropdownItem>
          <DropdownItem disabled={dropdownSelection === "partners"} onClick={() => this.selectAllRefills("partners")}>
            Patients With Partners
          </DropdownItem>
          <DropdownItem
            disabled={dropdownSelection === "no-partners"}
            onClick={() => this.selectAllRefills("no-partners")}
          >
            Patients Without Partners
          </DropdownItem>
          {selectedRefills.length > 0 && (
            <DropdownItem className="fw-bold" onClick={() => this.selectAllRefills("deselect")}>
              Clear Selections
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  };

  selectPartnerRefill = (partnerRefills, originalRefillDate) => {
    const sameDateRefillsId = this.getSameDayRefillsId(partnerRefills, originalRefillDate);

    if (sameDateRefillsId) {
      let newArray = this.isChecked(sameDateRefillsId)
        ? [...this.state.selectedRefills].filter((e) => e !== sameDateRefillsId)
        : [...this.state.selectedRefills, sameDateRefillsId];

      this.setState({ selectedRefills: newArray });
    }
  };

  isChecked = (refillId) => {
    return this.state.selectedRefills.indexOf(refillId) > -1;
  };

  requestSentRemaining = (requestSent) => {
    if (!!requestSent) {
      this.setState({ count: this.state.count + 1 });
    }
    // return;
  };

  getSameDayRefillsId = (partnerRefills, originalRefillDate) => {
    const sameDateRefills = partnerRefills.filter((ref) => moment(ref.refillDate).isSame(originalRefillDate, "day"));
    if (sameDateRefills.length > 0) {
      return sameDateRefills[0]._id;
    }
    return null;
  };

  sendDateChild = (date) => {
    this.setState({ selectedDate: moment(date).format("YYYY-MM-DD") }, () => this.toggle());
  };

  render() {
    const { addPartner, foundRefills } = this.state;
    const { loading } = this.props.patient;
    const sentRemaining = foundRefills.length - this.state.count;

    return (
      <div className="px-3">
        <>
          <Helmet defer={false}>
            <title>Refills</title>
          </Helmet>
        </>
        <Row>
          <Col md={6} lg={4} className="px-1">
            <div className="">
              <RefillsUpcomingWeek />
            </div>
          </Col>

          <Col md={6} lg={8} className="px-1">
            <Row className="g-0">
              <Col md={12} lg={12} xl={6} className="px-1">
                <div className="sticky-top">
                  <Card className="text-center py-2 px-1 opacity-4 text-center mt-1 bg-white box-shadow-1">
                    <Alert color="info" isOpen={this.state.alertVisible} toggle={this.handleDismissAlert}>
                      <span>Please enter a correct date to search for.</span>
                    </Alert>

                    <Form id="course-refill" className="my-2">
                      <Row className="g-0">
                        <Col
                          lg={12}
                          className="pt-0 px-2 m-auto text-center d-inline-flex justify-content-center align-items-center"
                        >
                          <Button
                            size="lg"
                            className="border me-1 m-0"
                            color="light"
                            onClick={this.checkNextDate(false)}
                          >
                            ←
                          </Button>
                          <Input
                            className="text-center mx-1"
                            type="date"
                            min={this.oneMonthAgo}
                            max={this.elevenMonths}
                            name="selectedDate"
                            value={this.state.selectedDate}
                            onChange={this.handleChangeDate}
                            // had to use below to disable ability to type which causes bugs
                            onKeyDown={(e) => e.preventDefault()}
                            required
                          />
                          <Button
                            size="lg"
                            className="border ms-1 m-0"
                            color="light"
                            onClick={this.checkNextDate(true)}
                          >
                            →
                          </Button>
                        </Col>
                      </Row>
                    </Form>

                    {this.refillsByDay().length > 0 ? (
                      <>
                        <Row className="py-0 pt-1 mt-n2 mb-0 px-3 m-auto text-center">
                          <div className="border-lighter rounded-8 p-1 px-2 mb-1 opacity-4">
                            <b className="me-1">{this.refillsByDay().length}</b> Refills This Week
                          </div>
                        </Row>
                        <Row className="py-0 mt-n1 px-3">
                          <SevenDayTable refillsByDay={this.refillsByDay()} week={0} sendDate={this.sendDateChild} />
                        </Row>
                      </>
                    ) : (
                      <>
                        {loading && (
                          <div
                            className="d-flex mx-1 bg-gray-transparent opacity-2 rounded-16 m-2 p-3 align-items-center"
                            style={{ height: "95px" }}
                          >
                            <Spinner className="m-auto opacity-2" color="dark" />
                          </div>
                        )}
                      </>
                    )}
                  </Card>

                  <RefillUpcomingCalendar sendDateToRefillSearch={this.sendDateChild} />
                  <PatientSearch />
                </div>
              </Col>

              {/*--------------------- DAILY REFILLS ---------------------*/}
              {/*--------------------- DAILY REFILLS ---------------------*/}
              {/*--------------------- DAILY REFILLS ---------------------*/}
              <Col md={12} lg={12} xl={6} className="px-1">
                <Card className="px-2 py-0 text-center">
                  <div className="bg-white-transparent rounded-8 py-3 px-2 mx-n2 sticky-top">
                    <div>
                      {/*{CalculateDate.days(1) !== this.state.selectedDate && (*/}
                      {CalculateDate.today() !== this.state.selectedDate && (
                        <div style={{ position: "absolute" }}>
                          <Button
                            color="light"
                            style={{
                              margin: "0px 12px 8px 0px",
                              border: "1px #e2e2e2 solid",
                            }}
                            onClick={this.goBackToToday}
                          >
                            {moment(moment(), "YYYY-MM-DDTHH:mm:ss").format("MM/DD")}
                          </Button>
                        </div>
                      )}

                      <Button color="info" className="me-2 mb-2 mt-0 px-3" onClick={this.checkNextDate(false)}>
                        ⇠
                      </Button>
                      <Button
                        color="light"
                        className="mx-2 mb-2 mt-0 px-2 border rounded"
                        onClick={this.refreshAllPatients}
                      >
                        ↺
                      </Button>
                      <Button color="info" className="ms-2 mb-2 mt-0 px-3" onClick={this.checkNextDate(true)}>
                        ⇢
                      </Button>

                      {this.state.selectedRefills.length > 0 && (
                        <RefillChangeSelected
                          selectedRefillsYa={this.state.selectedRefills}
                          refreshModalYa={() => this.refreshRefills()}
                        />
                      )}
                    </div>

                    {foundRefills.length > 0 ? (
                      <div>
                        {this.selectOrDeselectRefills()}
                        {sentRemaining !== 0 && (
                          <Badge
                            color="warning"
                            className="position-absolute fs-lg border rounded-8 opacity-2"
                            style={{ right: "8px", marginTop: "-1px" }}
                          >
                            {sentRemaining}
                          </Badge>
                        )}
                        <h5>
                          <span className="border rounded-8 px-2 py-0 pb-1">
                            <b>{foundRefills.length}</b>
                            <span className="text-muted"> {foundRefills.length === 1 ? " refill" : " refills"}</span>
                          </span>
                          &nbsp;
                          <span className="border rounded-8 px-2 py-0 pb-1">
                            {moment(this.state.selectedDate, "YYYY-MM-DDTHH:mm:ss").format("ddd MM/DD")}
                          </span>
                        </h5>
                      </div>
                    ) : (
                      <h5 className="opacity-4">
                        <span className="border rounded-8 px-2 py-0 pb-1 text-muted">
                          {loading ? (
                            <span className="opacity-1">
                              <b className="opacity-2">no </b>refills
                            </span>
                          ) : (
                            <span>
                              <b>no </b>refills
                            </span>
                          )}
                        </span>
                        &nbsp;
                        <span className="border rounded-8 px-2 py-0 pb-1 text-muted">
                          {moment(this.state.selectedDate, "YYYY-MM-DDTHH:mm:ss").format("ddd MM/DD")}
                        </span>
                      </h5>
                    )}
                  </div>

                  {foundRefills.length > 0 ? (
                    <div className="mb-3">
                      <div className="rounded-8 box-shadow-1">
                        <ListGroup className="rounded-8">
                          {foundRefills.map((refill, indexFoundRefills) => (
                            // -----------------EACH REFILL START---------------- //
                            // -----------------EACH REFILL START---------------- //
                            // -----------------EACH REFILL START---------------- //
                            <ListGroupItem
                              style={{
                                backgroundColor: this.isChecked(refill._id)
                                  ? "#c3e6cb"
                                  : refill.requestSent && refill.responseReceived
                                    ? "rgba(184,255,170,0.93)"
                                    : refill.requestSent
                                      ? "rgba(255,233,183,0.62)"
                                      : "",
                                opacity: refill.temporary ? "60%" : "100%",
                              }}
                              key={refill._id}
                              id={refill._id + "addPartner"}
                              className="position-relative p-0"
                            >
                              {addPartner && refill.partner && this.isChecked(refill._id) && (
                                <>
                                  {/*--------------------------------------------------------*/}
                                  {/*Need to also make sure the partner has a same day refill*/}
                                  {/*--------------------------------------------------------*/}
                                  {this.getSameDayRefillsId(refill.partner.refills, refill.refillDate) && (
                                    <div>
                                      <Button
                                        color="light"
                                        onClick={() =>
                                          this.selectPartnerRefill(refill.partner.refills, refill.refillDate)
                                        }
                                        className="rounded-8 py-1 box-shadow-2 line-height-1"
                                      >
                                        {this.isChecked(
                                          this.getSameDayRefillsId(refill.partner.refills, refill.refillDate),
                                        ) ? (
                                          <div>
                                            <div className="fw-bold">Both partners selected</div>
                                            <div>click to deselect partner</div>
                                          </div>
                                        ) : (
                                          "also select partner's refill?"
                                        )}
                                      </Button>
                                    </div>
                                  )}
                                </>
                              )}
                              <Row className="p-0 px-1 no-gutters">
                                <Col xs="1" className="p-1 ps-3 d-flex">
                                  <FormGroup check>
                                    <Input
                                      id={refill._id}
                                      onChange={(e) =>
                                        this.handleSelectRefill(refill._id, e.target.checked, refill.partner)
                                      }
                                      type="checkbox"
                                      checked={this.isChecked(refill._id)}
                                      className="border"
                                    />
                                  </FormGroup>
                                </Col>

                                {/*-----------------MAIN REFILL BOX----------------*/}
                                {/*-----------------MAIN REFILL BOX----------------*/}
                                {/*-----------------MAIN REFILL BOX----------------*/}
                                <Col xs="11" className="py-1 rounded-8">
                                  {/*------------------------MAIN FILL------------------------*/}
                                  {/*------------------------MAIN FILL------------------------*/}
                                  {/*------------------------MAIN FILL------------------------*/}
                                  <RefillItem
                                    refillProp={refill}
                                    patient={{
                                      firstName: refill.firstName,
                                      lastName: refill.lastName,
                                      patientId: refill.patientId,
                                      partner: refill.partner,
                                      hasControls: refill.hasControls,
                                      isDelivery: refill.isDelivery,
                                      rxCount: refill.rxCount,
                                    }}
                                    nameClass="text-uppercase fw-bold"
                                    showBadges
                                    showEditModal
                                    showName
                                    className="text-center rounded-8"
                                    whichComponent="ref-dash-"
                                    colSizes={[6, 6, 6]}
                                  />

                                  {/*------------------------OTHER REFILLS------------------------*/}
                                  {/*------------------------OTHER REFILLS------------------------*/}
                                  {/*------------------------OTHER REFILLS------------------------*/}
                                  {refill.otherRefills && (
                                    <div className="max-height-8 text-left overflow-y-auto">
                                      {refill.otherRefills.length > 0 && (
                                        <div className="d-inline-block bg-light rounded-8 mt-1 mb-0 px-1 pb-1">
                                          <Badge color="light" className="text-wrap text-dark">
                                            {refill.otherRefills.length} other{" "}
                                            {refill.otherRefills.length > 1 ? "refills" : "refill"}
                                          </Badge>
                                          <div className={refill.otherRefills.length > 1 ? "d-flex" : ""}>
                                            {refill.otherRefills.map((otherRefill, i) => {
                                              return (
                                                <Badge color="dark" key={i + 109} className="opacity-3 pb-1 text-wrap">
                                                  {moment(otherRefill.refillDate, "YYYY-MM-DDTHH:mm:ss").format(
                                                    "ddd M/DD",
                                                  )}
                                                  {otherRefill.refillNotes && (
                                                    <div>
                                                      {otherRefill.refillNotes.map((note, i) => {
                                                        return (
                                                          <div key={i + "noteNote"}>
                                                            {note.userName}: {note.note}
                                                          </div>
                                                        );
                                                      })}
                                                    </div>
                                                  )}
                                                </Badge>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </div>
                    </div>
                  ) : (
                    <>
                      {loading ? (
                        <div
                          className="d-flex bg-gray-transparent opacity-2 rounded-16 m-0 mb-3 p-1 align-items-center"
                          style={{ height: "95px" }}
                        >
                          <Spinner className="m-auto opacity-2" color="dark" />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  patient: state.patient,
});

export default connect(mapStateToProps, { getPatients, getRefills })(RefillsDash);

// ORIGINALLY IN findRefillsByDate()
//---------------------------------//
// const activePatients = this.props.patient.patients.filter((patient) => patient.active);
// const patientsWithRefills = activePatients.filter((patient) => patient.refills.length > 0);
// const matchingRefills = [];
// const selected = moment(this.state.selectedDate);
//
// patientsWithRefills.forEach((patient) => {
//   patient.refills.forEach((refill, i) => {
//     if (moment(refill.refillDate, "YYYY-MM-DDTHH:mm:ss").isSame(selected, "day")) {
//       matchingRefills.push({
//         ...patient.refills[i],
//         ...{
//           firstName: patient.firstName,
//           lastName: patient.lastName,
//           patientId: patient._id,
//           mirixa: patient.mirixa,
//           // hasPartner: patient.hasPartner,
//           partner: patient.partner,
//           otherRefills: patient.refills && patient.refills.filter((refX) => refX._id !== refill._id),
//           indexArr: i,
//         },
//       });
//     }
//   });
// });
//
// const count = matchingRefills.filter((x, i) => {
//   return x.requestSent;
// }).length;
