import React from "react";
import PropTypes from "prop-types";
import PaginationNew from "react-js-pagination";

const PatientsPagination = ({ currentPage, patientsPerPage, totalItems, onPageChange }) => (
  <div className="text-center pt-2 border-top border-light">
    <div className="font-small text-muted mb-1">
      {`${(currentPage - 1) * patientsPerPage + 1} to ${
        currentPage * patientsPerPage > totalItems ? totalItems : currentPage * patientsPerPage
      } of ${totalItems} patients`}
    </div>
    <PaginationNew
      onChange={onPageChange}
      activePage={currentPage}
      totalItemsCount={totalItems}
      itemsCountPerPage={patientsPerPage}
      pageRangeDisplayed={6}
      itemClass="page-item m-0"
      linkClass="page-link"
      innerClass="pagination justify-content-center mb-0"
    />
  </div>
);

PatientsPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  patientsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default PatientsPagination;
