import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardTitle, Col, Row, Container, Button } from "reactstrap";
import GoBack from "../../GoBack";
import { getAllUsers } from "../../actions/userActions";
import UserEditModal from "./UserEditModal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import TemporaryAdminUpdates from "../Utilities/TemporaryAdminUpdates";
import PatientBulkUpdater from "../Patients/PatientBulkUpdater";
// import TemporaryAdminUpdates from "../Utilities/TemporaryAdminUpdates";

class Users extends Component {
  componentDidMount() {
    this.props.auth && this.props.getAllUsers();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth) {
        this.props.auth.role === "superadmin" && this.props.getAllUsers();
      }
    }
  }

  isAdmin() {
    const role = this.props.auth.role;
    //--- USE THIS TO TEMPORARILY OVERRIDE IN CASE YOU ACCIDENTALLY ---//
    //--- UNSET THE SUPERADMIN PRIVILEGES ---//
    // if(this.props.auth) {

    if (role === "superadmin" || role === "admin") {
      return (
        <Container fluid className="p-0 pt-1">
          <div className="px-2">
            <Card body style={{ backgroundColor: "#e2e2e2" }}>
              <CardTitle style={{ textAlign: "center" }}>
                <strong>USERS</strong>
              </CardTitle>

              <div>
                <>
                  <Helmet defer={false}>
                    <title>Users</title>
                  </Helmet>
                </>
                <Row>
                  {this.props.user.users.map((user) => (
                    <Col sm="3" xs="6" className="col-3-no-padding" key={user._id}>
                      <Card body style={{ textAlign: "center" }}>
                        {user.name ? <h5>{user.name}</h5> : <div>user has no name</div>}
                        {user.pharmacy ? (
                          <div>
                            <b>{user.pharmacy.name} Pharmacy</b>
                          </div>
                        ) : (
                          <div style={{ opacity: "0.5" }}>
                            <i>no pharmacy</i>
                          </div>
                        )}
                        {user.email && <div style={{ color: "#008cff" }}>{user.email}</div>}
                        {user.role ? (
                          <div
                            style={{
                              color: user.role === "user" ? "#15ba00" : "#ff0e00",
                            }}
                          >
                            <b>{user.role}</b>
                          </div>
                        ) : (
                          <div style={{ opacity: "0.5" }}>
                            <i>no role</i>
                          </div>
                        )}
                        {user.username && <div>{user.username}</div>}

                        <UserEditModal editUser={user} />
                        <div />
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </Card>
            {/*<TemporaryAdminUpdates />*/}
            <PatientBulkUpdater />
            <div>
              <GoBack />
              <Link to="/pharmacy">
                <Button color="warning" className="btn-lg">
                  Pharmacy
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      );
    }
    return;
  }

  render() {
    switch (this.props.auth) {
      case null:
        return <div />;
      case false:
        return <div />;
      default:
        return <div>{this.isAdmin()}</div>;
    }
  }
}

function mapStateToProps({ auth, user }) {
  return { auth, user };
}

export default connect(mapStateToProps, { getAllUsers })(Users);
