import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Col, Row } from "reactstrap";
import DrugAdd from "./DrugAdd";
import Drugs from "./Drugs";
import DrugSearch from "./DrugSearch";
import NotAuthorized from "../NotAuthorized";
import { Helmet } from "react-helmet";

class DrugDash extends Component {
  isAdmin() {
    const auth = this.props.auth;
    if (auth.role === "superadmin" || auth.role === "admin") {
      return (
        <div className="">
          <>
            <Helmet defer={false}>
              <title>Drugs</title>
            </Helmet>
          </>
          <Row>
            <Col xs="12" className="pt-1">
              <DrugSearch />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <DrugAdd />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Drugs />
            </Col>
          </Row>
        </div>
      );
    }
    return <NotAuthorized />;
  }

  renderAdd() {
    const auth = this.props.auth;
    switch (auth) {
      case null:
        return;
      case false:
        return (
          <div style={{ textAlign: "center" }}>
            <h5>You must login to be able to view this page.</h5>
          </div>
        );
      default:
        return <div>{this.isAdmin()}</div>;
    }
  }

  render() {
    return (
      <Container fluid className="px-3">
        <div>{this.renderAdd()} </div>
      </Container>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps)(DrugDash);
