import React, { Component } from "react";
import { Button, Form, FormGroup, Input, Card, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { addIcdCode } from "../../../actions/icdCodeActions";
import _ from "lodash";

class IcdCodeAdd extends Component {
  state = {
    codeLocal: "",
    valueLocal: "",
  };

  clearCourse = () => {
    document.getElementById("icd-course").reset();
    document.getElementsByName("codeLocal")[0].focus();
    this.setState({ codeLocal: "" });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: _.upperFirst(e.target.value),
    });
  };

  handleChangeCodeLocal = (e) => {
    this.setState({
      codeLocal: e.target.value.toUpperCase().trim(),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const newIcdCode = {
      icdCodeNum: this.state.codeLocal,
      icdCodeValue: this.state.valueLocal.trim(),
    };

    // Add drug via addIcdCode action
    this.props.addIcdCode(newIcdCode);

    // Clear entered content
    this.clearCourse();
  };

  render() {
    return (
      <div>
        <Card body>
          <Form onSubmit={this.handleSubmit} id="icd-course" autoComplete="off">
            <FormGroup>
              <Row>
                <Col xs="3" className="pe-0">
                  <Input
                    type="text"
                    name="codeLocal"
                    id="drug"
                    placeholder="Icd code"
                    value={this.state.codeLocal}
                    onChange={this.handleChangeCodeLocal}
                    required
                  />
                </Col>
                <Col xs="9">
                  <Input
                    type="text"
                    name="valueLocal"
                    id="drug"
                    placeholder="Code value"
                    value={this.state.valueLocal}
                    onChange={this.handleChange}
                    required
                  />
                </Col>
              </Row>
              <Button color="dark" className="mt-2" block>
                Add Icd Code
              </Button>
            </FormGroup>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  icdCode: state.icdCode,
});

export default connect(mapStateToProps, { addIcdCode })(IcdCodeAdd);
