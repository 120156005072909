import React, { Component } from "react";
import DashboardRefillSectionItem from "./DashboardRefillSectionItem";
import { Col, Row, Card } from "reactstrap";
import Moment from "moment";

class DashboardRefillSection extends Component {
  state = {
    date: "",
  };

  componentDidMount() {
    this.setTodaysDate();
    this.interval = setInterval(() => this.setTodaysDate(), 10 * 1000 * 60);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setTodaysDate() {
    this.setState({
      date: Moment().format("MMM DD").toUpperCase(),
    });
  }

  render() {
    return (
      <div>
        {/*<Card body style={{ padding: "15px 30px 15px 30px" }}>*/}
        <Card body>
          {/*<RefillSearch />*/}
          {/*<Row className="max-height-50 overflow-y-auto">*/}
          <Row>
            <Col xs={12} sm={12} md={6}>
              <h5>
                TODAY <span style={{ color: "#d6d1d6" }}>{this.state.date}</span>
              </h5>
              <DashboardRefillSectionItem billBuddy="BILL_TODAY" />
            </Col>

            <Col xs={12} sm={12} md={6}>
              <h5 style={{ color: "red" }}>OVERDUE</h5>
              <DashboardRefillSectionItem billBuddy="OVERDUE" />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default DashboardRefillSection;
