import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";

import DashboardRefillSection from "./Patients/Refills/DashboardRefillSection";
import PatientSearch from "./Patients/PatientSearch/PatientSearch";
import DrugSearch from "./Drug/DrugSearch";
import NotAuthorized from "./NotAuthorized";
import AddParentNote from "./Notes/AddParentNote";
import Notes from "./Notes/Notes";
import WholesalerMultiSearch from "./Wholesalers/WholesalerMultiSearch";

class Dashboard extends Component {
  renderContent() {
    switch (this.props.auth) {
      case null:
        return;
      case false:
        return (
          <NotAuthorized />
          // <div className="text-center">
          //   <h2>You must login to access the Dashboard</h2>
          // </div>
        );
      default:
        if (this.props.auth.role && this.props.auth.pharmacy) {
          return (
            <div className="px-2">
              <>
                <Helmet defer={false}>
                  <title>Dashboard</title>
                </Helmet>
              </>
              <Row className="g-0">
                <Col sm={12} className="card box-shadow-1 py-1 px-2 mt-2">
                  {/*<Row className="g-0">*/}
                  {/*<Col sm={6} md={3}>*/}
                  {/*  <NotesGeneral />*/}
                  {/*  <AddParentNote />*/}
                  {/*</Col>*/}
                  {/*<Col sm={6} md={9}>*/}
                  <Notes />
                  {/*  </Col>*/}
                  {/*</Row>*/}
                </Col>
                <Col sm={5} className="px-1 pb-sm-1 pb-0 pt-1">
                  <WholesalerMultiSearch />
                  {this.props.auth.pharmacy && <PatientSearch />}
                  <DrugSearch />
                  <AddParentNote />
                </Col>
                <Col sm={7} className="px-1 py-sm-1 py-0">
                  {this.props.auth.pharmacy && <DashboardRefillSection />}
                </Col>
              </Row>
              {/*-----only show if user has associated pharmacy-----*/}
              {/*{this.props.auth.pharmacy && (*/}
              {/*  <Row>*/}
              {/*    <Col sm={4} className="dash-col-lowpad">*/}
              {/*      <PatientSearch />*/}
              {/*    </Col>*/}
              {/*    <Col sm={8} className="dash-col-lowpad">*/}
              {/*      <DashboardRefillSection />*/}
              {/*    </Col>*/}
              {/*  </Row>*/}
              {/*)}*/}
            </div>
          );
        } else {
          return <NotAuthorized />;
        }
    }
  }

  render() {
    return (
      <Container fluid className="px-2">
        {this.renderContent()}
      </Container>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps)(Dashboard);
