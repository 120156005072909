import React from "react";

const Landing = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>
        {/*<i className="large material-icons ">heart_minus</i>*/}
        <span className="material-symbols-outlined large">health_and_safety</span>
      </h1>
      <h5>
        <b>Fargram Pharmacy Management</b>
        {/*<div>6501 San Fernando Rd. Ste K, Glendale, CA 91201</div>*/}
      </h5>
      {/*<div>*/}
      {/*  Phone: <b>(818) 303-3038</b>*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*  Fax: <b>(818) 303-3123</b>*/}
      {/*</div>*/}
    </div>
  );
};

export default Landing;
