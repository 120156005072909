import React, { useState } from "react";
import axios from "axios";

const PatientBulkUpdater = () => {
  const [inputData, setInputData] = useState("");

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/api/patients/update-groups", { data: inputData });
      console.log(response.data);
    } catch (error) {
      console.error("Error updating patient groups:", error);
    }
  };

  return (
    <div>
      <textarea
        rows="10"
        cols="50"
        value={inputData}
        onChange={(e) => setInputData(e.target.value)}
        placeholder="Paste patient data here (lastName, firstName, groupNumber each on a new line)"
      />
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default PatientBulkUpdater;
