import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink as RRNavLink } from "react-router-dom";
import { Navbar, NavbarBrand, Collapse, Nav, NavLink, NavItem, NavbarToggler } from "reactstrap";
import { getNotes } from "../actions/noteActions";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(60 * 10);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const note = useSelector((state) => state.note);

  const toggle = () => setIsOpen(!isOpen);

  const refreshNotes = useCallback(() => {
    dispatch(getNotes());
    setSecondsLeft(60 * 10);

    const notesInterval = setInterval(() => {
      dispatch(getNotes());
      setSecondsLeft(60 * 10);
    }, 60000 * 10);

    const countdownInterval = setInterval(() => {
      setSecondsLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(notesInterval);
      clearInterval(countdownInterval);
    };
  }, [dispatch]);

  useEffect(() => {
    if (auth) {
      const cleanup = refreshNotes();
      return cleanup;
    }
  }, [auth, refreshNotes]);

  useEffect(() => {
    return () => {
      clearInterval(refreshNotes.notesInterval);
      clearInterval(refreshNotes.countdownInterval);
    };
  }, []);

  const checkAdmin = () => {
    const role = auth.role;
    if (role === "superadmin" || role === "admin") {
      return (
        <Nav>
          <NavItem>
            <NavLink tag={RRNavLink} to="/drugs">
              Drugs
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={RRNavLink} to="/icd-codes">
              ICD
            </NavLink>
          </NavItem>
          {role === "superadmin" && (
            <Nav>
              <NavItem>
                <NavLink tag={RRNavLink} to="/users">
                  Users
                </NavLink>
              </NavItem>
            </Nav>
          )}
        </Nav>
      );
    }
    return null;
  };

  const renderContent = () => {
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;

    switch (auth) {
      case null:
        return;
      case false:
        return (
          <Nav>
            <NavItem>
              <a href="/auth/google">Login With Google</a>
            </NavItem>
          </Nav>
        );
      default:
        return (
          <Nav>
            {auth.role && auth.pharmacy && (
              <Nav>
                <NavItem className="my-auto opacity-1 me-5">
                  <button className="rounded-8 border-light" onClick={refreshNotes}>
                    {minutes}:{seconds < 10 ? "0" : ""}
                    {seconds}
                  </button>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} to="/dash">
                    <span className="">Dash</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} to="/refills">
                    Refills
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} to="/patients">
                    Patients
                  </NavLink>
                </NavItem>
              </Nav>
            )}

            {checkAdmin()}

            <NavItem>
              <NavLink href="/api/logout">Logout {auth.name}</NavLink>
            </NavItem>
          </Nav>
        );
    }
  };

  return (
    <Navbar expand="sm" className="sticky-top py-1">
      <NavbarBrand tag={RRNavLink} to={auth ? "/dash" : "/"} className="ms-2">
        <span className="material-symbols-outlined large">health_and_safety</span>
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto" navbar>
          {renderContent()}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;
