import React, { useEffect, useState } from "react";
import {
  addNote,
  addCategoryNote,
  updateCategoryNote,
  deleteCategoryNote,
  deleteNote,
  clearNoteResponse,
} from "../../actions/noteActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Badge,
  Button,
  Card,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  Row,
  Tooltip,
} from "reactstrap";
import NoteAddInCategory from "./AddChildNote";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { value } from "lodash/seq";
import NoteChildItem from "./NoteChildItem";

const NoteEditParent = (props) => {
  const { note } = props;
  const dispatch = useDispatch();
  const [toggleEdit, setToggleEdit] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const defaultVal = {
    category: note.category ? note.category : "",
    subcategory: note.subcategory ? note.subcategory : "",
    subcategoryOrder: note.subcategoryOrder ? note.subcategoryOrder : 0,
    categoryOrder: note.categoryOrder ? note.categoryOrder : 0,
    noteId: note._id ? note._id : null,
    delete: "",
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isSubmitted, isSubmitSuccessful },
    reset,
    formState,
    control,
  } = useForm({
    defaultValues: defaultVal,
  });

  const watchDelete = watch("delete");

  const _auth = useSelector((state) => state.auth);
  const _note = useSelector((state) => state.note);

  useEffect(() => {
    reset(defaultVal);
  }, [_note, isSubmitSuccessful]);

  const onEditNoteCategory = async (data) => {
    // console.log("category data: " + JSON.stringify(data));
    // console.log("category id: " + data.noteId);
    if (isDirty && data.noteId) {
      try {
        await dispatch(
          updateCategoryNote(data.noteId, {
            category: data.category.trim(),
            subcategory: data.subcategory.trim(),
            subcategoryOrder: data.subcategoryOrder,
            categoryOrder: data.categoryOrder,
          }),
        );
        setToggleEdit(!toggleEdit);
      } catch (err) {
        console.log("Error while editing note: ", err);
      }
    } else {
      // console.log("it wasn't changed");
      setToggleEdit(!toggleEdit);
    }
  };

  // const handleKeyDown = (e, id, text) => {
  //   console.log("data: ", text);
  //   console.log("id: ", id);
  //   if (e.keyCode === 13 && e.shiftKey === false) {
  //     e.preventDefault();
  //     onEditNote({ text: text }, id);
  //   }
  // };

  const onCancel = () => {
    // console.log("CANCEL RAN");
    reset(defaultVal);
    setToggleEdit(!toggleEdit);
  };

  const handleDeleteNote = async (id) => {
    // console.log("in deleteNote: " + JSON.stringify(id));
    // dispatch(deleteNote(id));
    try {
      await dispatch(deleteNote(id));
    } catch (err) {
      console.log("err while deleting: ", err);
    }
  };

  return (
    <>
      {!toggleEdit ? (
        <>
          <span className="border rounded-8 opacity-3 ms-1">
            <span className="fw-bold">{note.category}</span> {note.subcategory}
          </span>
          {(_auth.role === "admin" || _auth.role === "superadmin") && (
            <Button
              onClick={() => setToggleEdit(!toggleEdit)}
              className="btn-sm fs-xxs position-absolute right-0 opacity-3"
            >
              EDIT
            </Button>
          )}
        </>
      ) : (
        <div className="border border-warning border-2 box-shadow-2 rounded-8 p-1 mb-2 pb-0">
          <Form autoComplete="off" onSubmit={handleSubmit(onEditNoteCategory)}>
            <Row className="g-0">
              <Col sm={9}>
                <Controller
                  control={control}
                  name="category"
                  rules={{ required: true }}
                  // defaultValue={""}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input onChange={onChange} value={value} className="py-1 px-1 line-height-1 border" />
                  )}
                />

                <Controller
                  control={control}
                  name="subcategory"
                  rules={{ required: true }}
                  // defaultValue={""}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input onChange={onChange} value={value} className="py-1 px-1 line-height-1 border" />
                  )}
                />

                <div>
                  {note.category !== "General" && (
                    <div>
                      <Controller
                        control={control}
                        name="categoryOrder"
                        // rules={{ required: true }}
                        // defaultValue={""}
                        render={({ field: { value, onChange } }) => (
                          <>
                            <select onChange={onChange} value={value} className="form-select-sm">
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              {/*<option value="6">6</option>*/}
                              {/*<option value="7">7</option>*/}
                              {/*<option value="8">8</option>*/}
                            </select>
                            <span className="fs-sm ms-1">Category Order</span>
                          </>
                        )}
                      />
                    </div>
                  )}

                  <Controller
                    control={control}
                    name="subcategoryOrder"
                    // rules={{ required: true }}
                    // defaultValue={""}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <select onChange={onChange} value={value} className="form-select-sm">
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="6">7</option>
                          <option value="6">8</option>
                        </select>
                        <span className="fs-sm ms-1">Subcategory Order</span>
                      </>
                    )}
                  />
                </div>

                <span className="opacity-1 fs-xxs">
                  {note.subcategoryOrder && <span className="">Sub: {note.subcategoryOrder}</span>}
                  {note.categoryOrder && <span className="ms-2">Cat: {note.categoryOrder}</span>}
                </span>
              </Col>

              <Col sm={3} className="text-end">
                {!showDelete ? (
                  <>
                    <Button onClick={() => setShowDelete(!showDelete)} className="btn-icon bg-danger me-lg-2">
                      <i className="material-icons m-icon-custom fs-sm my-0">delete</i>
                    </Button>

                    <Button
                      disabled={!isDirty}
                      type="submit"
                      onClick={async () => await handleSubmit(onEditNoteCategory)}
                      className="btn-sm px-1 bg-dark fs-xs my-0"
                    >
                      SAVE
                    </Button>

                    <Button color="success" className="btn-sm px-1 bg-secondary fs-xs my-0" onClick={() => onCancel()}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <div className="text-center border rounded-8 p-2 bg-warning fs-sm">
                    Are you sure you would like to delete the entire category? This cannot be undone. If yes, type
                    <span className="text-danger fw-bold"> DELETE</span> (case sensitive) below to unlock the delete
                    button.
                    <input className="text-center form-control mt-2" {...register("delete")} placeholder="type here" />
                    <Button
                      disabled={watchDelete !== "DELETE"}
                      onClick={async () => await handleDeleteNote(note._id)}
                      className="btn-icon p-1 m-1 line-height-1 bg-danger"
                    >
                      Yes, Delete the ENTIRE {note.category} Category
                    </Button>
                    <Button
                      onClick={() => {
                        reset();
                        setShowDelete(!showDelete);
                      }}
                      className="bg-info p-1 m-1 line-height-1"
                    >
                      Cancel Delete
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
            {errors.category && (
              <div className="text-danger fw-bold fs-sm text-center position-absolute top-0 mt-0 ms-1 left-0 opacity-3">
                please enter a category name
              </div>
            )}
            {errors.subcategory && (
              <div className="text-danger fw-bold fs-sm text-center position-absolute bottom-0 mt-5 ms-1 left-0 opacity-3">
                please enter a subcategory name
              </div>
            )}
          </Form>

          <Row className="g-0 mb-1">
            {note.archivedNotes.length > 0 && (
              <Col className="border rounded-8 bg-info-light mt-2 mx-1 max-height-10 overflow-y-auto">
                <div className="text-center fs-sm fw-bold my-1 ">ARCHIVED NOTES</div>
                {note.archivedNotes
                  .slice(0)
                  .reverse()
                  .map((archivedNote, i) => (
                    <>
                      <NoteChildItem
                        key={"arch-child-" + archivedNote._id}
                        specificNote={archivedNote}
                        archivedNote={true}
                        category={note.category}
                      />
                    </>
                    // <div key={"arch-notes-" + i}>{note.text}</div>
                  ))}
              </Col>
            )}
          </Row>
        </div>
      )}
    </>
  );
};

export default connect(null, null)(NoteEditParent);
