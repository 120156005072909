import {
  GET_PATIENTS,
  ADD_PATIENT,
  DELETE_PATIENT,
  UPDATE_PATIENT,
  PATIENTS_LOADING,
  SEARCH_PATIENTS,
  VIEW_PATIENT,
  ADD_PATIENT_REFILL,
  DELETE_PATIENT_REFILL,
  UPDATE_PATIENT_REFILL,
  UPDATE_MANY_PATIENT_REFILLS,
  SET_CURRENT_PAGINATION_PAGE,
  FILTER_PATIENTS_BY,
  CLEAR_PATIENT_ERROR,
  CLEAR_PATIENT_RESPONSE,
  UPDATE_PATIENTS_PER_PAGE,
  FIND_FAMILY,
  ADD_PARTNER,
  REMOVE_PARTNERS,
  UPDATE_FAMILY,
  GET_FAMILY,
  UPDATE_PATIENT_NOTE,
  GET_REFILLS,
  UPDATE_REFILLS_CHECKED_SECONDARY,
  ADD_REFILL_CHECKED_DATE,
  GET_REFILLS_BY_RANGE,
  SET_REFILLS_DATE_RANGE,
  UPDATE_PATIENT_STATUS,
} from "../actions/types";
import { isEmpty } from "lodash";
import moment from "moment";
import { orderDates } from "../components/Utilities/CalculateDate";

const initialState = {
  patients: [],
  currentPatient: {},
  searchResults: [],
  currentPage: 1,
  patientsPerPage: 512,
  filterPatientsBy: "Currently Active With Refills",
  response: [],
  error: {},
  loading: true,
  refills: [],
  refillsByRange: [],
  // selectedDate: moment(moment(), "YYYY-MM-DDTHH:mm:ss").add(1, "days").format("YYYY-MM-DD"),
  selectedDate: moment(moment(), "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
  startDateRange: moment().startOf("week").format("YYYY-MM-DD"),
  endDateRange: moment().startOf("week").add(7, "days").format("YYYY-MM-DD"),
  requestsSent: "",
};

export default function (state = initialState, action) {
  // console.log('patientsReducer was called with state', state, 'and action', action)

  switch (action.type) {
    case SEARCH_PATIENTS:
      return {
        ...state,
        searchResults: action.payload,
      };
    case GET_PATIENTS:
      return {
        ...state,
        patients: action.payload,
        loading: false,
      };

    case VIEW_PATIENT:
      if (state.patients.length > 0) {
        const patient = state.patients.find((patient) => patient._id === action.payload);
        return {
          ...state,
          currentPatient: patient,
        };
      } else if (action.payload) {
        if (action.payload._id !== 0) {
          return {
            ...state,
            currentPatient: action.payload,
          };
        }
      }
      return {
        ...state,
        currentPatient: { firstName: "Patient Does Not Exist" },
      };

    case DELETE_PATIENT:
      // check if its current patient that was deleted
      // in order to clear out the redirected page
      return {
        ...state,
        patients: state.patients.filter((patient) => patient._id !== action.payload),
        currentPatient: { firstName: "Patient Does Not Exist" },
      };

    case ADD_PATIENT:
      if (action.payload.errorMessage) {
        return { ...state, patients: state.patients, error: action.payload };
      }
      return {
        ...state,
        patients: [action.payload, ...state.patients],
        response: ["Patient was successfully added"],
      };

    case UPDATE_PATIENT:
      const {
        _id,
        firstName,
        lastName,
        dateOfBirth,
        mirixa,
        isDelivery,
        hasControls,
        primaryPhoneNumber,
        primaryPhoneNote,
        secondaryPhoneNumber,
        secondaryPhoneNote,
        rxCount,
        groupNumber,
      } = action.payload;
      const patients = state.patients.map((patient) => {
        if (patient._id === _id) {
          // return { ...patient, firstName, lastName, dateOfBirth };
          return {
            ...patient,
            firstName,
            lastName,
            dateOfBirth,
            mirixa,
            isDelivery,
            hasControls,
            primaryPhoneNumber,
            primaryPhoneNote,
            secondaryPhoneNumber,
            secondaryPhoneNote,
            rxCount,
            groupNumber,
          };
        }
        return patient;
      });
      return { ...state, patients: patients, currentPatient: action.payload };

    case UPDATE_PATIENT_NOTE:
      const notePayload = action.payload;
      console.log("Reducer note payload:", notePayload);
      const updatedNotePatients = state.patients.map((patient) => {
        if (patient._id === notePayload._id) {
          const updatedPatient = { ...patient };
          if (notePayload.noteType === "second") {
            updatedPatient.secondNote = notePayload.secondNote;
          } else if (notePayload.noteType === "third") {
            updatedPatient.thirdNote = notePayload.thirdNote;
          } else {
            updatedPatient.note = notePayload.note;
          }
          updatedPatient.noteHistory = notePayload.noteHistory;
          console.log("UPDATED PATTTT: ", notePayload.note);
          return updatedPatient;
        }
        return patient;
      });

      const updatedNoteCurrentPatient = { ...state.currentPatient };
      if (notePayload.noteType === "second") {
        updatedNoteCurrentPatient.secondNote = notePayload.secondNote;
      } else if (notePayload.noteType === "third") {
        updatedNoteCurrentPatient.thirdNote = notePayload.thirdNote;
      } else {
        updatedNoteCurrentPatient.note = notePayload.note;
      }
      updatedNoteCurrentPatient.noteHistory = notePayload.noteHistory;

      return {
        ...state,
        patients: updatedNotePatients,
        currentPatient: updatedNoteCurrentPatient,
      };

    // ==== BEFORE MULTIPLE TYPES OF NOTES - first, second, third === //
    // case UPDATE_PATIENT_NOTE:
    //   const notePayload = action.payload;
    //   const patientsNoteUpdate = state.patients.map((patient) => {
    //     if (patient._id === notePayload._id) {
    //       return { ...patient, note: notePayload.note, noteHistory: notePayload.noteHistory };
    //     }
    //     return patient;
    //   });
    //   return {
    //     ...state,
    //     patients: patientsNoteUpdate,
    //     currentPatient: { ...state.currentPatient, note: notePayload.note, noteHistory: notePayload.noteHistory },
    //   };

    // UPDATE PATIENT STATUS //
    // UPDATE PATIENT STATUS //
    // UPDATE PATIENT STATUS //
    case UPDATE_PATIENT_STATUS:
      return {
        ...state,
        patients: state.patients.map((patient) =>
          patient._id === action.payload._id ? { ...patient, ...action.payload, partner: patient.partner } : patient,
        ),
      };

    ///------------SECONDARY PATIENT-------------///
    ///------------SECONDARY PATIENT-------------///
    ///------------SECONDARY PATIENT-------------///
    case UPDATE_REFILLS_CHECKED_SECONDARY:
      // console.log("DADADA " + JSON.stringify(action.payload));
      const patientsAll = state.patients.map((patient) => {
        if (patient._id === action.payload._id) {
          return { ...patient, datesRefillsLastChecked: action.payload.datesRefillsLastChecked };
        }
        return patient;
      });
      return {
        ...state,
        patients: patientsAll,
        currentPatient: {
          ...state.currentPatient,
          datesRefillsLastChecked: action.payload.datesRefillsLastChecked,
        },
      };

    case ADD_REFILL_CHECKED_DATE:
      const patientsAllOne = state.patients.map((patient) => {
        if (patient._id === action.payload._id) {
          return { ...patient, datesRefillsLastChecked: action.payload.datesRefillsLastChecked };
        }
        return patient;
      });
      return {
        ...state,
        patients: patientsAllOne,
        currentPatient: {
          ...state.currentPatient,
          datesRefillsLastChecked: action.payload.datesRefillsLastChecked,
        },
      };

    /////----------REFILLS BELOW---------//////
    /////----------REFILLS BELOW---------//////
    /////----------REFILLS BELOW---------//////
    case GET_REFILLS:
      const selectedDate = action.date ? action.date : state.selectedDate;
      const activePatients = state.patients.filter((patient) => patient.active);
      const patientsWithRefills = activePatients.filter((patient) => patient.refills.length > 0);
      const matchingRefills = [];
      patientsWithRefills.forEach((patient) => {
        patient.refills.forEach((refill, i) => {
          if (moment(refill.refillDate, "YYYY-MM-DDTHH:mm:ss").isSame(selectedDate, "day")) {
            matchingRefills.push({
              ...patient.refills[i],
              ...{
                firstName: patient.firstName,
                lastName: patient.lastName,
                patientId: patient._id,
                mirixa: patient.mirixa,
                hasControls: patient.hasControls,
                isDelivery: patient.isDelivery,
                rxCount: patient.rxCount,
                groupNumber: patient.groupNumber,
                partner: patient.partner,
                otherRefills: patient.refills && patient.refills.filter((refX) => refX._id !== refill._id),
                indexArr: i,
              },
            });
          }
        });
      });
      const count = matchingRefills.filter((x, i) => {
        return x.requestSent;
      }).length;
      if (selectedDate !== state.selectedDate) {
        return { ...state, refills: matchingRefills, requestsSent: count, selectedDate: selectedDate };
      } else {
        return { ...state, refills: matchingRefills, requestsSent: count };
      }

    case GET_REFILLS_BY_RANGE:
      // const selectedDateStart = action.dateStart;
      // const selectedDateEnd = action.dateEnd;
      // const selectedDateStart = action.dateStart ? action.dateStart : moment().startOf("week").format("YYYY-MM-DD");
      const selectedDateStart = action.dateStart ? action.dateStart : state.startDateRange;
      const selectedDateEnd = action.dateEnd
        ? action.dateEnd
        : state.endDateRange
          ? action.dateEnd
          : moment(selectedDateStart).add(7, "days").format("YYYY-MM-DD");
      const activePatientsRange = state.patients.filter((patient) => patient.active);
      const patientsWithRefillsRange = activePatientsRange.filter((patient) => patient.refills.length > 0);
      let matchingRefillsRange = [];
      // console.log("start: " + selectedDateStart);
      // console.log("end: " + selectedDateEnd);
      patientsWithRefillsRange.forEach((patient) => {
        patient.refills.forEach((refill, i) => {
          if (
            moment(refill.refillDate, "YYYY-MM-DDTHH:mm:ss").isBetween(
              selectedDateStart,
              selectedDateEnd,
              undefined,
              "(]",
            )
          ) {
            // console.log("YES");
            matchingRefillsRange.push({
              ...patient.refills[i],
              ...{
                firstName: patient.firstName,
                lastName: patient.lastName,
                patientId: patient._id,
                mirixa: patient.mirixa,
                hasControls: patient.hasControls,
                isDelivery: patient.isDelivery,
                rxCount: patient.rxCount,
                groupNumber: patient.groupNumber,
                partner: patient.partner,
                otherRefills: patient.refills && patient.refills.filter((refX) => refX._id !== refill._id),
                indexArr: i,
              },
            });
          }
        });
      });
      // put in order of date ascending
      // const matchingRefillsRange2 = matchingRefillsRange.sort((a, b) => {
      //   return Date.parse(a.refillDate) - Date.parse(b.refillDate);
      //   // console.log("A: " + JSON.stringify(a));
      // });

      const matchingRefillsRange2 = orderDates(matchingRefillsRange, "refillDate", -1);
      // const matchingRefillsRange2 = orderDates(sortBy(matchingRefillsRange, "lastName"), "refillDate", -1);
      // const matchingRefillsRange2 = sortBy(matchingRefillsRange, ["refillDate", "lastName"]);
      // const matchingRefillsRange2 = orderBy(matchingRefillsRange, ["refillDate", "lastName"], ["asc", "asc"]);

      // const countRange = matchingRefillsRange.filter((x, i) => {
      //   return x.requestSent;
      // }).length;
      // if (selectedDateRange !== state.selectedDate) {
      //   return { ...state, refillsByRange: matchingRefillsRange };
      // } else {
      return { ...state, refillsByRange: matchingRefillsRange2 };
    // }

    case SET_REFILLS_DATE_RANGE:
      return {
        ...state,
        startDateRange: action.dateStart ? action.dateStart : state.startDateRange,
        endDateRange: action.dateEnd ? action.dateEnd : state.endDateRange,
      };

    case ADD_PATIENT_REFILL:
      if (state.patients.length > 0) {
        // partner here is actually the partner._id since we don't populate the field
        const { _id, refills, lastBilledRefill, archivedRefills, partner, firstName, lastName, active } =
          action.payload;
        const patients = state.patients.map((patient) => {
          if (patient._id === _id) {
            return { ...patient, refills, lastBilledRefill, archivedRefills };
          } else if (patient._id === partner) {
            return { ...patient, partner: { refills, firstName, lastName, _id, active } };
          }
          return patient;
        });

        return {
          ...state,
          patients: patients,
          currentPatient: {
            ...state.currentPatient,
            refills: refills,
            lastBilledRefill: lastBilledRefill,
            archivedRefills: archivedRefills,
            // partner: { refills, firstName, lastName, _id },
          },
        };
      } else {
        // const { _id, refills, firstName, lastName } = action.payload;
        return {
          ...state,
          currentPatient: {
            ...state.currentPatient,
            refills: action.payload.refills,
            lastBilledRefill: action.payload.lastBilledRefill,
            archivedRefills: action.payload.archivedRefills,
            // partner: { refills, firstName, lastName, _id },
          },
        };
      }

    // NEEDS TO BE REFACTORED SINCE ITS BASICALLY DOING THE SAME THING AS ADD_PATIENT_REFILL
    case UPDATE_PATIENT_REFILL:
      if (state.patients.length > 0) {
        // partner extracted here is just the partner id since we didn't populate
        const { _id, refills, lastBilledRefill, archivedRefills, firstName, lastName, active, partner } =
          action.payload;
        const patients = state.patients.map((patient) => {
          if (patient._id === _id) {
            return { ...patient, refills };
          } else if (patient._id === partner) {
            return { ...patient, partner: { refills, firstName, lastName, active, _id } };
          }
          return patient;
        });
        return {
          ...state,
          patients: patients,
          currentPatient: {
            ...state.currentPatient,
            refills: refills,
            lastBilledRefill: lastBilledRefill,
            archivedRefills: archivedRefills,
            // partner: { refills, firstName, lastName, _id },
          },
        };
      } else {
        // const { _id, refills, firstName, lastName } = action.payload;
        return {
          ...state,
          currentPatient: {
            ...state.currentPatient,
            refills: action.payload.refills,
            lastBilledRefill: action.payload.lastBilledRefill,
            archivedRefills: action.payload.archivedRefills,
            // partner: { refills, firstName, lastName, _id },
          },
        };
      }

    // ---- DELETE REFILLS ---- //
    case DELETE_PATIENT_REFILL:
      // const patient = action.payload;
      if (state.patients.length > 0) {
        const { _id, refills, firstName, lastName, active, partner } = action.payload;

        const patients = state.patients.map((p) => {
          if (p._id === _id) {
            return { ...p, refills: refills };
            // } else if (p._id === (patient.partner ? patient.partner._id : "0")) {
          } else if (p._id === partner) {
            return { ...p, partner: { refills, firstName, lastName, active, _id } };
          }
          return p;
        });
        return {
          ...state,
          patients: patients,
          currentPatient: isEmpty(state.currentPatient)
            ? {}
            : { ...state.currentPatient, refills: action.payload.refills },
        };
      }
      return {
        ...state,
        currentPatient: isEmpty(state.currentPatient)
          ? {}
          : { ...state.currentPatient, refills: action.payload.refills },
      };

    //------UPDATE MULTIPLE PATIENT REFILLS TOGETHER-----//
    //------UPDATE MULTIPLE PATIENT REFILLS TOGETHER-----//
    //------UPDATE MULTIPLE PATIENT REFILLS TOGETHER-----//
    case UPDATE_MANY_PATIENT_REFILLS:
      // const combinedPatients = state.patients.map(
      //   (patient) => action.payload.find((el) => el._id === patient._id) || patient
      // );
      const manyPayload = action.payload;
      const combinedPatients = state.patients.map((patient) => {
        if (manyPayload.find((el) => el._id === patient._id)) {
          return { ...patient, refills: manyPayload.find((el) => el._id === patient._id).refills };
        }
        return patient;
      });
      return { ...state, patients: combinedPatients };

    // THIS METHOD ALSO WORKS, BUT IS MORE VERBOSE
    // const pp = state.patients.map(patient => {
    //   const found = action.payload.find( el => el._id === patient._id );
    //   return found ? found : patient;
    // });

    //-------PAGINATION--------//
    //-------PAGINATION--------//
    //-------PAGINATION--------//
    case SET_CURRENT_PAGINATION_PAGE:
      return { ...state, currentPage: action.payload };

    case FILTER_PATIENTS_BY:
      return { ...state, filterPatientsBy: action.payload };

    case UPDATE_PATIENTS_PER_PAGE:
      return { ...state, patientsPerPage: action.payload };

    case PATIENTS_LOADING:
      if (state.patients.length < 1) {
        return {
          ...state,
          loading: true,
        };
      }
      return { ...state };

    /////----------PATIENT PARTNER LINKS---------//////
    /////----------PATIENT PARTNER LINKS---------//////
    /////----------PATIENT PARTNER LINKS---------//////
    case FIND_FAMILY:
      return {
        ...state,
        response: action.payload,
      };

    // have to update all patients array as well because partners are used everywhere
    case ADD_PARTNER:
      const payload = action.payload;
      if (payload.errorMessage) {
        return { ...state, error: payload };
      }
      const updatedPatients = state.patients.map((patient) => payload.find((el) => el._id === patient._id) || patient);
      const updatedCurrentPatient = payload[0];
      return {
        ...state,
        patients: updatedPatients,
        currentPatient: { ...state.currentPatient, partner: updatedCurrentPatient.partner },
        response: [{ msg: "⚭ ⚇ successfully linked both partners ⚇ ⚭" }],
      };

    case REMOVE_PARTNERS:
      const payloadX = action.payload;
      if (payloadX.errorMessage) {
        return { ...state, error: payloadX };
      }
      const updatedPatientsX = state.patients.map(
        (patient) => payloadX.find((el) => el._id === patient._id) || patient,
      );
      const updatedCurrentPatientX = payloadX[0];
      return {
        ...state,
        patients: updatedPatientsX,
        currentPatient: { ...state.currentPatient, partner: updatedCurrentPatientX.partner },
        response: [{ msg: "⚯ successfully unlinked both patients ⚯" }],
      };

    /////----------FAMILY FAMILY FAMILY---------//////
    /////----------FAMILY FAMILY FAMILY---------//////
    /////----------FAMILY FAMILY FAMILY---------//////
    case GET_FAMILY:
      // payload here should just be the array of the populated family members
      return {
        ...state,
        currentPatient: { ...state.currentPatient, family: action.payload },
      };

    case UPDATE_FAMILY:
      // payload here should just be the array of the populated family members
      let updatedFamily = state.currentPatient.family;
      if (state.currentPatient._id === action.payload[0]._id) {
        updatedFamily = action.payload[0].family;
      } else if (state.currentPatient._id === action.payload[1]._id) {
        updatedFamily = action.payload[1].family;
      }
      return {
        ...state,
        currentPatient: { ...state.currentPatient, family: updatedFamily },
        response: [{ msg: "⚯ successfully updated ⚯" }],
      };

    /////----------RESPONSES & ERRORS---------//////
    /////----------RESPONSES & ERRORS---------//////
    /////----------RESPONSES & ERRORS---------//////
    case CLEAR_PATIENT_ERROR:
      return {
        ...state,
        error: {},
      };

    case CLEAR_PATIENT_RESPONSE:
      return {
        ...state,
        response: [],
      };

    default:
      return state;
  }
}
