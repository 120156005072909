// import 'materialize-css/dist/css/materialize.min.css';
import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import App from "./components/App";
import reducers from "./reducers";

// using this variable which shows as "development" or "production"
// allows us to only conditionally show Redux devtools (only in dev for example right now)
const nodeEnv = process.env.NODE_ENV;

const composeEnhancer =
  (nodeEnv !== "production" && typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(reducers, {}, composeEnhancer(applyMiddleware(thunk)));

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.querySelector("#root")
// );
createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <App />
  </Provider>
);
