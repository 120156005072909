import React, { Component } from "react";
import { Button, Form, FormGroup, Input, Card, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { addDrug } from "../../actions/drugActions";
import _ from "lodash";

class DrugAdd extends Component {
  state = {
    brandN: "",
    genericN: "",
    primaryIcdCodeId: "",
    drugNotes: "",
    drugClass: "",
    drugSchedule: "0",
    extraDrugInfo: "",
    otherIcdCodesString: ""
  };

  componentDidMount() {
    // this.saveOtherIcdCodes = _.debounce(this.saveOtherIcdCodes, 1000);
    this.initialState = _.cloneDeep(this.state);
  }

  clearCourseAndState = () => {
    document.getElementById("drug-course").reset();
    document.getElementsByName("brandN")[0].focus();
    this.setState(this.initialState);
    this.initialState = _.cloneDeep(this.initialState);
  };

  // clearState = () => {
  //   this.setState(this.initialState);
  //   this.initialState = _.cloneDeep(this.initialState);
  // };

  handleChangeOtherCodes = e => {
    let codes = e.target.value.toUpperCase();
    this.setState({ otherIcdCodesString: codes });
    // this.saveOtherIcdCodes();
  };

  // saveOtherIcdCodes = () => {
  //   let splitArray = this.state.otherIcdCodesString.split(" ");
  //   const arrNoSpace = splitArray.filter(e => e !== "");
  //   this.setState({ otherIcdCodeIds: arrNoSpace });
  // };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeScheduleDrug = e => {
    if (e.target.value === "Not a schedule drug") {
      this.setState({ drugSchedule: "0" });
    } else {
      this.setState({ drugSchedule: e.target.value });
    }
  };

  onSubmit = e => {
    e.preventDefault();

    let splitArray = this.state.otherIcdCodesString.split(" ");
    const arrNoSpace = splitArray.filter(e => e !== "");

    //FIX ERROR CHECK FOR EMPTY OTHER Drug CODES
    //FIX ERROR CHECK FOR INCORRECTLY TYPED PRIMARY Drug CODE

    // THESE WERE REMOVING ALL PUNCTUATIONS
    // const formatBrand = _.startCase(this.state.brandN.toLowerCase());
    // const formatGeneric = _.startCase(this.state.genericN.toLowerCase());

    const formatBrand = this.state.brandN
      .toLowerCase()
      .replace(/\w+/g, _.capitalize)
      .trim();
    const formatGeneric = this.state.genericN
      .toLowerCase()
      .replace(/\w+/g, _.capitalize)
      .trim();

    const newDrug = {
      brandName: formatBrand,
      genericName: formatGeneric,
      primaryIcdCode: this.state.primaryIcdCodeId.toUpperCase().trim(),
      otherIcdCodes: arrNoSpace,
      drugNotes: this.state.drugNotes,
      drugClass: this.state.drugClass,
      drugSchedule: this.state.drugSchedule,
      extraDrugInfo: this.state.extraDrugInfo
    };

    // const clonedNewDrug = _.cloneDeep(newDrug);

    // Add drug via addDrug action
    this.props.addDrug(newDrug);
    this.clearCourseAndState();
  };

  render() {
    return (
      <div>
        <Card body>
          <Form onSubmit={this.onSubmit} id="drug-course" autoComplete="off">
            <FormGroup>
              <Row>
                <Col xs="6">
                  <Input
                    type="text"
                    name="brandN"
                    id="drug"
                    placeholder="Brand name"
                    onChange={this.handleChange}
                    required
                  />
                </Col>
                <Col xs="6">
                  <Input
                    type="text"
                    name="genericN"
                    id="drug"
                    placeholder="Generic name"
                    onChange={this.handleChange}
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col xs="6">
                  <Input
                    type="text"
                    name="primaryIcdCodeId"
                    id="drug"
                    placeholder="Main ICD 10 Code"
                    onChange={this.handleChange}
                    required
                  />
                </Col>
                <Col xs="6">
                  <Input
                    type="text"
                    name="otherIcdCodeId"
                    id="drug"
                    placeholder="Other ICD 10 Codes"
                    onChange={this.handleChangeOtherCodes}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs="6">
                  <Input
                    type="text"
                    name="drugClass"
                    id="drug"
                    placeholder="Drug class"
                    onChange={this.handleChange}
                  />
                </Col>

                <Col xs="6">
                  <Input
                    type="select"
                    name="drugSchedule"
                    id="drug"
                    onChange={this.handleChangeScheduleDrug}
                    required
                  >
                    <option>Not a schedule drug</option>
                    <option>I</option>
                    <option>II</option>
                    <option>III</option>
                    <option>IV</option>
                    <option>V</option>
                  </Input>
                </Col>
              </Row>

              <Input
                type="text"
                name="drugNotes"
                id="drug"
                placeholder="Drug directions and information (notes)"
                onChange={this.handleChange}
              />
              <Input
                type="text"
                name="extraDrugInfo"
                id="drug"
                placeholder="Extra information about the drug"
                onChange={this.handleChange}
              />

              <Button color="dark" style={{ marginTop: "2rem" }} block>
                Add Drug
              </Button>
            </FormGroup>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  drug: state.drug
});

export default connect(
  mapStateToProps,
  { addDrug }
)(DrugAdd);
