import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Col,
  Card,
  CardTitle,
  Row,
  Alert,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { connect } from "react-redux";
import { addPatient, addPatientOverride, clearPatientError, clearPatientResponse } from "../../actions/patientActions";
import moment from "moment";
// import PatientSearch from "./PatientSearch";
import _ from "lodash";

class PatientAdd extends Component {
  state = {
    aName: "",
    bName: "",
    dateOfBirth: "",
    note: "",
    refillDate: "",
    requestTypeSelected: [],
    refillNote: "",
    sex: "unknown",
    userName: this.props.auth.name,
    mirixa: false,
    alertVisible: true,
    showForm: false,
    response: "",
    secondaryPatient: false,
    dateRefillsLastChecked: "",
  };

  componentDidMount() {
    // this.initialState = _.cloneDeep(this.state);
    this.initialState = this.state;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.patient.response !== prevProps.patient.response &&
      this.setState({ response: this.props.patient.response ? this.props.patient.response[0] : [] });
  }

  componentWillUnmount() {
    this.props.clearPatientResponse();
  }

  toggleShowForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  clearCourseAndState = () => {
    document.getElementById("patient-course").reset();
    document.getElementsByName("aName")[0].focus();
    this.setState({ ...this.initialState, showForm: true });
    // this.initialState = _.cloneDeep(this.initialState);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckboxBtnClick(selected) {
    const index = this.state.requestTypeSelected.indexOf(selected);
    if (index < 0) {
      this.state.requestTypeSelected.push(selected);
    } else {
      this.state.requestTypeSelected.splice(index, 1);
    }
    this.setState({ requestTypeSelected: [...this.state.requestTypeSelected] });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let refillLocal;
    const trimmedNote = this.state.refillNote.trim();

    this.state.refillDate === ""
      ? (refillLocal = [])
      : (refillLocal = [
          {
            refillDate: this.state.refillDate,
            typeOfRequest: this.state.requestTypeSelected,
            // refillNote: this.state.refillNote,
            refillNotes:
              trimmedNote === ""
                ? []
                : [
                    {
                      date: Date.now(),
                      userName: this.state.userName,
                      note: trimmedNote,
                    },
                  ],
          },
        ]);

    const formatFirst = _.startCase(this.state.aName.trim().toLowerCase());
    const formatLast = _.startCase(this.state.bName.trim().toLowerCase());

    const secondary = this.state.secondaryPatient === true || this.state.secondaryPatient === "true" ? true : false;

    const newPatient = {
      firstName: formatFirst,
      lastName: formatLast,
      dateOfBirth: this.state.dateOfBirth,
      // notes: [this.state.note.trim()],
      note: this.state.note.trim(),
      refills: refillLocal,
      mirixa: this.state.mirixa,
      sex: this.state.sex,
      secondaryPatient: secondary,
      datesRefillsLastChecked: secondary ? [{ dateChecked: this.state.dateRefillsLastChecked }] : [],
    };

    // console.log("NEW " + JSON.stringify(newPatient));

    this.props.addPatient(newPatient);
    this.clearCourseAndState();
    this.setState({ requestTypeSelected: [] });
  };

  handleAddPatientOverride = (overridePatient) => {
    const patient = _.cloneDeep(overridePatient);
    this.props.addPatientOverride(patient);
    this.handleDismissAlert();
  };

  handleDismissAlert = () => {
    this.setState({ alertVisible: false, response: "" });
    this.props.clearPatientError("cleared patient DOB match error");
  };

  render() {
    const { error } = this.props.patient;
    const oneMonthAgo = moment().subtract(1, "months").format("YYYY-MM-DD");
    const elevenMonths = moment().add(11, "months").format("YYYY-MM-DD");
    const oneYearAgo = moment().subtract(1, "years").format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");
    const { showForm, response, alertVisible } = this.state;

    return (
      <div>
        <Card>
          {showForm ? (
            <>
              <Button color="light" onClick={this.toggleShowForm} className="opacity-3 border rounded mt-0 mb-2">
                ▴ Close ▴
              </Button>
              <CardTitle style={{ textAlign: "center" }}>
                <strong>ADD NEW PATIENT</strong>
              </CardTitle>
              {(error.errorMessage || response) && (
                <Alert
                  color={error.errorMessage ? "danger" : "success"}
                  isOpen={alertVisible || response.length}
                  toggle={this.handleDismissAlert}
                >
                  {response && <div className="text-center">{response}</div>}

                  <div>{error.errorMessage}</div>
                  {error.oldPatient && (
                    <div>
                      Existing Patient is <b>{error.oldPatient.firstName + " " + error.oldPatient.lastName}</b>
                    </div>
                  )}
                  {error.newPatient && (
                    <div className="mt-2">
                      <div>
                        If you are certain this is not a duplicate patient and would still like to add{" "}
                        <b>{error.newPatient.firstName + " " + error.newPatient.lastName}</b> as a <u>new</u> patient,
                        please click the 'Add' button below.
                      </div>
                      <div>
                        <Button onClick={() => this.handleAddPatientOverride(error.newPatient)} color="danger">
                          Yes, Add {error.newPatient.firstName + " " + error.newPatient.lastName}
                        </Button>
                        <Button onClick={this.handleDismissAlert} color="info">
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </Alert>
              )}
              <Form onSubmit={this.handleSubmit} id="patient-course" autoComplete="off">
                <FormGroup row>
                  <Col md={6}>
                    <div className="gray-box">
                      <Row>
                        <Col sm="6">
                          <Input
                            type="text"
                            name="aName"
                            value={this.state.aName}
                            placeholder="First name"
                            onChange={this.handleChange}
                            required
                          />
                        </Col>
                        <Col sm="6">
                          <Input
                            type="text"
                            name="bName"
                            value={this.state.bName}
                            placeholder="Last name"
                            onChange={this.handleChange}
                            required
                          />
                        </Col>
                      </Row>

                      <InputGroup>
                        <InputGroupText>Date of Birth</InputGroupText>
                        <Input
                          type="date"
                          name="dateOfBirth"
                          min="1920-01-01"
                          max={oneYearAgo}
                          id="dob"
                          value={this.state.dateOfBirth}
                          // placeholder="Date of birth"
                          onChange={this.handleChange}
                        />
                      </InputGroup>

                      <Input
                        type="text"
                        name="note"
                        placeholder="Note about the patient"
                        value={this.state.note}
                        onChange={this.handleChange}
                      />
                      {/*<FormGroup check>*/}
                      {/*  <Input type="checkbox" name="check" id="exampleCheck"/>*/}
                      {/*  <Label for="exampleCheck" check>Mirixa patient?</Label>*/}
                      {/*</FormGroup>*/}

                      <Row>
                        <Col sm="6">
                          <InputGroup>
                            <InputGroupText>Sex</InputGroupText>
                            <select
                              type="select"
                              name="sex"
                              id="sexId"
                              value={this.state.sex}
                              onChange={this.handleChange}
                              className="form-control"
                              // style={{ marginLeft: "32px", borderRadius: "6px" }}
                            >
                              <option value="unknown">Unknown</option>
                              <option value="female">Female</option>
                              <option value="male">Male</option>
                            </select>
                          </InputGroup>
                        </Col>
                        <Col sm="6">
                          <InputGroup className="col-sm-6">
                            <InputGroupText>Mirixa</InputGroupText>
                            <select
                              type="select"
                              name="mirixa"
                              id="patientMirixaId"
                              value={this.state.mirixa}
                              onChange={this.handleChange}
                              className="form-control"
                              // style={{ marginLeft: "32px", borderRadius: "6px" }}
                            >
                              <option value="false">No</option>
                              <option value="true">Yes</option>
                            </select>
                          </InputGroup>
                        </Col>
                      </Row>

                      {/*SECONDARY PATIENT*/}
                      <Row>
                        <InputGroup className="col-lg-8">
                          <InputGroupText>Secondary Patient</InputGroupText>
                          <select
                            type="select"
                            name="secondaryPatient"
                            id="secondaryPatient"
                            value={this.state.secondaryPatient}
                            onChange={this.handleChange}
                            className="form-control"
                            // style={{ marginLeft: "32px", borderRadius: "6px" }}
                          >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </select>
                        </InputGroup>

                        {this.state.secondaryPatient === true ||
                          (this.state.secondaryPatient === "true" && (
                            <InputGroup className="mb-3 col-lg-8">
                              <InputGroupText className="fw-bolder">Date Refills Last Checked</InputGroupText>
                              <Input
                                type="date"
                                name="dateRefillsLastChecked"
                                min={oneMonthAgo}
                                max={today}
                                id="dateRefillsLastChecked"
                                value={this.state.dateRefillsLastChecked}
                                // placeholder="Date the refill was last checked"
                                onChange={this.handleChange}
                              />
                            </InputGroup>
                          ))}
                      </Row>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="gray-box">
                      <div className="text-center mb-3">
                        <strong>Add Patient Refill</strong>
                      </div>
                      <InputGroup>
                        <Col xs={7} className="p-0">
                          <Input
                            type="date"
                            name="refillDate"
                            placeholder="New refill date"
                            min={oneMonthAgo}
                            max={elevenMonths}
                            value={this.state.refillDate}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col xs={5} className="p-0">
                          <Button
                            style={{ float: "right" }}
                            color="primary"
                            className="bg-info-light"
                            onClick={() => this.handleCheckboxBtnClick("Phone")}
                            active={this.state.requestTypeSelected.includes("Phone")}
                          >
                            Phone
                            {this.state.requestTypeSelected.includes("Phone") && <span> ✓</span>}
                          </Button>
                        </Col>
                      </InputGroup>
                      <Input
                        type="text"
                        name="refillNote"
                        value={this.state.refillNote}
                        placeholder="Refill note"
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Button color="dark" block>
                      Add Patient
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            </>
          ) : (
            <Button color="light" onClick={this.toggleShowForm} className="mt-0 border rounded">
              ▾ Show Add New Patient Form ▾
            </Button>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  patient: state.patient,
  error: state.patient,
  auth: state.auth,
});

export default connect(mapStateToProps, { addPatient, clearPatientError, addPatientOverride, clearPatientResponse })(
  PatientAdd
);
