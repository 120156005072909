import React, { useState } from "react";
import { Button, Popover, PopoverBody, PopoverHeader } from "reactstrap";

const PopoverItem = (props) => {
  const { position = "bottom", index, buttonText, parentFunction } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  // const handleParentDelete = () => {
  //   parentFunction();
  //   console.log("loveeeee");
  // };

  const handleParent = () => {
    parentFunction();
    toggle();
  };

  return (
    <span>
      <span id={"popover-" + index}>{props.children}</span>
      <Popover
        style={{ textAlign: "center" }}
        placement={position}
        isOpen={popoverOpen}
        target={"popover-" + index}
        toggle={toggle}
        trigger="legacy"
      >
        {/*{props.title}*/}

        <PopoverHeader>
          <strong>{buttonText}</strong>?
        </PopoverHeader>
        <PopoverBody style={{ paddingTop: "5px" }}>
          <Button style={{ marginTop: "0px" }} onClick={toggle} className="btn btn-primary custom-btn">
            Cancel
          </Button>
          <Button
            style={{ marginTop: "0px" }}
            // onClick={this.parentFunctionDate.bind(this, patientId, i)}
            onClick={handleParent}
            className="btn btn-danger custom-btn"
          >
            Confirm {buttonText}
          </Button>
        </PopoverBody>
      </Popover>
    </span>
  );
};

export default PopoverItem;
