import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardTitle, Col, Row, Container } from "reactstrap";
import PharmacyAdd from "./PharmacyAdd";
import GoBack from "../../GoBack";
import NotAuthorized from "../NotAuthorized";

class Pharmacy extends Component {
  isAdmin() {
    const role = this.props.auth.role;
    if (role === "superadmin") {
      return (
        <Container fluid className="custom-container">
          <div>
            <Card
              body
              style={{
                backgroundColor: "#e9f4ff"
              }}
            >
              <CardTitle style={{ textAlign: "center" }}>
                <strong>PHARMACY</strong>
                {/*CURRENT USER:{" "}*/}
                {/*<strong>{this.props.auth.name.toUpperCase()}</strong>*/}
              </CardTitle>

              <div>
                {" "}
                <Row>
                  <Col xs="12">
                    <PharmacyAdd />
                  </Col>
                </Row>
              </div>
            </Card>
            <div>
              <GoBack />
            </div>
          </div>
        </Container>
      );
    }
    return <NotAuthorized />;
  }

  render() {
    switch (this.props.auth) {
      case null:
        return <div />;
      case false:
        return <div />;
      default:
        return <div>{this.isAdmin()}</div>;
    }
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps)(Pharmacy);
