import axios from "axios";
import { GET_NOTES, ADD_NOTE, DELETE_NOTE, UPDATE_NOTE, NOTES_LOADING, CLEAR_NOTE_RESPONSE, NOTE_ERROR } from "./types";

export const getNotes = () => (dispatch) => {
  // console.log("get NOTES")
  // dispatch(setNotesLoading());
  axios.get("/api/notes").then((res) =>
    dispatch({
      type: GET_NOTES,
      payload: res.data,
    }),
  );
};

export const addNote = (note) => (dispatch) => {
  axios.post("/api/notes", note).then((res) =>
    dispatch({
      type: ADD_NOTE,
      payload: res.data,
    }),
  );
};

export const addCategoryNote = (id, note) => (dispatch) => {
  axios.put(`/api/notes-category/${id}`, note).then((res) =>
    dispatch({
      type: UPDATE_NOTE,
      payload: res.data,
    }),
  );
};

export const deleteNote = (id) => (dispatch) => {
  axios.delete(`/api/notes/${id}`).then((res) =>
    dispatch({
      type: DELETE_NOTE,
      payload: id,
    }),
  );
};

// delete the child note in the category
export const deleteCategoryNote = (noteId, noteToDelete) => (dispatch) => {
  axios.put(`/api/notes-category/${noteId}/delete`, noteToDelete).then((res) =>
    dispatch({
      type: UPDATE_NOTE,
      payload: res.data,
    }),
  );
};

// delete the ARCHIVED note in the archivedNotes array
export const deleteArchivedNote = (noteId) => (dispatch) => {
  axios.put(`/api/notes-category/${noteId}/delete-archived`).then((res) =>
    dispatch({
      type: UPDATE_NOTE,
      payload: res.data,
    }),
  );
};

// update the child note in notes array of parent note
export const updateCategoryChildNote = (noteId, note) => async (dispatch) => {
  axios.put(`/api/notes-category/${noteId}/update-child`, note).then((res) => {
    // console.log("hi: ", note);
    dispatch({
      type: UPDATE_NOTE,
      payload: res.data,
    });
  });
};

// update the parent category
export const updateCategoryNote = (parentNoteId, note) => (dispatch) => {
  axios.put(`/api/notes-category/${parentNoteId}/update`, note).then((res) =>
    dispatch({
      type: UPDATE_NOTE,
      payload: res.data,
    }),
  );
};

export const updateNote = (id, note) => (dispatch) => {
  axios.put(`/api/notes/${id}`, note).then((res) =>
    dispatch({
      type: UPDATE_NOTE,
      payload: res.data,
    }),
  );
};

export const clearNoteResponse = () => {
  return {
    type: CLEAR_NOTE_RESPONSE,
  };
};

export const setNotesLoading = () => {
  return {
    type: NOTES_LOADING,
  };
};
