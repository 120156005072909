import React from "react";
import { Button, Card, Col, ListGroup, Row } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

const findNextRefill = (refills) => {
  const today = moment().subtract(1, "d").toDate();
  const newRefills = _.sortBy(refills, (ref) => {
    return new moment(ref.refillDate);
  });
  const filteredRefills = newRefills.filter((refill) => {
    const localDate = moment(refill.refillDate, "YYYY-MM-DDTHH:mm:ss").toDate();
    return moment(localDate).isSameOrAfter(today);
  });

  if (filteredRefills.length > 0) {
    return (
      <span>
        <span style={{ opacity: "0.4" }}>Next Fill:</span>{" "}
        <b style={{ opacity: "0.7" }}>
          {moment(filteredRefills[0].refillDate, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY")}
        </b>
      </span>
    );
  } else {
    return <span style={{ opacity: "0.4" }}>No upcoming fills</span>;
  }
};

const PatientSearchResults = (props) => {
  return (
    <div>
      <ListGroup>
        <Row className="d-flex flex-lg-row">
          {props.searchResults
            .filter((item) => !!item)
            .map(({ firstName, lastName, dateOfBirth, refills, _id, active, deceased }) => (
              <Col key={_id} className="" md="auto" xs="12" style={{ padding: "0px 2px 0px 2px" }}>
                <Card
                  // className=""
                  body
                  style={{
                    backgroundColor: !active ? "rgba(255,50,0,0.2)" : "#f9f9f9",
                    opacity: !!deceased && "0.6",
                    padding: "2px 4px 2px 8px",
                  }}
                >
                  <Link to={`/patients/${_id}`} className="custom-link-hover rounded-8">
                    {!!deceased && (
                      <Button disabled className="btn-red">
                        <b>DECEASED</b>
                      </Button>
                    )}
                    {!active && !deceased && (
                      <Button disabled className="btn-red">
                        <b>INACTIVE</b>
                      </Button>
                    )}
                    <div
                      style={{
                        fontSize: "18px",
                        borderBottom: "1px solid rgba(0,0,0, 0.1)",
                        paddingBottom: "4px",
                      }}
                    >
                      <strong>{lastName + ", " + firstName}</strong>
                    </div>
                    <span className="border rounded px-1" style={{ fontSize: "10px" }}>
                      {refills && refills.length > 0 ? (
                        <span className="bg-white">{findNextRefill(refills)}</span>
                      ) : (
                        <span className="opacity-1">No refills</span>
                      )}
                    </span>
                    {dateOfBirth && (
                      <span
                        className="border rounded"
                        style={{ margin: "4px 0px 0px 0px", fontSize: "10px", float: "right", opacity: "50%" }}
                      >
                        <b>
                          {moment(dateOfBirth, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY")}{" "}
                          {/*<span style={{ opacity: "0.2" }}>*/}
                          {/*  {moment().diff(moment(dateOfBirth, "YYYY-MM-DDTHH:mm:ss"), "years")}*/}
                          {/*</span>*/}
                        </b>
                      </span>
                    )}
                  </Link>
                </Card>
              </Col>
            ))}
        </Row>
      </ListGroup>
    </div>
  );
};

export default PatientSearchResults;
