import axios from "axios";
import {
  GET_DRUGS,
  ADD_DRUG,
  DELETE_DRUG,
  UPDATE_DRUG,
  DRUGS_LOADING,
  SEARCH_DRUGS
} from "./types";

export const searchDrugs = query => dispatch => {
  axios.get("/api/drugs/search?q=" + query).then(res =>
    dispatch({
      type: SEARCH_DRUGS,
      payload: res.data
    })
  );
};

export const getDrugs = () => dispatch => {
  dispatch(setDrugsLoading());
  axios.get("/api/drugs").then(res =>
    dispatch({
      type: GET_DRUGS,
      payload: res.data
    })
  );
};

export const addDrug = drug => dispatch => {
  axios.post("/api/drugs", drug).then(res =>
    dispatch({
      type: ADD_DRUG,
      payload: res.data
    })
  );
};

export const deleteDrug = id => dispatch => {
  axios.delete(`/api/drugs/${id}`).then(res =>
    dispatch({
      type: DELETE_DRUG,
      payload: id
    })
  );
};

export const updateDrug = (id, drug) => dispatch => {
  axios.put(`/api/drugs/${id}`, drug).then(res =>
    dispatch({
      type: UPDATE_DRUG,
      payload: res.data
    })
  );
};

export const setDrugsLoading = () => {
  return {
    type: DRUGS_LOADING
  };
};
