import React, { Component } from "react";
import {
  Card,
  CardTitle,
  Form,
  Input,
  // ListGroup,
  // Button
  // Badge,
  // Col,
  // Row
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { searchPatients } from "../../../actions/patientActions";
// import moment from "moment";
import _ from "lodash";
import PatientSearchResults from "./PatientSearchResults";
// import { Link } from "react-router-dom";

class PatientSearch extends Component {
  state = { searchTerm: "" };

  componentDidMount() {
    this.debounceSearch = _.debounce(this.debounceSearch, 1000);
    this.debounceResetSearch = _.debounce(this.resetSearch, 1000 * 30);
  }

  componentWillUnmount() {
    this.debounceResetSearch.cancel();
    this.resetSearch();
  }

  clearCourse = () => {
    document.getElementById("course-patient").reset();
  };

  debounceSearch = () => {
    this.props.searchPatients(this.state.searchTerm);
  };

  resetSearch = () => {
    this.setState({ searchTerm: "" });
    this.props.searchPatients("");
  };

  handleChange = (e) => {
    this.setState({ searchTerm: e.target.value });
    this.debounceSearch();
    this.debounceResetSearch();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.debounceSearch();
    this.clearCourse();
    this.debounceResetSearch();
  };

  render() {
    const { searchResults } = this.props.patient;

    return (
      <div>
        <Card className="py-2 px-3">
          <CardTitle className="text-center text-primary mb-0">
            <span className="cursor-pointer rounded px-1" onClick={this.resetSearch}>
              PATIENT <strong>SEARCH</strong>
            </span>
          </CardTitle>
          <Form onSubmit={this.handleSubmit} id="course-patient">
            <Input
              // id="course-patient"
              placeholder="Enter patient name"
              autoComplete="off"
              value={this.state.searchTerm}
              onChange={this.handleChange}
              className="box-shadow-0"
            />
          </Form>

          <PatientSearchResults searchResults={searchResults} />

          {/*{this.checkIfResultExists(searchResults)}*/}
        </Card>
      </div>
    );
  }
}

PatientSearch.propTypes = {
  searchPatients: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  patient: state.patient,
});

export default connect(mapStateToProps, { searchPatients })(PatientSearch);
