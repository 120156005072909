import React, { Component } from "react";
import {
  Card,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  deleteIcdCode,
  getIcdCodes,
  updateIcdCode
} from "../../../actions/icdCodeActions";
import IcdCodeEditModal from "./IcdCodeEditModal";

class IcdCodeItem extends Component {
  render() {
    const icdCode = this.props.icdCodeItem;

    return (
      <Col sm="3" xs="6" className="col-3-no-padding" key={icdCode._id}>
        <Card body className="icd-code-card">
          <h3>{icdCode.icdCodeNum}</h3>
          {icdCode.icdCodeValue}

          <div style={{ paddingTop: "10px !important" }}>
            <IcdCodeEditModal editIcdCode={icdCode} />
          </div>
        </Card>
      </Col>
    );
  }
}

IcdCodeItem.propTypes = {
  getIcdCodes: PropTypes.func.isRequired,
  icdCode: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  icdCode: state.icdCode
});

export default connect(
  mapStateToProps,
  { getIcdCodes, deleteIcdCode, updateIcdCode }
)(IcdCodeItem);
