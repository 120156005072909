import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonGroup,
  Popover,
  PopoverHeader,
  PopoverBody,
  Row,
  Col,
  Alert
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteIcdCode, updateIcdCode } from "../../../actions/icdCodeActions";
import _ from "lodash";

class IcdCodeEditModal extends Component {
  state = {
    icdCodeNumLocal: this.props.editIcdCode.icdCodeNum,
    icdCodeValueLocal: this.props.editIcdCode.icdCodeValue,
    modal: false,
    popoverOpen: false,
    alertVisible: false
  };

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleDismissAlert = () => {
    this.setState({ alertVisible: false });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeCode = e => {
    this.setState({
      icdCodeNumLocal: e.target.value.toUpperCase().trim()
    });
  };

  handleDeleteClick = id => {
    this.props.deleteIcdCode(id);
  };

  debounceCloseAlert = _.debounce(() => {
    this.setState({ alertVisible: false });
  }, 6000);

  handleEditSaveClick = id => e => {
    e.preventDefault();
    const trimmedValue = _.upperFirst(this.state.icdCodeValueLocal.trim());
    const trimmedCode = this.state.icdCodeNumLocal.split(" ").join("");
    const icdCodeJson = {
      icdCodeNum: trimmedCode,
      icdCodeValue: trimmedValue
    };

    this.props.updateIcdCode(id, icdCodeJson);
    this.setState({
      icdCodeValueLocal: trimmedValue,
      icdCodeNumLocal: trimmedCode,
      alertVisible: true
    });
    this.debounceCloseAlert();
  };

  render() {
    const icdCode = this.props.editIcdCode;
    const { modal } = this.state;

    return (
      <div>
        <Button
          className="custom-btn"
          color="primary"
          onClick={this.toggle}
          style={{ width: "100%" }}
        >
          Edit
        </Button>

        <Modal isOpen={modal} toggle={this.toggle} centered={true}>
          <ModalHeader toggle={this.toggle}>
            Edit {icdCode.icdCodeNum}
          </ModalHeader>
          <ModalBody>
            <Alert
              isOpen={this.state.alertVisible}
              toggle={this.handleDismissAlert}
            >
              <span>Saved changes successfully.</span>
            </Alert>
            <Form
              onSubmit={this.handleEditSaveClick(icdCode._id)}
              autoComplete="off"
            >
              <FormGroup>
                <Row>
                  <Col xs="2">
                    <Input
                      type="text"
                      name="icdCodeNumLocal"
                      id="drug"
                      placeholder="Icd code"
                      value={this.state.icdCodeNumLocal}
                      onChange={this.handleChangeCode}
                      required
                    />
                  </Col>
                  <Col xs="10">
                    <Input
                      type="text"
                      name="icdCodeValueLocal"
                      id="drug"
                      placeholder="Code value"
                      value={this.state.icdCodeValueLocal}
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                </Row>

                <ButtonGroup className="d-flex">
                  <Button color="dark" style={{ flexBasis: "75%" }}>
                    Save Changes
                  </Button>

                  <Button
                    color="danger"
                    id={"popover" + icdCode._id}
                    onClick={this.togglePopover}
                  >
                    Delete
                  </Button>

                  <Popover
                    style={{ textAlign: "center" }}
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target={"popover" + icdCode._id}
                    toggle={this.togglePopover}
                  >
                    <PopoverHeader>
                      Permanently delete <strong>{icdCode.icdCodeNum}</strong>?
                    </PopoverHeader>
                    <PopoverBody style={{ paddingTop: "5px" }}>
                      <Button
                        style={{ marginTop: "0px" }}
                        onClick={this.togglePopover}
                        className="btn btn-primary custom-btn"
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{ marginTop: "0px" }}
                        onClick={this.handleDeleteClick.bind(this, icdCode._id)}
                        className="btn btn-danger custom-btn"
                      >
                        Confirm Delete
                      </Button>
                    </PopoverBody>
                  </Popover>
                </ButtonGroup>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

IcdCodeEditModal.propTypes = {
  //   getIcdCodes: PropTypes.func.isRequired,
  icdCode: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  icdCode: state.icdCode
});

export default connect(
  mapStateToProps,
  { deleteIcdCode, updateIcdCode }
)(IcdCodeEditModal);
